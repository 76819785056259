import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { TextArea } = Input;
const targetFaqTypes = [{ value: "SHIPPER", label: "Shipper" }, { value: "LOYALTY", label: "Loyalty" }, { value: "DRIVER", label: "Driver" }]
class FAQUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            faqTypes: [],
            isUpdating: false
        };
        this.updateRef = React.createRef()
    }

    componentDidMount() {
        this.fetchAllData()
    }

    fetchAllData = async () => {
        try {
            await Promise.all([
                this.fetchData(),
                this.fetchFaqType()
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    fetchFaqType = async () => {
        try {
            const response = await ApiHandler({ url: Api.faq_type, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let faqTemp = []
                response.map((value) => {
                    faqTemp.push({
                        value: value,
                        label: value,
                    });
                })
                this.setState({
                    faqTypes: faqTemp
                });
            }
        } catch (error) {

        }
    }

    fetchData = async () => {
        try {
            const response = await ApiHandler({ url: Api.faq, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            if (response) {
                this.setState({
                    data: response
                })
                this.updateRef.current.setFieldsValue(response);
            }
        } catch (error) {

        }
    }

    updateFAQ = async (formData) => {
        const { data } = this.state
        this.setState({ isUpdating: true })
        const requestData = {
            ...formData,
            id: data.id
        }
        try {
            const response = await ApiHandler({ url: Api.faq, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData })
            if (response.status === 200) {
                this.props.navigate(CustomPath.faq)
            }
        } catch (error) {
            this.setState({ isUpdating: false })
        }
    }

    render() {
        const { faqTypes, isUpdating } = this.state
        const { navigate } = this.props
        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: <Link to={CustomPath.faq}>FAQ</Link>,
                                        },
                                        {
                                            title: 'Update',
                                        }
                                    ]}
                                /></Col>
                            <Col span={24}>
                                <Card
                                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                    bordered={false}
                                    title={"Faq Update"}
                                    extra={<Button type="primary" onClick={() => navigate(CustomPath.faq)}>Back</Button>}
                                >
                                    <Form
                                        layout="vertical"
                                        ref={this.updateRef}
                                        initialValues={{ active: true }}
                                        onFinish={this.updateFAQ}>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item name="question" label={"Question (EN)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input placeholder={"Enter question"} />
                                                </Form.Item></Col>
                                            <Col span={24}>
                                                <Form.Item name="answer" label={"Answer  (EN)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <TextArea
                                                        style={{ height: 80 }}
                                                        placeholder={"Enter answer"} />
                                                </Form.Item></Col>
                                            <Col span={24}><Form.Item name="questionMM" label={"Question (MM)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Input placeholder={"Enter question"} />
                                            </Form.Item></Col>
                                            <Col span={24}>
                                                <Form.Item name="answerMM" label={"Answer  (MM)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <TextArea
                                                        style={{ height: 80 }}
                                                        placeholder={"Enter answer"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="faqType" label={"FAQ Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select
                                                        size="large"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="Select FAQ Type"
                                                        options={faqTypes}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="faqTargetType" label={"Target Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select
                                                        disabled
                                                        size="large"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="Select target type"
                                                        options={targetFaqTypes}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="active"
                                                    valuePropName="checked">
                                                    <Checkbox>Active</Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item >
                                                    <Button type="primary" htmlType="submit" loading={isUpdating}>Update</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>

                            </Col>
                        </Row>
                    </Col >

                </Row >

            </>
        );
    }
}

export default compose(withRouter)(FAQUpdate)
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Button, Card, Col, Input, List, Row, Switch, message } from "antd";
import React from "react";
import Theme from "../../components/theme";
import '../../css/location/manage-location.css';
import Api from "../../network/api";
import { ApiFilter, ApiPutBatch } from "../../network/network-manager";
import withRouter from "../../network/with-router";

class ManageHomeDeliveryLocation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            division_collection: [],
            township_collection: [],
            township_collection_full_list: [],
            selected_division: 0
        }
    }

    componentDidMount() {
        this.fetchDivision()
    }

    fetchDivision = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.division_filter, params, this.props)
            if (response && response.content) {
                if (response.content.length != 0) {
                    this.fetchTownship(response.content[0].id)
                }
                this.setState(prevState => ({
                    division_collection: response.content
                }));

            }

        } catch (error) {

        }
    }

    //filter
    handleFilterChange = (e) => {
        const { township_collection_full_list } = this.state
        const value = e.target.value;
        const filteredList = township_collection_full_list.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );

        this.setState({
            township_collection: filteredList
        })
    };

    fetchTownship = async (id) => {
        try {
            var params = {
                page: 0,
                size: 200,
                divisionId: id,
                name: ''
            }
            const response = await ApiFilter(Api.township_filter, params, this.props)
            if (response && response.content) {
                const unsortedTownShip = response.content;
                const sortedTownShip = [...unsortedTownShip].sort((a, b) => a.name.localeCompare(b.name));
                this.setState(prevState => ({
                    township_collection: sortedTownShip,
                    township_collection_full_list: sortedTownShip,
                    selected_division: id
                }));
            }
        } catch (error) {

        }
    }

    saveDivisionChanges = async () => {
        const { division_collection } = this.state
        try {
            let update_division_collection = []
            division_collection && division_collection.map(division => {
                update_division_collection.push({ 'id': division.id, 'enableHomeDelivery': division.enableHomeDelivery })
            })
            const response = await ApiPutBatch(Api.division_batch_update_home_delivery, update_division_collection, this.props)
            if (response.status == 200) {
                this.fetchDivision()
            }
        } catch (error) {
            message.error("Fail to save")
        }
    }

    saveTownShipChanges = async () => {
        const { township_collection, selected_division } = this.state
        try {
            let update_township_collection = []
            township_collection && township_collection.map(township => {
                update_township_collection.push({ 'id': township.id, 'enableHomeDelivery': township.enableHomeDelivery })
            })

            const response = await ApiPutBatch(Api.township_batch_update_home_delivery, update_township_collection, this.props)
            if (response.status == 200) {
                this.fetchTownship(selected_division)
            }
        } catch (error) {
            message.error("Fail to save")
        }
    }

    handleDivisionSwitch = (value) => {
        const { division_collection } = this.state
        let newCollection = []
        division_collection.map((division) => {
            if (value == division.id) {
                let isActive;
                if (division.enableHomeDelivery == true) {
                    isActive = false
                } else {
                    isActive = true
                }
                newCollection.push({ ...division, enableHomeDelivery: isActive })
            } else {
                newCollection.push({ ...division })
            }

        })
        this.setState({
            division_collection: newCollection
        })

    }

    handleTownshipSwitch = (value) => {
        const { township_collection } = this.state
        let newCollection = []
        township_collection.map((township) => {
            if (township.id == value) {
                let isActive;
                if (township.enableHomeDelivery == true) {
                    isActive = false
                } else {
                    isActive = true
                }
                newCollection.push({ ...township, enableHomeDelivery: isActive })
            } else {
                newCollection.push({ ...township })
            }

        })
        this.setState({
            township_collection: newCollection
        })

    }



    render() {
        const { navigate } = this.props
        const { division_collection, township_collection, selected_division } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: 'Available Location'
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12} />
                    <Col span={11}>
                        <Row style={{ marginBottom: 20 }}>
                            <Col span={24} className="custom-btn" style={{ textAlign: 'right' }}>
                                <Input
                                    onChange={this.handleFilterChange}
                                    style={{ width: 200, background: '#f1f1f1', height: 40 }}
                                    placeholder="Enter township"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={1} />
                    <Col span={11}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Division"}
                            extra={<Button type="primary" onClick={() => this.saveDivisionChanges()}>Save Changes</Button>}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={division_collection}
                                renderItem={(item, index) => (
                                    <div className={selected_division == item.id ? "division-box-selected" : "division-box"}>
                                        <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                            <Col span={20} onClick={() => this.fetchTownship(item.id)} >
                                                <span className="location-name">
                                                    {item.name}
                                                </span>

                                            </Col>
                                            <Col span={4} style={{ textAlign: 'right' }}>
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    onChange={() => this.handleDivisionSwitch(item.id)}
                                                    defaultChecked={item.enableHomeDelivery}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                )}
                            />
                        </Card>
                    </Col>

                    <Col span={11}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Township"}
                            extra={<Button type="primary" onClick={() => this.saveTownShipChanges()}>Save Changes</Button>}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={township_collection}
                                renderItem={(item, index) => (
                                    <div className="division-box">
                                        <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                            <Col span={20} >
                                                <span className="location-name">
                                                    {item.name}
                                                </span>
                                            </Col>
                                            <Col span={4} style={{ textAlign: 'right' }}>
                                                <Switch
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    onChange={() => this.handleTownshipSwitch(item.id)}
                                                    checked={item.enableHomeDelivery}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            />
                        </Card>
                    </Col>
                </Row >
            </>
        )
    }

}

export default compose(withRouter)(ManageHomeDeliveryLocation)
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Input, Row, Skeleton, Space, Table, Tag, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../../components/core-component";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import { TitleLevel3 } from "../../../components/general-component";

class DriverPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            isDataFetching: false,
            updateForm: {},
            updateDivision: {},
            filterData: {}

        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            if (filterData && filterData.assignTownshipName && filterData.assignTownshipName.length != 0 && filterData.assignTownshipName.length < 2) {
                message.error("Please enter at least three words")
                this.setState({
                    isDataFetching: false
                })
                return
            }
            var params = {
                ...filterData,
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiFilter(Api.driver_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }

    search = () => {
        this.fetchData(1)
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }


    render() {
        const { data, totalPagination, page, pageSize, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Unique ID',
                dataIndex: 'specialCode',
                key: 'specialCode'
            },
            {
                title: 'Full Name',
                key: 'fullName',
                render: (driver) => (
                    <span>{driver.firstName} {driver.lastName}</span>
                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Assign Township',
                key: 'township',
                render: (shipper) => (
                    <>
                        {
                            shipper.assignTownship ?
                                <span>{shipper.assignTownship.name}</span>
                                :
                                <></>

                        }
                    </>

                ),
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (driver) => (
                    <Space>
                        <Link to={`${CustomPath.driver_update}/${driver.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteDriver(driver.id)}><DeleteOutlined /></Button> */}
                    </Space>
                ),
            },
        ]

        return (
            <>
                <Row style={{ marginBottom: "20px" }} >
                    <Col span={1} />
                    <Col span={22}>
                        <Row style={{ alignItems: 'center' }} gutter={[16, 16]}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <TitleLevel3 label={"Driver"} />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Link to={CustomPath.driver_create}><NJVAddButton /></Link>
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(event) => this.updateFilterData('name', event.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by name"
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(event) => this.updateFilterData('phoneNumber', event.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by phone"
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(event) => this.updateFilterData('assignTownshipName', event.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by assign township"
                                />
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                            </Col>
                            <Col span={24}>

                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            // className="custom-table"
                                            style={{ borderRadius: 10 }}
                                            pagination={{
                                                position: 'top' | 'bottom',
                                                total: totalPagination,
                                                current: page,
                                                onChange: this.handlePaginationChange,
                                                defaultPageSize: pageSize,
                                                showSizeChanger: false
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName}
                                        />
                                }
                            </Col>
                        </Row>

                    </Col>
                </Row>






            </>
        );
    }
}

export default compose(withRouter)(DriverPage)
import {
    EyeOutlined
} from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Modal, Row, Skeleton, Table, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { SvgCopyOutlined } from "../../components/custom-svg";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

class ParcelPickUpPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            totalPagination: 0,
            pageSize: Constant.pageSize,
            data: [],
            trackingList: [],
            isDataFetching: false,
            openTrackingModal: false
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const requestParams = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize
            }
            const response = await ApiHandler({url: Api.pickup_history,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response && response.content) {
                let updatedList = []
                response.content.map((pickupHistory) => {
                    pickupHistory = { ...pickupHistory, key: pickupHistory.id }
                    if (pickupHistory.createdDate) {
                        const newCreatedDate = dayjs(pickupHistory.createdDate, 'YYYY-MM-DD HH:mm:ss');
                        pickupHistory = { ...pickupHistory, createdDate: newCreatedDate.format('DD MMM, YYYY hh:mm A') }
                    }
                    updatedList.push(pickupHistory)
                })

                this.setState({
                    data: updatedList,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page

                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }
    copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            message.success("Copied!")
        } catch (error) {
        }
    };


    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    render() {
        const { page, totalPagination, pageSize, isDataFetching, data, openTrackingModal, trackingList } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Pickup Id',
                dataIndex: 'pickUpId',
                key: 'pickUpId',
            },
            {
                title: 'Shipper',
                key: 'shipper',
                render: (pickupHistory) => (
                    <>
                        {
                            pickupHistory && pickupHistory.shipper ?
                                <>
                                    <div>{pickupHistory.shipper.fullName}</div>
                                    <div>{pickupHistory.shipper.phoneNumber}</div>
                                </>
                                :
                                <></>
                        }
                    </>
                )
            },
            {
                title: 'Driver',
                key: 'driver',
                render: (pickupHistory) => (
                    <>
                        {
                            pickupHistory && pickupHistory.driver ?
                                <>
                                    <div>{pickupHistory.driver.fullName}</div>
                                    <div>{pickupHistory.driver.specialCode}</div>
                                </>
                                :
                                <></>
                        }
                    </>
                )
            }, {
                title: 'Total Orders',
                key: 'noOfTracking',
                render: (pickupHistory) => (
                    <>
                        {
                            pickupHistory && pickupHistory.pickupOrders ?
                                <>
                                    <div>{pickupHistory.pickupOrders.length}</div>
                                </>
                                :
                                <></>
                        }
                    </>
                )
            },
            {
                title: 'Created Date',
                key: 'createdDate',
                dataIndex: 'createdDate'
            }, {
                title: 'View',
                key: 'view',
                dataIndex: '',
                textAlign: 'center',
                width: 10,
                render: (pickupHistory) => (
                    <Button onClick={() => this.setState({ openTrackingModal: true, trackingList: pickupHistory.pickupOrders })} size={'middle'} type="primary" shape="circle" style={{ marginBottom: 5 }}><EyeOutlined /></Button>
                ),
            }
        ]
        return (
            <>

                <Modal
                    title={"Tracking List"}
                    centered
                    open={openTrackingModal}
                    onOk={() => this.setState({ openTrackingModal: false })}
                    footer={null}
                    onCancel={() => this.setState({ openTrackingModal: false })}
                    className='custom-modal'
                >
                    {
                        trackingList && trackingList.map(order =>
                            <div
                                style={{
                                    background: '#f1f1f1',
                                    padding: '5px 10px',
                                    marginBottom: 3,
                                    borderRadius: 5,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 15,
                                        fontWeight: 500,
                                    }}
                                >
                                    {order.trackingNumber}
                                </div>
                                <Button onClick={() => this.copyToClipboard(order.trackingNumber)} size={'middle'} type="text" shape="circle" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><SvgCopyOutlined width={18} height={18} color={'#818181'} /></Button>
                            </div>

                        )
                    }
                </Modal>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={"Parcel Pickup"} />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            style={{ borderRadius: 10 }}
                                            pagination={{
                                                position: 'top' | 'bottom',
                                                total: totalPagination,
                                                current: page,
                                                onChange: this.handlePaginationChange,
                                                defaultPageSize: pageSize,
                                                showSizeChanger: false
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName}
                                        />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
export default compose(withRouter)(ParcelPickUpPage)
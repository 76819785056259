import { Button, Col, Input, Row, Skeleton, Table } from 'antd';
import React, { Component } from 'react';
import { NJVTable } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';

export default class ShipperLoyaltyPoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            filterData: null,
            page: 1,
            pageSize: Constant.pageSize,
            isDataFetching: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, filterData, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        let params = {
            businessName: filterData?.businessName?.trim(),
            phoneNumber: filterData?.phoneNumber?.trim(),
            page: pageNumber ? pageNumber - 1 : page - 1,
            size: pageSize,
        }
        await ApiHandler({ url: Api.shipper_loyalty, method: HTTP_METHOD.GET, requestParams: params })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })

    }

    updateFilterData = (key, value) => {
        this.setState(prev => ({
            filterData: {
                ...prev.filterData,
                [key]: value
            }
        }))
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber);
    };

    render() {
        const { data, totalPagination, page, pageSize, isDataFetching } = this.state

        const getRowClassName = (_, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Business Name',
                key: 'businessName',
                dataIndex: 'businessName'
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Current Month’s Level',
                key: 'currentMonthLevel',
                dataIndex: 'currentMonthLevel',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Previous Month’s Level',
                key: 'previousMonthLevel',
                dataIndex: 'previousMonthLevel',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Current Month Point',
                key: 'currentMonthPoint',
                dataIndex: 'currentMonthPoint',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            },
            {
                title: 'Previous Month Point',
                key: 'previousMonthPoint',
                dataIndex: 'previousMonthPoint',
                align: 'center',
                render: (value) => <p>{value ? value : 'N/A'}</p>
            }
        ]

        return (
            <Row style={{ marginBottom: "20px" }} gutter={[24, 24]}>
                <Col span={1} />
                <Col span={22}>
                    <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                            <TitleLevel3 label={"Shipper Loyalty Point"} />
                        </Col>
                        <Col span={6}>
                            <Input
                                onChange={(event) => this.updateFilterData('businessName', event.target.value)}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                placeholder="Search by Business Name"
                                autoComplete='off'
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                onChange={(event) => this.updateFilterData('phoneNumber', event.target.value)}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                placeholder="Search by Phone Number"
                                autoComplete='off'
                            />
                        </Col>
                        <Col span={6} />
                        <Col span={6}>
                            <Button onClick={() => this.fetchData(1)} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                        </Col>
                        <Col span={24}>
                            {
                            isDataFetching ?
                                <Skeleton active />
                                :
                            <Table
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns}
                                dataSource={data}
                                rowClassName={getRowClassName}
                            />
                            }
                        </Col>
                    </Row>

                </Col>
            </Row>
        )
    }
}

import { InboxOutlined } from "@ant-design/icons";
import { compose } from '@reduxjs/toolkit';
import { Button, Card, Col, Form, Image, Input, Modal, Row, Select, Skeleton, Space, Spin, Table, Tag, Upload, message } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { FormLabelOptional, TitleLevel3 } from '../../components/general-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, uploadAndDownload } from '../../network/network-manager';
import withRouter from '../../network/with-router';
const { Dragger } = Upload;
const { Option } = Select;

class Settlement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            order_history_collection: [],
            historyMap: {},
            titleList: [],

            totalPage: 0,
            pageSize: Constant.pageSize,
            page: 1,
            filterData: {},
            orderStatusList: [],
            current_order_detail: {},

            openUploadExcelModal: false,
            openOrderDetailModal: false,
            openRejectModal: false,
            openApproveModal: false,
            isDataFetching: false,
            isUploading: false,
            isRejecting: false,
            isApproving: false,
            isStatusReady: false
        }
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        let { pageSize, page, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            let params = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize
            }
            if (filterData) {
                if(filterData.businessName || filterData.phoneNumber || filterData.status){
                    filterData = {
                        ...filterData,
                        businessName: filterData.businessName && filterData.businessName?.trim(),
                        phoneNumber: filterData.phoneNumber && filterData.phoneNumber?.trim(),
                    }
                    params = {
                        ...params,
                        ...filterData
                    }
                }
            }
            const response = await ApiHandler({url: Api.shipper_owed,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams: params})
            if (response && response.content) {
                let updatedList = []
                response.content.map((orderHistory) => {
                    if (orderHistory.createdDate) {
                        orderHistory = { ...orderHistory, uploadDate: dayjs(orderHistory.createdDate).format('YYYY-MM-DD HH:MM A') }
                    }
                    updatedList.push(orderHistory)
                    if(orderHistory.status === "READY"){
                        this.setState({
                            isStatusReady: true
                        })
                    }
                })
                this.setState({
                    data: updatedList,
                    totalPage: response.totalElements,
                    page: pageNumber ? pageNumber : page

                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handleFileUpload = async (file) => {
        try {
            this.setState({
                isUploading: true
            })
            let formData = new FormData()
            formData.append('file', file);
            await uploadAndDownload(Api.shipper_owed_excel, formData, "owed-shipper-list")
    
            this.fetchData()
        } catch (error) {
            console.log(error)
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
        this.setState({
            isUploading: false,
            openUploadExcelModal: false
        })
    };

    closeModal = () => {
        if (this.state.isUploading) {
            message.error("You can not close at this time")
        } else {
            this.setState({
                openUploadExcelModal: false
            })
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData
        })
    }

    search = () => {
        this.fetchData(1)
    }

    handleApprove = async () => {
        const { owedShipperId } = this.state;

        try{
            this.setState({
                isApproving: true
            })
            const response = await ApiHandler({url: Api.shipper_owed_approve,method: HTTP_METHOD.PUT,mediaType: MEDIA_TYPE.JSON, specificId: owedShipperId, customMessage: "This Settlement has been approved"})
            if(response){
                this.setState({
                    isApproving: false,
                    openApproveModal: false,
                    isStatusReady: false
                })
            }
            this.fetchData()
        }catch(error){
            console.log(error);
        }
    }

    handleRejectReason = (value) => {
        this.handleReject(value.reason)
    }

    handleReject = async (rejectReason) => {
        try{
            const { owedShipperId } = this.state;
            this.setState({
                isRejecting: true
            })
            const formData = new FormData();
            formData.append('rejectReason',rejectReason)
            const response = await ApiHandler({url: Api.shipper_owed_reject,method: HTTP_METHOD.PUT,mediaType: MEDIA_TYPE.FORM_DATA,requestData: formData,specificId: owedShipperId, customMessage: "This Settlement has been rejected"})
            if(response){
                this.setState({
                    isRejecting: false,
                    openRejectModal: false,
                    isStatusReady: false
                })
            }
            this.fetchData()
        }catch(error){
            console.log(error);
        }
    }

    pagination = (page, size) => {
        this.fetchData(page)
        this.setState({
            page: page - 1
        })
    }

  render() {
    const { isDataFetching, pageSize, totalPage, page, data, openUploadExcelModal, isUploading, openRejectModal, openApproveModal, isRejecting, isApproving } = this.state
    
    const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
    
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Business Name',
                dataIndex: 'businessName',
                key: 'businessName',
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber',
        
            },
            {
                title: 'Amount',
                key: 'amount',
                dataIndex: 'amount',
            },
            {
                title: 'Upload Date',
                key: 'uploadDate',
                dataIndex: 'uploadDate',

            },
            {
                title: 'Evidence',
                key: 'evidence',
                align: 'center',
                dataIndex: 'evidence',
                render : (evidence) => 
                        <Image
                            width={50}
                            src={evidence}
                            preview={evidence ? true : false}
                        />
            },
            {
                title: 'Status',
                key: 'status',
                align: 'center',
                dataIndex: 'status',
                render: (status,row) => {
                    return status === "READY" ? (
                        <Space>
                            <Button type='primary' style={{ background: 'red' }} onClick={() => this.setState({ owedShipperId: row.id, openRejectModal: true })}>Reject</Button>
                            <Button type='primary' onClick={() => this.setState({ owedShipperId: row.id, openApproveModal: true })}>Approve</Button>
                        </Space>) : <Tag color={`${status === 'CANCEL' ? '#FFA500' : status === 'APPROVED' ? '#1677ff' : status === 'REJECT' ? '#ff0000' : status === 'PENDING' ? '#ccc' : ''}`}>{status}</Tag>
                },              
            },
        ]

    return (
        <>
        <Modal
            title={"Upload Settlement Excel file"}
            centered
            open={openUploadExcelModal}
            footer={null}
            onCancel={() => this.closeModal()}
            className='custom-modal'
        >
            <Dragger
                disabled={isUploading}
                {...draggerProps}
                accept={[".xlsx", ".xls"]}
                maxCount={1}
                style={{ marginTop: 15 }}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-hint">
                    {
                        isUploading ?
                            <div>
                                <Spin /> <span> Uploading...</span>
                            </div>
                            :
                            <span>Upload Excel File Only</span>
                    }

                </p>
            </Dragger>
        </Modal>
        <Modal title="Reject Modal" open={openRejectModal} footer={null} onCancel={()=> this.setState({ openRejectModal: false, owedShipperId: null})} onOk={this.handleReject} style={{ top: 250}}>
            <Form
                layout="vertical"
                onFinish={this.handleRejectReason}>
                <Form.Item name="reason" label={"Reason for rejection"} rules={[{ required: true, message: '*(requried)' }]} >
                    <Input.TextArea placeholder="Enter your reason" maxLength={180} style={{ background: '#f5f5f5' }} />
                </Form.Item>
                <Form.Item style={{ textAlign: 'end', marginBottom: 0 }}>
                    <Button style={{ marginRight: 20 }} onClick={() => this.setState({ openRejectModal: false })}>Cancel</Button>
                    <Button type="primary" loading={isRejecting} htmlType="submit">Confirm</Button>
                </Form.Item>
            </Form>
        </Modal>
        <Modal title="Approve Modal" open={openApproveModal} onCancel={() => this.setState({ openApproveModal: false, owedShipperId: null})} onOk={this.handleApprove} style={{ top: 250}} footer={[
            <Button style={{ marginRight: 10 }} onClick={() => this.setState({ openApproveModal: false,owedShipperId: null })}>Cancel</Button>,
            <Button type="primary" loading={isApproving} onClick={this.handleApprove} htmlType="submit">Confirm</Button>
        ]}>
            <p>
                Are you sure want to confirm this customer request?
            </p>
        </Modal>
        <Row>
            <Col span={1} />
            <Col span={22}>
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <TitleLevel3 label={"Settlement"} />
                        <Button type='primary' onClick={() => this.setState({ openUploadExcelModal: true })}>Upload 
                        Settlement</Button>
                    </Col>
                    <Col span={24}>
                        <Card
                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none', paddingTop: 15, paddingBottom: 15 }}
                            bordered={false}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <FormLabelOptional label="Business Name" />
                                    <Input size="large" placeholder={"Business Name"} onChange={(event) => this.updateFilterData('businessName', event.target.value)} />
                                </Col>
                                <Col span={6}>
                                    <FormLabelOptional label="Phone Number" />
                                    <Input size="large" placeholder={"Phone Number"} onChange={(event) => this.updateFilterData('phoneNumber', event.target.value)} />
                                </Col>
                                <Col span={6}>
                                    <FormLabelOptional label="Status" />
                                    <Select
                                        onChange={(value) => this.updateFilterData('status', value)}
                                        size="large"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        placeholder="Select status">
                                        <Option value={"PENDING"}>PENDING</Option>
                                        <Option value={"APPROVED"}>APPROVED</Option>
                                        <Option value={"REJECT"}>REJECT</Option>
                                        <Option value={"CANCEL"}>CANCEL</Option>
                                        <Option value={"READY"}>READY</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                <FormLabelOptional label="&nbsp;" />
                                    <Button
                                        size="large"
                                        style={{ width: '100%' }}
                                        type="primary" onClick={() => this.search()}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    {
                        isDataFetching ?
                            <Col span={24}>
                                <Skeleton active />
                            </Col>
                            :
                            <Col span={24}>
                                <Table
                                    rowKey={(record) => record.id }
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPage,
                                        current: page,
                                        onChange: this.pagination,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                            </Col>

                    }
                </Row>
            </Col>
        </Row>

    </>
    )
  }
}

export default compose(withRouter)(Settlement)
import { Button, Col, Row, Skeleton, Tag } from 'antd';
import React from 'react'
import { TitleLevel3 } from '../../../components/general-component';
import { Link } from 'react-router-dom';
import { NJVTable } from '../../../components/core-component';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../../network/with-router';
import { Constant } from '../../../core/constant';
import CustomPath from '../../../routes/custom-path';

const colorOfLevel = (value) => {
    return value === 'Normal' ? '#808080' : value === 'Gold' ? '#FFD700' : value === 'Platinum' ? '#E5E4E2' : null;
}

class LoyaltyReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    'businessName': 'Super Ninja',
                    'phoneNumber': '0912344221',
                    'prevParcel': 100,
                    'currentParcel': 50,
                    'currentUser': 'Gold',
                    'upcomingUser': 'Normal'
                }
            ],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            isDataFetching: false
        }
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

render() {
    const { data, totalPagination, page, pageSize, isDataFetching } = this.state

    const getRowClassName = (record, index) => {
        return index % 2 === 0 ? 'blue-row' : 'black-row';
    };

    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
            width: 5
        },
        {
            title: 'Business Name',
            key: 'businessName',
            dataIndex: 'businessName'
        },
        {
            title: 'Phone Number',
            key: 'phoneNumber',
            dataIndex: 'phoneNumber'
        },
        {
            title: 'Previous Month’s Parcel',
            key: 'prevParcel',
            dataIndex: 'prevParcel',
            align: 'center'
        },
        {
            title: 'Current Month’s Parcel',
            key: 'currentParcel',
            dataIndex: 'currentParcel',
            align: 'center'
        },
        {
            title: 'Current User Level',
            key: 'currentUser',
            dataIndex: 'currentUser',
            align: 'center',
            render: (value) => (
                <Tag color={colorOfLevel(value)}>{value}</Tag>
            )
        },
        {
            title: 'Upcoming User Level',
            key: 'upcomingUser',
            dataIndex: 'upcomingUser',
            align: 'center',
            render: (value) => (
                <Tag color={colorOfLevel(value)}>{value}</Tag>
            )
        },
         {
            title: 'Usage History',
            key: 'history',
            dataIndex: '',
            align: 'center',
            render: () => (
                <Link to={`${CustomPath.loyalty_shop_report_history}`}><Button type="primary" >View</Button></Link>
            ),
        },
    ]

    return (
        <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
            <Col span={1} />
            <Col span={22}>
                <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Loyalty Report"} />
                    </Col>
                    <Col span={24}>
                        {/* {
                            isDataFetching ?
                                <Skeleton active />
                                : */}
                                <NJVTable
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                />
                        {/* } */}
                    </Col>
                </Row>

            </Col>
        </Row>
    )
}
}

export default compose(withRouter)(LoyaltyReport)
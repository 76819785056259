import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Modal, Row, Skeleton, Space, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class ProductCategoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: false,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {}
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    deleteProductCategory = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
    }

    deleteProductCategoryConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiHandler({url: Api.product_category,method: HTTP_METHOD.DELETE,mediaType: MEDIA_TYPE.JSON,specificId: deleteItem})
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    fetchData = async (pageNumber, value) => {
        const { page, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1
        }
        await ApiHandler({url: Api.product_category_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
        .then(response => {
            this.setState({
                data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
            });
        }).catch(error => {

        }).finally(() => {
            this.setState({
                isDataFetching: false
            })
        })
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber);
    };


    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Product Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (productCategory) => (
                    <Space>
                        <Link to={`${CustomPath.product_category_update}/${productCategory.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteProductCategory(productCategory.id)}><DeleteOutlined /></Button> */}
                    </Space>
                ),
            },
        ]

        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteProductCategoryConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Product Category"} />
                    </Col>
                    <Col span={11} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.product_category_create}><NJVAddButton /></Link>
                    </Col>
                    <Col span={1} />
                </Row>


                <Row justify='center'>
                    <Col span={1}></Col>
                    <Col span={22}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    // className="custom-table"
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>

            </>
        );
    }
}


export default compose(withRouter)(ProductCategoryPage)
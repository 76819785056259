import React, { useEffect, useState } from 'react'
import { GetListFromEnumObject } from '../core/nvm-utils'
import { ApiHandler } from '../network/network-manager';
import Api from '../network/api';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import { Select } from 'antd';

const DynamicEnumSelect = (props) => {
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchData()
    }, [props.enumtype])

    const fetchData = async () => {
        setLoading(true)
        var requestParams = {
            'enumType': props.enumtype
        }
        await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                setOptions(GetListFromEnumObject(response))
            }).catch((error) => { })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Select
            loading={loading} options={options}
            {...props}
        />
    )
}

export default DynamicEnumSelect
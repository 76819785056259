import { Button, Col, Row, Tag } from 'antd'
import React, { Component } from 'react'
import { TitleLevel3 } from '../../../components/general-component'
import { NJVTable } from '../../../components/core-component';
import dayjs from 'dayjs'
import { Constant } from '../../../core/constant';
import withRouter from '../../../network/with-router';
import { compose } from '@reduxjs/toolkit';
import CustomPath from '../../../routes/custom-path';

const colorOfLevel = (value) => {
    return value === 'Normal' ? '#808080' : value === 'Gold' ? '#FFD700' : value === 'Platinum' ? '#E5E4E2' : null;
}

class LoyaltyHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [{
                'shopName': 'Ninja Shop',
                'level': 'Gold',
                'discount': 3,
                'amount': 3000,
                'date': new Date()
            }],
            page: 1,
            pageSize: Constant.pageSize,
            isDataFetching: false
        }
    }

    render() {
        const { data, totalPagination, page, pageSize, isDataFetching } = this.state
        const { navigate } = this.props;

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
            },
            {
                title: 'Shop Name',
                key: 'shopName',
                dataIndex: 'shopName'
            },
            {
                title: 'User level',
                key: 'level',
                dataIndex: 'level',
                align: 'center',
                render: (value) => (
                    <Tag color={colorOfLevel(value)}>{value}</Tag>
                )
            },
            {
                title: 'Loyalty Discount Parcent',
                key: 'discount',
                dataIndex: 'discount',
                align: 'center',
                render: (value) => <span>{value}%</span>
            },
            {
                title: 'Spent Amount',
                key: 'amount',
                dataIndex: 'amount',
                align: 'center',
            },
            {
                title: 'Date',
                key: 'date',
                dataIndex: 'date',
                align: 'center',
                render: (value) => dayjs(value).format('YYYY-MM-DD')
            },
        ]

        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                        <Col span={12}>
                            <TitleLevel3 label="Super Ninja" />
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={() => navigate(CustomPath.loyalty_shop_report)}>Back</Button>
                        </Col>
                        <Col span={24}>
                            {/* {
                            isDataFetching ?
                                <Skeleton active />
                                : */}
                            <NJVTable
                                pagination={{
                                    position: 'top' | 'bottom',
                                    total: totalPagination,
                                    current: page,
                                    onChange: this.handlePaginationChange,
                                    defaultPageSize: pageSize,
                                    showSizeChanger: false
                                }}
                                columns={columns}
                                dataSource={data}
                                rowClassName={getRowClassName}
                            />
                            {/* } */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(LoyaltyHistory)

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Input, Modal, Row, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class CoveragePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: false,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            searchName: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    //filter 
    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    deleteCoverage = (id) => {
        this.setState({
            openModal: true,
            deleteItem: id
        });
        
    }

    deleteCoverageConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiHandler({url: Api.coverage,method: HTTP_METHOD.DELETE,mediaType: MEDIA_TYPE.JSON, specificId: deleteItem})
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, searchName, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })

        const requestParams = {
            name: searchName ? searchName : '',
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1
        }
        await ApiHandler({url: Api.coverage_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams})
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {

            }).finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber);
    };


    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Cover Name',
                dataIndex: 'name',
                key: 'name',
            }, {
                title: 'Coverage Type',
                dataIndex: 'coverageType',
                key: 'coverageType',
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { active }) => (
                    <>
                        {
                            (
                                active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (coverage) => (
                    <Space>
                        <Link to={`${CustomPath.coverage_update}/${coverage.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteCoverage(coverage.id)}><DeleteOutlined /></Button>

                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteCoverageConfirm()}
                    onCancel={() => this.setState({ openModal: false })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Coverage & Price"} />
                    </Col>
                    <Col span={11} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.coverage_create}><NJVAddButton /></Link>
                    </Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: 30 }}>
                    <Col span={1} />
                    <Col span={22} className="custom-btn">
                        <Input
                            onChange={this.changeSearchName}
                            style={{ width: 200, background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by cover name"
                        />
                        <Button size="large" onClick={() => this.fetchData()} type="primary" >Search</Button>

                    </Col>
                </Row>
                <Row justify='center'>
                    <Col span={1}></Col>
                    <Col span={22}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>

            </>
        );
    }
}


export default compose(withRouter)(CoveragePage)
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { ApiFilter, ApiPost } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";


class AdminForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleData: [],
            roleOptions: [],
            currentDate: '',
            isLoading: false
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        const date = dayjs();
        this.setState({ currentDate: date.subtract(18, 'year') })
        this.fetchRole()

    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };

    fetchRole = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.role_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    roleData: response.content
                });
            }
        } catch (error) {
        }
    }

    saveAdmin = async (values) => {
        this.setState({
            isLoading: true
        })
        try {
            if (values.primaryPhoneNumber.length > 12 || values.primaryPhoneNumber.length < 5) {
                message.destroy()
                message.error("Invalid Phone Number")
                return
            }
            if (values.dateOfBirth === undefined) {
                const date = this.currentDate()
                values = { ...values, dateOfBirth: date.format("YYYY-MM-DD") }
            }


            const response = await ApiPost(Api.admin,
                {
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "dateOfBirth": values.dateOfBirth,
                    // "profileUrl": activeStatus,
                    "gender": values.gender,
                    "userStatus": values.active ? "ACTIVE" : "INACTIVE",
                    "phoneNumber": values.primaryPhoneNumber,
                    "accounts": [
                        {
                            "loginId": values.primaryPhoneNumber,
                            "primaryPhoneNumber": values.primaryPhoneNumber,
                            "password": values.password,
                            "accountType": "PHONE"
                        }
                    ],
                    "role": {
                        "id": values.role
                    }
                }, this.props
            )

            if (response.status === 200) {
                this.props.navigate(CustomPath.admin)
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        return dayjs().subtract(18, 'year')
    }




    render() {
        const { navigate } = this.props
        const { roleData, isLoading } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.admin}>Admin</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Admin Form"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.admin)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                initialValues={{ gender: 'MALE', active: true }}
                                onFinish={this.saveAdmin}
                            >
                                <Row>
                                    <Col span={12} style={{ paddingRight: 5 }}>
                                        <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter first name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: 5 }}>
                                        <Form.Item name="lastName" label={"Last Name"}>
                                            <Input placeholder={"Enter last name"} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} style={{ paddingRight: 5 }}>
                                        <Form.Item
                                            name="primaryPhoneNumber"
                                            label={"Phone Number"}
                                            rules={[
                                                { required: true, message: '*(requried)' }
                                            ]}>
                                            <Input placeholder={"979xxxxxxxx"} onChange={this.handlePhoneNumberChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: 5 }}>
                                        <Form.Item name="password" label={"Password"}
                                            rules={[{ required: true, message: '*(requried)' },
                                            { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                            <Input.Password size="small" placeholder="Enter password" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} style={{ paddingRight: 5 }}>
                                        <Form.Item name="gender" label={"Gender"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                size="large"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Select gender"
                                                options={[
                                                    {
                                                        label: 'Male',
                                                        value: 'MALE',
                                                    },
                                                    {
                                                        label: 'Female',
                                                        value: 'FEMALE',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col span={12} style={{ paddingLeft: 5 }}>
                                        <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                            <DatePicker
                                                disabledDate={this.disabledDate}
                                                defaultValue={this.currentDate}
                                                allowClear={false}
                                                size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked">
                                            <Checkbox>Active</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {
                                        roleData && roleData.length !== 0 ?
                                            <Col span={24}>
                                                <Form.Item name="role" label={"Role"}>
                                                    <Radio.Group
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Row>
                                                            {
                                                                roleData.map((value) =>
                                                                    <Col span={24} style={{ marginBottom: 5 }}>
                                                                        <Radio value={value.id}>{value.roleTypeName}</Radio>
                                                                    </Col>
                                                                )

                                                            }
                                                        </Row>
                                                    </Radio.Group>

                                                </Form.Item>
                                            </Col>
                                            :
                                            <></>
                                    }
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    {/* <Button style={{ marginRight: '20px' }}>Reset</Button> */}
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(AdminForm)
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPut } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class AdminUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleData: [],
            adminAccount: {},
            isLoading: false
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
        this.fetchRole()
    }

    fetchRole = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.role_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    roleData: response.content
                });
            }
        } catch (error) {
        }
    }

    fetchData = async () => {
        try {
            const response = await ApiGet(Api.admin, this.props.params.id, this.props)
            if (response) {
                this.setState(prevState => ({
                    data: response,
                }));
                let admin;
                if (response.dateOfBirth) {
                    const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
                    admin = { ...response, dateOfBirth: dob }
                } else {
                    admin = { ...response }
                }
                if (response.userStatus === 'ACTIVE') {
                    admin = { ...admin, active: true }
                } else {
                    admin = { ...admin, active: false }
                }
                if (admin.role) {
                    admin = { ...admin, role: admin.role.id }
                }
                if (admin.accounts) {
                    admin.accounts.map((account) => {
                        if (account.accountType === "PHONE") {
                            admin = { ...admin, primaryPhoneNumber: account.primaryPhoneNumber, password: account.password }
                            this.setState({
                                adminAccount: account
                            })
                        }
                    })
                }
                if (admin.roles) {
                    let roles = []
                    admin.roles.map((role) => {
                        roles.push(role.id)
                    })
                    admin = { ...admin, roles }
                }
                this.inputRef.current.setFieldsValue(admin);
            }
        } catch (error) {

        }
    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };


    updateAdmin = async (values) => {
        const { adminAccount, data } = this.state
        this.setState({
            isLoading: true
        })
        try {
            const response = await ApiPut(Api.admin, data.id,
                {
                    "id": data.id,
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "dateOfBirth": values.dateOfBirth.format("YYYY-MM-DD"),
                    // "profileUrl": activeStatus,
                    "phoneNumber": values.primaryPhoneNumber,
                    "gender": values.gender,
                    "userStatus": values.active ? "ACTIVE" : "INACTIVE",
                    "accounts": [
                        {
                            "id": adminAccount.id,
                            "loginId": values.primaryPhoneNumber,
                            "primaryPhoneNumber": values.primaryPhoneNumber,
                            "password": values.password,
                            "accountType": "PHONE"
                        }
                    ],
                    "role": {
                        "id": values.role
                    }
                }, this.props
            )
            if (response.status === 200) {
                this.props.navigate(CustomPath.admin)
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }


    render() {
        const { navigate } = this.props
        const { roleData, isLoading } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.admin}>Admin</Link>,
                                },
                                {
                                    title: 'Update',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>

                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Admin Form Update"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.admin)}>Back</Button>}
                        >

                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                onFinish={this.updateAdmin}>
                                <Row>
                                    <Col span={12} style={{ paddingRight: 5 }}>
                                        <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter first name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: 5 }}>
                                        <Form.Item name="lastName" label={"Last Name"}>
                                            <Input placeholder={"Enter last name"} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} style={{ paddingRight: 5 }}>
                                        <Form.Item name="primaryPhoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"979xxxxxxxx"} onChange={this.handlePhoneNumberChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: 5 }}>
                                        <Form.Item name="password" label={"Password"} rules={[{ required: true, message: '*(requried)' }, { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                            <Input.Password size="small" placeholder="Enter password" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} style={{ paddingRight: 5 }}>
                                        <Form.Item name="gender" label={"Gender"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                size="large"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Select gender"
                                                options={[
                                                    {
                                                        label: 'Male',
                                                        value: 'MALE',
                                                    },
                                                    {
                                                        label: 'Female',
                                                        value: 'FEMALE',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col span={12} style={{ paddingLeft: 5 }}>
                                        <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                            <DatePicker
                                                disabledDate={this.disabledDate}
                                                defaultValue={this.currentDate}
                                                allowClear={false}
                                                size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked">
                                            <Checkbox>Active</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {
                                        roleData && roleData.length !== 0 ?
                                            <Col span={24}>
                                                <Form.Item name="role" label={"Role"}>
                                                    <Radio.Group
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Row>
                                                            {
                                                                roleData.map((value) =>
                                                                    <Col span={24} style={{ marginBottom: 5 }}>
                                                                        <Radio value={value.id}>{value.roleTypeName}</Radio>
                                                                    </Col>
                                                                )

                                                            }
                                                        </Row>
                                                    </Radio.Group>

                                                </Form.Item>
                                            </Col>
                                            :
                                            <></>
                                    }
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Update</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(AdminUpdate)
import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Skeleton, Table } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NJVAddButton } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../routes/custom-path';
import BannerItems from './banner-items-page';

class LoyaltyBannerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            currentValue: {currentName: '',currentBannerId: 0},
            isDataFetching: false,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            isDrawerOpen: false,
        }
    }

    componentDidMount() {
        this.fetchData()
    }


    fetchData = () => {
        this.setState({
            isDataFetching: true
        })
        ApiHandler({ url: Api.banner_list, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    data: response
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    render() {
        const { data, totalPagination, pageSize, page, isDrawerOpen, isDataFetching, currentValue } = this.state;
        
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Banner Name',
                dataIndex: 'bannerName',
                key: 'name',
            },
            {
                title: 'Banner Type',
                dataIndex: 'type',
                key: 'type',
                render: (value) => value === 'SHIPPER_DASHBOARD' ? 'Shipper Dashboard' : value === 'LOYALTY_SHOP_DASHBOARD' ? 'Loyalty Shop Dashboard' : 'Default'
            },
            {
                title: 'Start Time',
                dataIndex: 'startTime',
                key: 'startTime',
                align: 'center',
                render : (value) => value.slice(':',5)
            },
            {
                title: 'End Time',
                dataIndex: 'endTime',
                key: 'endTime',
                align: 'center',
                render : (value) => value.slice(':',5)
            },
            {
                title: 'Items',
                key: 'items',
                dataIndex: '',
                textAlign: 'center',
                align: 'center',
                render: (record) => {
                    return (
                        <Button type="primary" onClick={() => this.setState({ isDrawerOpen: true, currentValue : { currentName: record.bannerName,currentBannerId: record.id} })}>View Items</Button>
                    )
                },
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (record) => (
                    <Link to={CustomPath.banner_update} state={{updateBanner: record}}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                ),
            },
        ]

        return (
            <>
                <Drawer
                    title={currentValue?.currentName}
                    width="50%"
                    height={500}
                    closable={false}
                    onClose={() => this.setState({ isDrawerOpen: false })}
                    open={isDrawerOpen}
                    extra={
                        <Button onClick={() => this.setState({ isDrawerOpen: false })}>Close</Button>
                    }
                >
                    <BannerItems bannerId={currentValue?.currentBannerId} refresh={isDrawerOpen} />
                </Drawer>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Loyalty Banner"} />
                    </Col>
                    <Col span={11} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.banner_create}><NJVAddButton /></Link>
                    </Col>
                    <Col span={1} />
                </Row>
                <Row justify='center'>
                    <Col span={1}></Col>
                    <Col span={22}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>
            </>

        )
    }
}

export default LoyaltyBannerPage
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Row, Select, Skeleton, Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { v4 as uuidv4 } from 'uuid';
import Theme from "../../../components/theme";
import { GetListFromEnumObject } from "../../../core/nvm-utils";
import Api from "../../../network/api";
import { ApiFilter, ApiPost } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";




class RoleForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roles: [],
            menus: [],
            menusData: [],
            isLoading: false,
            is_fetching_menu: false
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        this.fetchRoles()
        this.fetchMenus()
    }

    fetchRoles = async () => {
        try {
            const params = {
                enumType: 'ROLE'
            }
            const response = await ApiFilter(Api.enum_filter, params, this.props)
            if (response) {
                this.setState({
                    roles: GetListFromEnumObject(response),
                });
            }
        } catch (error) {
        }
    }

    fetchMenus = async () => {
        this.setState({
            is_fetching_menu: true
        })
        try {
            const params = {
                enumType: 'MENU'
            }
            const response = await ApiFilter(Api.enum_filter, params, this.props)
            if (response) {
                let list = []
                response.map(role => {
                    const uid = uuidv4()
                    list.push({ uid: uid, label: role.value, value: role.key, read: false, write_edit: false, delete: false })
                })
                this.setState({
                    menusData: list
                });
            }
        } catch (error) {

        }
        this.setState({
            is_fetching_menu: false
        })
    }





    saveRole = async (formData) => {
        const { menusData } = this.state
        this.setState({
            isLoading: true
        })
        try {
            let new_menu_list = []
            menusData && menusData.map(menu => {
                if (menu.read || menu.write_edit || menu.delete) {
                    new_menu_list.push({ name: menu.value, read: menu.read, write: menu.write_edit, delete: menu.delete })
                }
            })
            const response = await ApiPost(Api.role,
                {
                    "roleType": formData.roles,
                    "description": formData.description,
                    "menus": new_menu_list
                }, this.props
            )
            if (response.status == 200) {
                this.props.navigate(CustomPath.role)
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }


    onChangePermission = (uid, permission, event) => {
        const { menusData } = this.state
        let newMenuData = []
        menusData.map(menu => {
            if (menu.uid === uid) {
                if (permission === 'READ') {
                    menu.read = event.target.checked
                } else if (permission === 'WRITE_EDIT') {
                    menu.write_edit = event.target.checked
                } else if (permission === 'DELETE') {
                    menu.delete = event.target.checked
                }
            }
            newMenuData.push(menu)
        })
        this.setState({
            menusData: newMenuData
        })
    }

    render() {
        const { navigate } = this.props
        const { roles, menusData, isLoading, is_fetching_menu } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.role}>Role & Permission</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Role Form"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.role)}>Back</Button>}
                        >

                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                onFinish={this.saveRole}>

                                <Form.Item name="roles" label={"Role"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select role"
                                        onChange={this.handleChange}
                                        options={roles}
                                    />
                                </Form.Item>
                                <Form.Item name="description" label={"Description"}>
                                    <Input placeholder={"Enter description"} />
                                </Form.Item>

                                <Row style={{
                                    marginBottom: 20,
                                    marginTop: 20,
                                    borderRadius: 8,
                                    backgroundColor: 'white',
                                    padding: 12
                                }}>

                                    <Col span={12}>
                                        <span>Menu</span>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <span>Read</span>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <span>Write / Edit</span>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <span>Delete</span>
                                    </Col>
                                </Row>
                                {
                                    is_fetching_menu ?
                                        <Skeleton active />
                                        :
                                        <>
                                            {
                                                menusData.map((menu, index) => {
                                                    return <><Row style={{ marginBottom: 10, paddingLeft: 12, paddingRight: 12 }}>

                                                        <Col span={12}>
                                                            {menu.label}
                                                        </Col>
                                                        <Col span={4} style={{ textAlign: 'center' }}>
                                                            <Checkbox
                                                                checked={menu.read}
                                                                onChange={(e) => this.onChangePermission(menu.uid, 'READ', e)}
                                                            />
                                                        </Col>
                                                        <Col span={4} style={{ textAlign: 'center' }}>
                                                            <Checkbox
                                                                checked={menu.write_edit}
                                                                onChange={(e) => this.onChangePermission(menu.uid, 'WRITE_EDIT', e)}
                                                            />
                                                        </Col>
                                                        <Col span={4} style={{ textAlign: 'center' }}>
                                                            <Checkbox
                                                                checked={menu.delete}
                                                                onChange={(e) => this.onChangePermission(menu.uid, 'DELETE', e)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                        <Divider style={{ margin: 10 }} />
                                                    </>
                                                })
                                            }
                                        </>
                                }

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ marginTop: 10 }} loading={isLoading}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(RoleForm)
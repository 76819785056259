import { ArrowLeftOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Skeleton, Spin, Table, Tag, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../../components/core-component";
import { TitleLevel3 } from "../../../components/general-component";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const PICK_UP = 1
const DROP_OFF = 2

class PartnershipAdminPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            parentData: props.location.state?.data || {},
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchName: '',
            searchPhone: '',
            updated_id: null,
            updateForm: {},
            updateDivision: {},

            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            isDataFetching: false,
            updating_pickup: false,
            updating_dropoff: false
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { parentData, page, searchName, searchPhone, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var requestParams = {
                name: searchName,
                phone: searchPhone,
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1,
                partnershipId: parentData.id
            }
            const response = await ApiHandler({url: Api.partnership_store_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.GET, requestParams})
            if (response) {
                let tempDataList = response && response.content.map((item) => item = { ...item, key: item.id })
                this.setState({
                    data: tempDataList,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }

    search = () => {
        const { page } = this.state
        this.fetchData(page)
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    gotoStoreCreate = () => {
        const { parentData } = this.state
        let data = {
            partnershipAdminId: parentData.id,
            partnershipAdminName: parentData.name,
        }
        this.props.navigate(
            CustomPath.partnership_store_create,
            { state: { data: data } }
        );
    }
    gotoStoreUpdate = (id) => {
        const { parentData } = this.state
        let data = {
            partnershipAdminId: parentData.id,
            partnershipAdminName: parentData.name,
        }
        let route = CustomPath.partnership_store_update + '/' + id
        this.props.navigate(
            route,
            { state: { data: data } }
        );
    }

    updatePickupDropOff = async (type, id, check, defaultValue) => {
        const { updating_dropoff, updating_pickup, data } = this.state
        if (type === PICK_UP && updating_pickup) {
            message.info("Please wait another item is updating")
            return
        }
        if (type === DROP_OFF && updating_dropoff) {
            message.info("Please wait another item is updating")
            return
        }
        if (type === PICK_UP) {
            this.setState({
                updated_id: id,
                updating_pickup: true
            })
        }
        if (type === DROP_OFF) {
            this.setState({
                updated_id: id,
                updating_dropoff: true
            })
        }

        const requestData = {
            'id': id,
            'enablePickup': type === PICK_UP ? check : defaultValue,
            'enableDropOff': type === DROP_OFF ? check : defaultValue,
        }
        await ApiHandler({url: Api.partnership_store_update_pickup_drop_off,method: HTTP_METHOD.PUT,mediaType: MEDIA_TYPE.JSON, requestData})
            .then((response) => {
                let tempDataList = data && data.map((item) => {
                    if (item.id === response.data.id) {
                        if (type === PICK_UP) {
                            item = { ...item, 'enablePickup': response.data.enablePickup }
                        } else if (type === DROP_OFF) {
                            item = { ...item, 'enableDropOff': response.data.enableDropOff }
                        }
                    }
                    return item
                })
                if (type === PICK_UP) {
                    this.setState({
                        updated_id: null,
                        updating_pickup: false,
                        data: tempDataList
                    })
                }
                if (type === DROP_OFF) {
                    this.setState({
                        updated_id: null,
                        updating_dropoff: false,
                        data: tempDataList
                    })
                }
            }).catch((error) => {
                message.error("Fail to update")
                if (type === PICK_UP) {
                    this.setState({
                        updated_id: null,
                        updating_pickup: false
                    })
                }
                if (type === DROP_OFF) {
                    this.setState({
                        updated_id: null,
                        updating_dropoff: false
                    })
                }
            })
    }


    render() {
        const { parentData, data, totalPagination, page, pageSize, isDataFetching, updating_dropoff, updating_pickup, updated_id } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Store Name',
                key: 'fullName',
                dataIndex: 'fullName'
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Store Code',
                dataIndex: 'specialCode',
                key: 'specialCode'
            },
            {
                title: 'Pickup',
                key: 'pickup',
                render: (item) => (
                    <>
                        {
                            (
                                updating_pickup && item.id === updated_id ?
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 24,
                                                }}
                                                spin
                                            />
                                        }
                                    /> :
                                    <Checkbox checked={item.enablePickup} onChange={(event) => this.updatePickupDropOff(PICK_UP, item.id, event.target.checked, item.enableDropOff)} />
                            )
                        }
                    </>
                ),
            },
            {
                title: 'Drop Off',
                key: 'dropOff',
                render: (item) => (
                    <>
                        {
                            (
                                updating_dropoff && item.id === updated_id ?
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 24,
                                                }}
                                                spin
                                            />
                                        }
                                    /> :
                                    <Checkbox checked={item.enableDropOff} onChange={(event) => this.updatePickupDropOff(DROP_OFF, item.id, event.target.checked, item.enablePickup)} />
                            )
                        }
                    </>
                ),
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (store) => (
                    <Button size={'middle'} type="primary" shape="circle" onClick={() => this.gotoStoreUpdate(store.id)}><EditOutlined /></Button>
                ),
            },
        ]

        return (
            <>
                <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
                    <Col span={1} />
                    <Col span={22}>
                        <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Link to={CustomPath.partnership_admin}><Button type="primary" style={{ marginRight: 10 }}><ArrowLeftOutlined /></Button></Link>
                                <TitleLevel3 label={`${parentData.name}'s Stores`} />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <NJVAddButton handleClick={this.gotoStoreCreate} />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            style={{ borderRadius: 10 }}
                                            pagination={{
                                                position: 'top' | 'bottom',
                                                total: totalPagination,
                                                current: page,
                                                onChange: this.handlePaginationChange,
                                                defaultPageSize: pageSize,
                                                showSizeChanger: false
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName}
                                        />
                                }
                            </Col>
                        </Row>

                    </Col>
                </Row>






            </>
        );
    }
}

export default compose(withRouter)(PartnershipAdminPage)
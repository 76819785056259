import { message } from 'antd';
import axios from 'axios';
import { LOC_getRefreshToken, LOC_storeToken } from '../../core/custom-local-storage';
import Api from '../../network/api';
import { updateMenu } from '../../redux/menu-slice';
import { updateRoleName, updateRoleType, updateUserName } from '../../redux/profile-slice';

const axiosInstance = axios.create({
    baseURL: Api.host
});

export const Authenticate = async (phoneNumber, password, props) => {
    try {
        const response = await axiosInstance.post(Api.login, {
            "username": phoneNumber,
            "password": password
        }, { headers: { 'accountType': 'ADMIN' } })
        if (response && response.status === 200) {
            LOC_storeToken(response.data)
            if (response.data) {
                if (response.data.menuList) {
                    props.dispatch(updateMenu(response.data.menuList))
                }
                let userName = ""
                if (response.data.firstName) {
                    userName = response.data.firstName
                }
                if (response.data.lastName) {
                    userName = userName + " " + response.data.lastName
                }
                props.dispatch(updateUserName(userName))
                props.dispatch(updateRoleType(response.data.roleType))
                props.dispatch(updateRoleName(response.data.roleName))
                
            }
            return true
        } else if (response && response.status === 401) {
            message.error("Phone Number or Password wrong!")
            return false
        }
    } catch (error) {
        message.error("Phone Number or Password wrong!")
        return false
    }
}


export const RefreshToken = async () => {
    try {
        const refreshToken = LOC_getRefreshToken()
        const response = await axiosInstance.post(Api.refresh_token, {
            "refreshToken": refreshToken
        })
        if (response && response.status === 200) {
            return response.data
        }
    } catch (error) {
        message.destroy()
        message.error("Please Login Again!")
    }
}


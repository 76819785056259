import { compose } from "@reduxjs/toolkit";
import { Col, Image, Modal, Row, Skeleton, Table } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { ApiFilter, ApiPut } from "../../network/network-manager";
import withRouter from "../../network/with-router";


class ShipperRequestSupport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isDataFetching: false,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            searchName: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize } = this.state;
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                page: 0,
                size: pageSize
            }
            const response = await ApiFilter(Api.order_issue, params, this.props)
            if (response && response.content) {
                let newList = []
                response.content.map(issue => {
                    if (issue.createdDate) {
                        issue = { ...issue, createdDate: dayjs(issue.createdDate).format('YYYY-MM-DD HH:MM A') }
                    }
                    newList.push(issue)
                })

                this.setState({
                    data: newList,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }



    resolveConfirm = async (id, status) => {
        const { data } = this.state
        try {
            let request = {
                status: 'COMPLETE'
            }
            if (!status) {
                request = { status: 'CLOSED' }
            }
            const response = await ApiPut(Api.order_issue_update_to_complete, id, request, this.props)
            if (response && response.data) {
                let newData = []
                data.map(orderIssue => {
                    if (orderIssue.id === response.data.id) {
                        newData.push(response.data)
                    } else {
                        newData.push(orderIssue)
                    }
                })
                this.setState({
                    data: newData
                })
            }
        } catch (error) {

        }
        this.setState({
            openModal: false
        })
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber);
    };

    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Tracking Number',
                key: 'trackingNumber',
                render: (issue) => (
                    <>
                        {
                            issue && issue.order ?
                                <div>{issue.order.trackingNumber}</div>

                                :
                                <></>
                        }
                    </>
                ),
            }, {
                title: 'Shipper Info',
                key: 'shipperInfo',
                render: (issue) => (
                    <>
                        <div>{issue.shipperBusinessName}</div>
                        <div>{issue.shipperBusinessPhoneNumber}</div>
                    </>
                ),
            },
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
            },
            {
                title: 'Sub-Category',
                dataIndex: 'subCategory',
                key: 'subCategory',
            },
            {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
            },
            {
                title: 'Submit Date ',
                dataIndex: 'createdDate',
                key: 'createdDate',
            },
            {
                title: 'Solve Date ',
                key: 'solveDate',
                render: (issue) => (
                    <>
                        {
                            issue.status === 'COMPLETE' ?
                                <>
                                    {dayjs(issue.modifiedDate).format('YYYY-MM-DD HH:MM A')}
                                </>
                                : <></>
                        }
                    </>
                )
            },
            {
                title: 'Photo',
                key: 'complaintPhoto',
                render: (issue) => (
                    <>
                        {
                            issue.category === 'Complaint Issue' && issue.imageUrl !== null ?
                                <Image
                                    style={{
                                        marginTop: 5,
                                        borderRadius: 9,
                                        cursor: 'pointer',
                                        padding: 10,
                                        border: '1px solid grey'
                                    }}
                                    onClick={() => this.setState({ viewPhoto: true })}
                                    src={issue.imageUrl}
                                    width={50}
                                />
                                :
                                <></>
                        }
                    </>
                ),
            },
            // {
            //     title: 'Action',
            //     key: 'action',
            //     dataIndex: '',
            //     textAlign: 'center',
            //     width: 60,
            //     render: (issue, index) => (
            //         <Space>
            //             {
            //                 issue.status === 'PENDING' ?
            //                     <div>
            //                         <Popconfirm
            //                             placement="bottomRight"
            //                             title={"Confrimation"}
            //                             description={"Are you sure to solve this ticket?"}
            //                             okText="Yes"
            //                             cancelText="No"
            //                             onConfirm={() => this.resolveConfirm(issue.id, true)}
            //                         >
            //                             <Button size={'middle'} type="primary" shape="square">Solve</Button>
            //                         </Popconfirm>
            //                         <Popconfirm
            //                             placement="bottomRight"
            //                             title={"Confrimation"}
            //                             description={"Are you sure to close this ticket?"}
            //                             okText="Yes"
            //                             cancelText="No"
            //                             onConfirm={() => this.resolveConfirm(issue.id, false)}
            //                         >
            //                             <Button size={'middle'} type="primary" shape="square" style={{ backgroundColor: 'red', marginTop: 5 }} >Close</Button>
            //                         </Popconfirm>
            //                     </div>
            //                     :
            //                     <>
            //                         {
            //                             issue.status === 'COMPLETE' ?
            //                                 <Tag color="#008000">{issue.status}</Tag>
            //                                 :
            //                                 <Tag color="#ff0000">{issue.status}</Tag>
            //                         }
            //                     </>
            //             }
            //         </Space>

            //     ),
            // },
        ]

        {/* {
                            issue.status === 'PENDING' ?
                                <Button size={'middle'} type="primary" shape="square" onClick={() => this.setState({ issueId: issue.id, openModal: true })}>Solve</Button>
                                :
                                <></>
                        } */}
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.resolveConfirm()}
                    onCancel={() => this.setState({ openModal: false })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to resolve?</p>
                </Modal>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={"Order Issue"} />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            style={{ borderRadius: 10 }}
                                            pagination={{
                                                position: 'top' | 'bottom',
                                                total: totalPagination,
                                                current: page,
                                                onChange: this.handlePaginationChange,
                                                defaultPageSize: pageSize,
                                                showSizeChanger: false
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName} />
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>

        )
    }
}

export default compose(withRouter)(ShipperRequestSupport)
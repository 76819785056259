import { Breadcrumb, Button, Card, Col, Form, Image, Input, Modal, Row, Select } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { FormLabelOptional } from "../../../components/general-component";
import Theme from "../../../components/theme";
import { NRCValidator, NumberOnlyForOptionalValidator, PhoneNumberValidator, ShipperPasswordValidator } from "../../../components/validator";
import { SHIPPER_BUSINESS_TYPE, nrcCode_map, nrcNo_collection, payment_type_collection } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPut } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import { updatePendingCount } from "../../../redux/noti-slice";
import CustomPath from "../../../routes/custom-path";

const { TextArea } = Input
const { Option } = Select

class ShipperRequestApproval extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shipperAccount: {},
            roleData: [],
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            product_category_collection: [],
            nrcDivisionNumber: null,
            nrctownshipCode: null,
            nrcCitizen: null,

            townshipCode_collection: [],
            openRejectModal: false,
            is_approving: false,
            isFetchingData: false,
            disableEditing: true
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        this.fetchDivision()
        this.fetchProductCategory()
        this.fetchPendingCount()
    }

    fetchProductCategory = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.product_category_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    product_category_collection: response.content
                })
            }
        } catch (error) { }
    }
    fetchDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }
    fetchData = async (township_map) => {
        try {
            this.setState({
                isFetchingData: true
            })
            const response = await ApiGet(Api.shipper, this.props.params.id, this.props)
            if (response) {
                this.setState(prevState => ({
                    data: response,
                }));
                let shipper;
                if (response.dateOfBirth) {
                    const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
                    shipper = { ...response, dateOfBirth: dob }
                } else {
                    shipper = { ...response }
                }
                if (response.userStatus === 'ACTIVE') {
                    shipper = { ...shipper, active: true }
                } else {
                    shipper = { ...shipper, active: false }
                }
                if (shipper.accounts) {
                    shipper.accounts.map((account) => {
                        if (account.accountType === "PHONE") {
                            shipper = { ...shipper, primaryPhoneNumber: account.loginId, password: account.password }
                            this.setState({
                                shipperAccount: account
                            })
                        }
                    })
                }

                if (shipper.business) {
                    let nrcNo = ''
                    if (shipper.business.businessOwnerNRC && shipper.business.businessOwnerNRC.includes('/') && shipper.business.businessOwnerNRC.includes('(') && shipper.business.businessOwnerNRC.includes(')')) {
                        const nrc = shipper.business.businessOwnerNRC.split('/');
                        const p2 = nrc[1].split(')')
                        const p3 = nrc[1].split('(')
                        const p4 = p3[1].split(')')
                        nrcNo = p2[1]

                        let township_code_list = []
                        if (nrc[0]) {
                            township_code_list = nrcCode_map[nrc[0]]
                        }

                        this.setState({
                            nrcDivisionNumber: nrc[0],
                            nrcCitizen: p4[0],
                            nrctownshipCode: p3[0],
                            townshipCode_collection: township_code_list

                        })
                    }
                    let businessName = shipper?.business?.businessName
                    if (shipper.uniqueId && businessName.includes(shipper.uniqueId)) {
                        businessName = businessName.replace(shipper.uniqueId + " ", '')
                    }

                    if (shipper?.township && shipper?.township?.shortCode && businessName.includes(shipper?.township?.shortCode)) {
                        businessName = businessName.replace(" (" + shipper?.township?.shortCode + ")", '')
                    }

                    shipper = {
                        ...shipper,
                        businessUniqueId: shipper.uniqueId,
                        nrcNo: nrcNo,
                        businessName: businessName,
                        ecommerceRegNumber: shipper.business.ecommerceRegNumber,
                        businessOwnerNRC: shipper.business.businessOwnerNRC,
                        facebookPageLink: shipper.business.facebookPageLink,
                        accountOwnerName: shipper.business.accountOwnerName,
                        paymentType: shipper.business.paymentType,
                        productCategory: shipper.business.productCategory ? shipper.business.productCategory.id : ''
                    }
                    if (this.props.roleType && (this.props.roleType === 'ADMIN' || this.props.roleType === 'BUSINESS_ADMIN' || this.props.roleType === 'SM_BANK_ACCOUNT_CHECKER' || this.props.roleType === 'SM_COMMINITY')) {
                        shipper = {
                            ...shipper,
                            paymentNumber: shipper.business.paymentNumber
                        }
                        this.setState({
                            disableEditing: false
                        })
                    }
                }

                if (shipper.township) {
                    let township_collection = township_map[shipper.township.division.id]
                    shipper = {
                        ...shipper,
                        divisionId: shipper.township.division ? shipper.township.division.id : null,
                        townshipId: shipper.township ? shipper.township.id : null,
                        businessTownshipCode: shipper.township ? shipper.township.shortCode : null
                    }
                    this.setState({
                        township_collection: township_collection
                    })
                }


                if (shipper.roles) {
                    let roles = []
                    shipper.roles.map((role) => {
                        roles.push(role.id)
                    })
                    shipper = { ...shipper, roles }
                }
                this.setState({
                    isFetchingData: false,
                    shipper
                }, () => this.inputRef.current.setFieldsValue(shipper))

            }


        } catch (error) {

        }

    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }
    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };


    updateShipper = async (values) => {
        const { shipperAccount, data, nrcDivisionNumber, nrctownshipCode, nrcCitizen } = this.state
        this.setState({
            is_approving: true
        })
        try {
            let businessName = data.uniqueId + " " + values.businessName + " (" + values.tag + ")"
            if (data.township && data.township.shortCode)
                businessName = businessName + " (" + data.township.shortCode + ")"
            const response = await ApiPut(Api.shipper, data.id,
                {
                    "id": data.id,
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "osGuid": values.osGuid,
                    'approvalStatus': 'APPROVED',
                    'emailAddress': values.emailAddress,
                    "phoneNumber": values.primaryPhoneNumber,
                    "userStatus": "ACTIVE",
                    "address1": values.address1,
                    "note": values.note,
                    "salePersonCode": values.salePersonCode,
                    "ninjaCoreOldShipperId": values.ninjaCoreOldShipperId,
                    "accounts": [
                        {
                            "id": shipperAccount.id,
                            "loginId": values.primaryPhoneNumber,
                            "primaryPhoneNumber": values.primaryPhoneNumber,
                            "password": values.password,
                            "accountType": "PHONE"
                        }
                    ],
                    'business': {
                        'id': data && data.business && data.business.id ? data.business.id : null,
                        'businessName': businessName,
                        'ecommerceRegNumber': values.ecommerceRegNumber,
                        'businessOwnerNRC': (nrcDivisionNumber && nrctownshipCode && nrcCitizen && values.nrcNo) ? nrcDivisionNumber + "/" + nrctownshipCode + "(" + nrcCitizen + ")" + values.nrcNo : null,
                        'facebookPageLink': values.facebookPageLink,
                        'accountOwnerName': values.accountOwnerName,
                        'paymentType': values.paymentType,
                        'paymentNumber': values.paymentNumber,
                        'productCategory': {
                            'id': values.productCategory
                        }
                    }
                }, this.props
            )
            if (response.status === 200) {
                this.fetchPendingCount()
                this.props.navigate(CustomPath.shipper_approval)
            }
        } catch (error) {
        }
        this.setState({
            is_approving: false
        })
    }

    fetchPendingCount = async () => {
        try {
            const response = await ApiFilter(Api.shipper_pending_count, {}, this.props);
            if (response || response === 0) {
                this.props.dispatch(updatePendingCount(response))
            }
        } catch (error) {

        }
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    handleOnCodeChange = (value) => {
        this.setState({
            townshipCode_collection: nrcCode_map[value],
            nrctownshipCode: null,
            nrcDivisionNumber: value
        })
    }
    handleOnTownShipCodeChange = (value) => {
        this.setState({
            nrctownshipCode: value
        })
    }

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }

    handleReject = async (formData) => {
        const { data } = this.state
        try {
            const response = await ApiPut(Api.shipper, data.id,
                {
                    "id": data.id,
                    'approvalStatus': 'REJECT',
                    "rejectReason": formData.rejectReason
                }, this.props
            )
            if (response.status === 200) {
                this.fetchPendingCount()
                this.props.navigate(CustomPath.shipper_approval)
            }
        } catch (error) {

        }
    }


    render() {
        const { navigate } = this.props
        const { data, division_collection, township_collection, product_category_collection, nrcCitizen, townshipCode_collection, nrcDivisionNumber, nrctownshipCode, openRejectModal, is_approving, disableEditing, shipper } = this.state
        return (
            <>
                <Modal
                    title="Notice !"
                    open={openRejectModal}
                    onCancel={() => this.setState({ openRejectModal: false })}
                    okText="Confirm"
                    cancelText="Cancel"
                    footer={null}
                >
                    <p>
                        Are you sure want to reject this customer request to use Ninja Van Shipper System ?
                    </p>
                    <Form
                        layout="vertical"
                        onFinish={this.handleReject}>

                        <Form.Item name="rejectReason" rules={[{ required: true, message: '*(requried)' }]} >
                            <Input placeholder={"Please enter the reject reason."} style={{ background: '#f5f5f5' }} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'end', marginBottom: 0 }}>
                            <Button style={{ marginRight: 20 }} onClick={() => this.setState({ openRejectModal: false })}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Confirm</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title:
                                        <Link to={CustomPath.shipper_approval}> Shipper Approval Page</Link>,
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={data.approvalStatus && data.approvalStatus === 'REJECT' ? <span style={{ color: 'red' }}>REJECTED</span> : "Shipper Approval Form"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.shipper_approval)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                onFinish={this.updateShipper}>
                                <Row gutter={16}>
                                    {/* <Col span={12}>
                                        <Form.Item name="uniqueId" label={"Shipper unique id"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter unique id"} />
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={24}>
                                        <Form.Item name="osGuid" label={"Invoice Id"}>
                                            <Input placeholder={"Enter invoice id"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter first name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="lastName" label={"Last Name"}>
                                            <Input placeholder={"Enter last name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="primaryPhoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]}>
                                            <Input placeholder={"979xxxxxxxx"} onChange={this.handlePhoneNumberChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="emailAddress" label={"Email Address"} size={'large'}>
                                            <Input size="large" placeholder={"example@gmail.com"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="password" label={"Password"} rules={[{ required: true, message: '*(requried)' }, { validator: ShipperPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                            <Input.Password size="small" placeholder="Enter password" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select Division"}
                                                onChange={this.handleDivisionSelect}
                                                size="large"
                                                options={
                                                    division_collection && division_collection.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select Division"}
                                                onChange={this.divisionSelectorChange}
                                                size="large"
                                                options={
                                                    township_collection && township_collection.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} />
                                    <Col span={12}>
                                        <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <TextArea
                                                style={{ height: 60 }} placeholder={"Enter address"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="note" label={"Note"}>
                                            <TextArea
                                                style={{ height: 60 }} placeholder={"Enter note"} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <h4>Business Information</h4>

                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Row gutter={16}>
                                            <Col span={4}>
                                                <Form.Item name="businessUniqueId" label={"Unique Id"} size={'large'}>
                                                    <Input disabled size="large" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="businessName" label={"Business Name"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input size="large" placeholder={"Fashion House"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name="tag" label={"Type"} size={'large'}>
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder={"CITY"}
                                                        size="large"
                                                    >
                                                        {
                                                            SHIPPER_BUSINESS_TYPE.map(type => <Option value={type}>{type}</Option>)
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name="businessTownshipCode" label={"Township Code"} size={'large'}>
                                                    <Input disabled size="large" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="ecommerceRegNumber" label={"E-commerce Registration Number"} size={'large'}>
                                            <Input size="large" placeholder={"1234343"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="facebookPageLink" label={"Business Facebook Page Link"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <FormLabelOptional label={"Business Owner NRC"} />
                                            </Col>
                                            <Col span={4}>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder={"1"}
                                                    onChange={this.handleOnCodeChange}
                                                    size="large"
                                                    value={nrcDivisionNumber}
                                                    options={nrcNo_collection}
                                                />
                                            </Col>
                                            <Col span={6}>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder={"LAYANA"}
                                                    onChange={this.handleOnTownShipCodeChange}
                                                    value={nrctownshipCode}
                                                    size="large"
                                                    options={
                                                        townshipCode_collection && townshipCode_collection.map((code) => {
                                                            return {
                                                                key: code.id,
                                                                value: code.id,
                                                                label: code.value,
                                                            }
                                                        })
                                                    }
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder={"N"}
                                                    value={nrcCitizen}
                                                    onChange={(value) => this.setState({ nrcCitizen: value })}
                                                    size="large"
                                                >
                                                    <Option value={"N"}>N</Option>
                                                    <Option value={"F"}>F</Option>
                                                    <Option value={"P"}>P</Option>
                                                    <Option value={"T"}>T</Option>
                                                    <Option value={"E"}>E</Option>
                                                </Select>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item name="nrcNo" size={'large'}
                                                    rules={[
                                                        { validator: NRCValidator }
                                                    ]}>
                                                    <Input size="large" placeholder={"123456"} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col span={12}>
                                        <Form.Item name="productCategory" label={"Choose Business Category"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Choose Business Category"}
                                                size="large"
                                                options={
                                                    product_category_collection && product_category_collection.map(category => {
                                                        return {
                                                            value: category.id,
                                                            label: category.name,
                                                        }
                                                    })
                                                }
                                            >

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="accountOwnerName" label={"Account Owner Name"} size={'large'} rules={[{ required: true }]}>
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="paymentType" label={"Primary Payment"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Choose Primary Payment"}
                                                size="large"
                                                options={
                                                    payment_type_collection && payment_type_collection.map((payment) => {
                                                        return {
                                                            value: payment.key,
                                                            label: payment.value,
                                                            type: 'primaryPayment'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item name="paymentNumber" label={"Primary Account Number"} size={'large'} rules={[{ validator: NumberOnlyForOptionalValidator }]}>
                                            <Input size="large" disabled={disableEditing} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="ninjaCoreOldShipperId" label={"Ninja Van Old Shipper Id"}>
                                            <Input placeholder={"Enter ninja core old shipper id"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="salePersonCode" label={"Sale Person Code"}>
                                            <Input placeholder={"Enter sale person code"} />
                                        </Form.Item>
                                    </Col>
                                    {
                                        !disableEditing && <Col span={12}>
                                            <Form.Item name="bankBookImg" label={"Bank Account Number Photo"}>
                                                {
                                                    shipper?.business.bankBookImg ? <Image width={100} src={shipper?.business.bankBookImg} /> : <div style={{ width: 200, height: 100, backgroundColor: '#f1f1f1', borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Image</div>
                                                }
                                            </Form.Item>
                                        </Col>
                                    }
                                    {
                                        data.approvalStatus && data.approvalStatus === 'REJECT' ?
                                            <Col span={24}>
                                                <div style={{ color: 'red' }}>
                                                    Reason : {data.rejectReason}
                                                </div>
                                            </Col>
                                            :
                                            <Col span={24}>
                                                <Form.Item style={{ textAlign: 'end' }}>
                                                    <Button type="primary" style={{ backgroundColor: 'red', marginRight: 15 }} onClick={() => this.setState({ openRejectModal: true })}>Reject</Button>
                                                    <Button type="primary" htmlType="submit" loading={is_approving}>Approve</Button>
                                                </Form.Item>
                                            </Col>
                                    }
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row >
            </>
        );
    }
}


export default compose(withRouter)(ShipperRequestApproval)
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Input, Row, Select, Skeleton, Table, Tag } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import '../../css/push-notification.css';
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

class NotificationHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            userTypes: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchChannel: '',
            searchTitle: '',
            searchUserType: '',
            isDataFetching: false,

        }
    }
    componentDidMount() {
        this.fetchData()
        this.fetchUserTypes()
    }

    fetchUserTypes = async () => {
        try {
            const requestParams = {
                enumType: 'USER_TYPE'
            }
            const response = await ApiHandler({url: Api.enum_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams});
            if (response) {
                let userTemp = []
                userTemp.push({
                    value: 'ALL',
                    label: 'ALL',
                });
                response.map((value) => {
                    userTemp.push(value);
                })
                this.setState({
                    userTypes: userTemp
                });
            }
        } catch (error) {

        }
    }


    fetchData = async (pageNumber) => {
        const { page, pageSize,filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1,
            channel: filterData?.searchChannel?.trim() ? filterData?.searchChannel?.trim() : '' ,
            title: filterData?.searchTitle?.trim() ? filterData?.searchTitle?.trim() : '',
            userType: filterData?.searchUserType ? filterData?.searchUserType === 'ALL' ? null : filterData?.searchUserType : null
        }
        await ApiHandler({url: Api.notification_history,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {
            }).finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    // filterData = async () => {
    //     const { searchChannel, searchTitle, searchUserType, pageNumber, page, pageSize } = this.state
    //     this.setState({
    //         isDataFetching: true
    //     })
    //     try {
    //         var body = {
    //             size: pageSize,
    //             page: pageNumber ? pageNumber - 1 : page - 1,
    //             channel: searchChannel.trim(),
    //             title: searchTitle.trim(),
    //             userType: searchUserType && searchUserType !== '' && searchUserType !== undefined && searchUserType !== 'ALL' ? [searchUserType] : []
    //         }
    //         const response = await ApiInstance(Api.notification_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, body)
    //         console.log(response);
    //         if (response && response.content) {
    //             this.setState(prevState => ({
    //                 data: response.content,
    //                 totalPagination: response.totalElements,
    //                 page: pageNumber ? pageNumber : page
    //             }));
    //         }
    //     } catch (error) {
    //     }
    //     this.setState({
    //         isDataFetching: false
    //     })
    // }

    onChangeFilter = (key, value) => {
        console.log(key,value);
        this.setState(prev => ({
            filterData: {
                ...prev.filterData,
                [key]: value
            }
        }))
    }

    // handleSearchName = (event) => {
    //     if (event.target.name === 'channel') {
    //         this.setState({ searchChannel: event.target.value })
    //     } else if (event.target.name === 'title') {
    //         this.setState({ searchTitle: event.target.value })
    //     }
    // }

    // handleSelect = (value) => {
    //     this.setState({ searchUserType: value })
    // }
    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };


    render() {
        const { data, totalPagination, page, pageSize, userTypes, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Channel',
                dataIndex: 'channel',
                key: 'channel',
            }, {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: 'Send Date',
                key: 'createdDate',
                render: (_, { createDate }) => (
                    <>
                        {
                            createDate ?? <span>{dayjs(createDate).format('YYYY-MM-DD HH:MM A')}</span>
                        }
                    </>
                ),
            },
            {
                title: 'Sender Name',
                dataIndex: 'senderName',
                key: 'senderName'
            },
            {
                title: 'UserType',
                key: 'userTypes',
                render: (_, { userTypes }) => (
                    <>
                        {
                            userTypes && userTypes.map((item) => <Tag color="green">{item}</Tag>)
                        }
                    </>
                ),

            },
        ]
        return (
            <>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: 'Notification History',
                                }
                            ]}
                        /> */}
                        <TitleLevel3 label={"Notification History"} />
                    </Col>
                    <Col span={1} />
                </Row>

                <Row justify='center'>
                    <Col span={1}></Col>
                    <Col span={22}>
                        <Row gutter={16} style={{ marginBottom: 30 }}>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.onChangeFilter('searchChannel', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by Channel"
                                    name="channel"
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.onChangeFilter('searchTitle', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by Title"
                                    name="title"
                                />
                            </Col>
                            <Col span={6} >
                                <Select
                                    size="large"
                                    className="custom-selector-gray"
                                    defaultValue="ALL"
                                    onChange={(value) => this.onChangeFilter('searchUserType', value)}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={userTypes}
                                />
                            </Col>

                            <Col span={6} className="search">
                                <Button onClick={() => this.fetchData()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            // className="custom-table"
                                            style={{ borderRadius: 10 }}
                                            pagination={{
                                                position: 'top' | 'bottom',
                                                total: totalPagination,
                                                current: page,
                                                onChange: this.handlePaginationChange,
                                                defaultPageSize: pageSize,
                                                showSizeChanger: false
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName} />
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col span={1}></Col>
                </Row>
            </>

        )
    }
}

export default compose(withRouter)(NotificationHistory)
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, List, Row, Switch, Tag, message, Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Theme from "../../components/theme";
import '../../css/location/manage-location.css';
import Api from "../../network/api";
import { ApiFilter, ApiGet, ApiPut } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { Option } = Select

class BusGateUpdate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            division_collection: [],
            township_collection: [],
            township_collection_full_list: [],
            selected_division: 0,
            selected_township_collection: [],
            selected_township_map: {},
            available_township_collection: [],
            division_collection_busgate: [],
            township_map_busgate: {},
            township_collection_busgate: []
        };
        this.updateRef = React.createRef()
    }

    componentDidMount() {
        this.fetchEnableBusGateSeriveDivision()

    }

    fetchEnableBusGateSeriveDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_enable_bus_gate_service, null, this.props)

            if (response) {
                let divisionList = []
                let townshipList = []
                let townshipMap = {}
                response.map(division => {
                    if (division.townshipDTOList && division.townshipDTOList.length !== 0) {
                        divisionList.push(division)
                        townshipMap[division.id] = division.townshipDTOList
                    }
                })
                if (divisionList !== null && townshipMap !== null) {
                    townshipList = townshipMap[divisionList[0].id]
                }
                this.setState({
                    division_collection_busgate: divisionList,
                    township_map_busgate: townshipMap,
                })
                this.fetchAllDivisionAndTownship()
            }
        } catch (error) {
        }
    }

    fetchData = async (townshipMap, townshipOnlyMap) => {
        const { township_map_busgate } = this.state

        try {
            let response = await ApiGet(Api.bus_gate, this.props.params.id, this.props)

            if (response) {
                if (response.busGateLocationTownship) {
                    response = { ...response, 'township': response.busGateLocationTownship.id }
                    if (response.busGateLocationTownship.division) {
                        response = { ...response, 'division': response.busGateLocationTownship.division.id }

                        if (response.serviceTownshipIds) {
                            let townshipList = township_map_busgate[response.busGateLocationTownship.division.id]
                            let tmap = {}
                            townshipList.map(t => {
                                tmap[t.id] = t
                            })
                            let existSeviceIdList = []
                            response.serviceTownshipIds.map(serviceId => {
                                if (tmap[serviceId]) {
                                    existSeviceIdList.push(serviceId)
                                }
                            })
                            response = { ...response, serviceTownshipIds: existSeviceIdList }
                        }


                        this.setState({
                            available_township_collection: townshipMap[response.busGateLocationTownship.division.id],
                            township_collection_busgate: township_map_busgate[response.busGateLocationTownship.division.id]
                        })
                    }
                }
                this.updateRef.current.setFieldsValue(response);
                let township_map = {}
                let township_collection = []



                if (response.availableTownshipIds) {
                    response.availableTownshipIds.map(township => {
                        township_collection.push(townshipOnlyMap[township])
                        township_map[township] = townshipOnlyMap[township]
                    })
                }
                this.setState({
                    data: response,
                    selected_township_map: township_map,
                    selected_township_collection: township_collection
                })
            }
        } catch (error) {

        }
    }

    fetchAllDivisionAndTownship = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipList = []
                let townshipMap = {}
                let townshipOnlyMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                    if (division.townshipDTOList) {
                        division.townshipDTOList.map(township => {
                            townshipOnlyMap[township.id] = township
                        })
                    }
                })
                if (divisionList !== null && townshipMap !== null) {
                    townshipList = townshipMap[divisionList[0].id]
                }
                this.setState({
                    selected_division: divisionList && divisionList.length !== 0 ? divisionList[0].id : 0,
                    division_collection: divisionList,
                    township_collection_map: townshipMap,
                    township_collection: townshipList,
                    township_collection_full_list: townshipList
                })
                this.fetchData(townshipMap, townshipOnlyMap)
            }
        } catch (error) {
        }

    }

    //filter
    handleFilterChange = (e) => {
        const { township_collection_full_list } = this.state
        const value = e.target.value;
        const filteredList = township_collection_full_list.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({
            township_collection: filteredList
        })
    };

    fetchTownship = async (id) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[id],
            selected_division: id
        })
    }

    divisionSelectorChange = (value) => {
        const { township_collection_map, township_map_busgate } = this.state
        this.updateRef.current.setFieldsValue({ township: null })
        this.setState({
            township_collection_busgate: township_map_busgate[value],
            available_township_collection: township_collection_map[value],
        })
        this.updateRef.current.setFieldsValue({ serviceTownshipIds: [] });
    }

    handleTownshipSwitch = (value, id) => {
        let { selected_township_map, selected_township_collection, township_collection } = this.state
        let selected_township_collection_temp = []

        if (!value) {
            selected_township_collection && selected_township_collection.map(selected_township => {
                if (selected_township.id !== id) {
                    selected_township_collection_temp.push(selected_township)
                }
            })

            selected_township_collection = selected_township_collection_temp
            delete selected_township_map[id]
        } else {
            township_collection && township_collection.map(township => {
                if (id === township.id) {
                    selected_township_collection.push(township)
                    selected_township_map[id] = township
                    return
                }
            })
        }
        this.setState({
            selected_township_map: selected_township_map,
            selected_township_collection: selected_township_collection
        })


    }


    updateBusGate = async (formData) => {
        try {
            const { selected_township_collection, data } = this.state
            if (selected_township_collection && selected_township_collection.length === 0) {
                message.error("Please select at least one township")
            } else {
                let township_id_collection = []
                selected_township_collection.map(township => {
                    township_id_collection.push(township.id)
                })
                const requestData = {
                    id: data.id,
                    busGateName: formData.busGateName,
                    busGateNameMM: formData.busGateNameMM,
                    availableTownshipIds: township_id_collection,
                    busGateLocationId: formData.township,
                    serviceTownshipIds: formData.serviceTownshipIds,
                    active: formData.active
                }
                        

                const response = await ApiPut(Api.bus_gate, data.id, requestData, this.props)
                if (response && response.status === 200) {
                    this.props.navigate(CustomPath.bus_gate_page)
                }
            }
        } catch (error) { }
    }

    render() {
        const { division_collection, township_collection, selected_division, selected_township_map, selected_township_collection, available_township_collection, township_collection_busgate } = this.state

        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
                            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: 'Bus Gate'
                                        }
                                    ]}
                                />
                                <Link to={CustomPath.bus_gate_page}><Button type="primary">Back</Button></Link>
                            </Col>
                            <Col span={24}>
                                <Form
                                    ref={this.updateRef}
                                    layout="vertical"
                                    onFinish={this.updateBusGate}
                                >
                                    <Row gutter={16}>
                                        <Col span={12} className="custom-btn">
                                            <Form.Item name="busGateName" label={"Bus Gate Name (en)"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Input
                                                    style={{ background: '#f1f1f1', height: 40 }}
                                                    placeholder="Enter bus gate name"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className="custom-btn">
                                            <Form.Item name="busGateNameMM" label={"Bus Gate Name (mm)"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Input
                                                    style={{ background: '#f1f1f1', height: 40 }}
                                                    placeholder="Enter bus gate name"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="division" label={"Division"} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Select
                                                    className="custom-selector-gray"
                                                    placeholder={"Select Division"}
                                                    onChange={this.divisionSelectorChange}
                                                    size="large"
                                                >
                                                    {
                                                        division_collection && division_collection.map((division) => {
                                                            return <Option value={division.id}>{division.name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="township" label={"Township"} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Select
                                                    className="custom-selector-gray"
                                                    placeholder={"Select Township"}
                                                    onChange={this.townshipSelectorChange}
                                                    size="large"

                                                >
                                                    {
                                                        available_township_collection && available_township_collection.map((township) => {
                                                            return <Option value={township.id}>{township.name} <Tag color="green">{township.postalCode}</Tag></Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item name="serviceTownshipIds" label={"Bus Gate And Post Office Township"} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Select
                                                    mode="multiple"
                                                    className="custom-selector-gray"
                                                    placeholder={"Select Bus Gate And Post Office Township"}
                                                    onChange={this.townshipSelectorChange}
                                                    size="large"

                                                >
                                                    {
                                                        township_collection_busgate && township_collection_busgate.map((township) => {
                                                            return <Option value={township.id}>{township.name} <Tag color="green">{township.postalCode}</Tag></Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        {
                                            selected_township_collection && selected_township_collection.length != 0 ?
                                                <Col span={24} style={{ marginBottom: 20 }}>
                                                    {
                                                        selected_township_collection.map(item =>
                                                            <span>
                                                                <Tag key={item.id} style={{ fontSize: 13, padding: '7px 15px', fontWeight: '500', borderRadius: 90 }} bordered={false} closable onClose={() => this.handleTownshipSwitch(false, item.id)}>
                                                                    {item.name}
                                                                </Tag>
                                                            </span>
                                                        )
                                                    }
                                                </Col>
                                                :
                                                <></>
                                        }
                                        <Col span={24}>
                                            <Form.Item
                                                name="active"
                                                valuePropName="checked">
                                                <Checkbox>Active</Checkbox>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">Update</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>




                            <Col span={24} className="custom-btn" style={{ textAlign: 'right' }}>
                                <Input
                                    onChange={this.handleFilterChange}
                                    style={{ width: 200, background: '#f1f1f1', height: 40 }}
                                    placeholder="Enter township"
                                />
                            </Col>
                            <Col span={12}>
                                <Card
                                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                    bordered={false}
                                    title={"Division"}
                                >
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={division_collection}
                                        renderItem={(item, index) => (
                                            <div className={selected_division == item.id ? "division-box-selected" : "division-box"}>
                                                <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                                    <Col span={20} onClick={() => this.fetchTownship(item.id)} >
                                                        <span className="location-name">
                                                            {item.name}
                                                        </span>

                                                    </Col>
                                                </Row>
                                            </div>

                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card
                                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                    bordered={false}
                                    title={"Township"}
                                >
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={township_collection}
                                        renderItem={(item, index) => (
                                            <div className="division-box">
                                                <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                                    <Col span={20} >
                                                        <span className="location-name">
                                                            {item.name}
                                                        </span>
                                                    </Col>
                                                    <Col span={4} style={{ textAlign: 'right' }}>
                                                        <Switch
                                                            checkedChildren={<CheckOutlined />}
                                                            unCheckedChildren={<CloseOutlined />}
                                                            onChange={(event) => this.handleTownshipSwitch(event, item.id)}
                                                            checked={selected_township_map && selected_township_map[item.id] ? true : false}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col >
                </Row >
            </>
        )
    }

}

export default compose(withRouter)(BusGateUpdate)
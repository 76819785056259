import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Skeleton, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton } from "../../../components/core-component";
import DynamicEnumSelect from "../../../components/FetchEnum";
import { TitleLevel3 } from "../../../components/general-component";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class LoyaltyShopPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filterData: null,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            isDataFetching: false
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber) => {
        const { page, filterData, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        let params = {
            fullName: filterData?.name?.trim(),
            phoneNumber: filterData?.phoneNumber?.trim(),
            shopType: filterData?.shopType,
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1
        }
        await ApiHandler({ url: Api.loyalty_shop_filter, method: HTTP_METHOD.GET, requestParams: params })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })

    }

    changeSearchName = (event) => {
        this.setState({
            searchName: event.target.value
        })
    }

    changeSearchPhone = (event) => {
        this.setState({
            searchPhone: event.target.value
        })
    }

    handleFilterData = (key, value) => {

        this.setState(prevState => ({
            filterData: {
                ...prevState.filterData,
                [key]: value
            }
        }));

        if (key === 'shopType') {
            this.setState(prevState => ({
                filterData: {
                    ...prevState.filterData,
                    shopType: value?.value
                }
            }));
        }
    }

    search = () => {
        const { page } = this.state
        this.fetchData(page)
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    render() {
        const { data, totalPagination, page, pageSize, isDataFetching } = this.state

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };


        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Shop Name',
                key: 'fullName',
                dataIndex: 'fullName'
            },
            {
                title: 'Shop Type',
                key: 'shopType',
                dataIndex: 'shopType'
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            }, {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (admin) => (
                    <Link to={`${CustomPath.loyalty_shop_update}/${admin.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                ),
            },
        ]

        return (
            <>
                <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
                    <Col span={1} />
                    <Col span={22}>
                        <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <TitleLevel3 label={"Loyalty Shop"} />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Link to={CustomPath.loyalty_shop_create}><NJVAddButton /></Link>
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.handleFilterData('name', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by name"
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.handleFilterData('phoneNumber', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by phone"
                                />
                            </Col>
                            <Col span={6}>
                                <DynamicEnumSelect
                                    allowClear
                                    enumtype="LOYALTY_SHOP_TYPE"
                                    size="large"
                                    className="custom-selector-gray"
                                    style={{ width: '100%' }}
                                    placeholder="Select Shop Type"
                                    onChange={(_, object) => this.handleFilterData('shopType', object)} />
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => this.search()} type="primary" style={{ height: 40, width: '100%' }}>Search</Button>
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            rowKey={(record) => record.id}
                                            style={{ borderRadius: 10 }}
                                            pagination={{
                                                position: 'top' | 'bottom',
                                                total: totalPagination,
                                                current: page,
                                                onChange: this.handlePaginationChange,
                                                defaultPageSize: pageSize,
                                                showSizeChanger: false
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName}
                                        />
                                }
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(LoyaltyShopPage)
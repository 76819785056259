import { EditOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Input, Modal, Row, Select, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NJVAddButton } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import { GetListFromEnumObject } from "../../core/nvm-utils";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class NotificationChannelPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: false,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            fetchUserTypes: []
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchUserTypes()
    }

    fetchUserTypes = async () => {
        try {
            const requestParams = {
                enumType: 'USER_TYPE'
            }
            const response = await ApiHandler({url: Api.enum_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response) {

                let userTypeAll = {
                    key: 20,
                    value: 'ALL',
                    label: 'ALL',
                }
                this.setState({
                    userTypes: [userTypeAll, ...GetListFromEnumObject(response)],
                });
            }
        } catch (error) {

        }
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1,
            ...filterData,
            userType: filterData?.userType ? filterData?.userType === 'ALL' ? null : filterData?.userType : null
        }
        await ApiHandler({url: Api.notification_channel_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            .then((response) => {
                this.setState({
                    data: response?.content,
                    totalPagination: response?.totalElements,
                    page: pageNumber ? pageNumber : page,
                })
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    onChangeFilter = (key, value) => {
        this.setState(prev => ({
            filterData: {
                ...prev.filterData,
                [key]: value
            }
        }))
    }

    handlePaginationChange = (pageNumber) => {
        this.fetchData(pageNumber);
    };


    render() {
        const { data, totalPagination, page, pageSize, openModal, userTypes, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Channel',
                dataIndex: 'channel',
                key: 'channel',
            }, {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: 'UserType',
                key: 'userTypes',
                render: (_, { userTypes }) => (
                    <>
                        {
                            userTypes && userTypes.map((item) => <Tag color="green">{item}</Tag>)
                        }
                    </>
                ),

            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { active }) => (
                    <>
                        {
                            (
                                active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (notification_channel) => (
                    <Space>
                        <Link to={`${CustomPath.notification_channel_update}/${notification_channel.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteCoverage(notification_channel.id)}><DeleteOutlined /></Button> */}

                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteCoverageConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Notification Channel"} />
                    </Col>
                    <Col span={11} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.notification_channel_create}><NJVAddButton /></Link>
                    </Col>
                    <Col span={1} />
                </Row>
                <Row justify='center'>
                    <Col span={1}></Col>
                    <Col span={22}>
                        <Row gutter={16} style={{ marginBottom: 30 }}>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.onChangeFilter('channel', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by Channel"
                                    name="channel"
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(e) => this.onChangeFilter('title', e.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by Title"
                                    name="title"
                                />
                            </Col>
                            <Col span={6} >
                                <Select
                                    size="large"
                                    className="custom-selector-gray"
                                    defaultValue="ALL"
                                    onChange={(value) => this.onChangeFilter('userType', value)}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={userTypes}
                                />
                            </Col>
                            <Col span={6} className="search">
                                <Button onClick={() => this.fetchData()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                            </Col>
                        </Row>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false
                                    }}
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                    <Col span={1}></Col>
                </Row>

            </>
        );
    }
}

export default compose(withRouter)(NotificationChannelPage)
import { createSlice } from '@reduxjs/toolkit'

export const LocationSlice = createSlice({
    name: 'location',
    initialState: {
        division_collection: [],
        township_collection: [],
        treeData: []
    },
    reducers: {
        updateDivisionList: (state, action) => {
            state.division_collection = action.payload
        },
        updateTownShipList: (state, action) => {
            state.township_collection = action.payload
        },
        updateTreeData: (state, action) => {
            state.treeData = action.payload
        },
        clearLocation: (state) => {
            state.division_collection = []
            state.township_collection = []
            state.treeData = []
        }

    },
})

export const { updateDivisionList, updateTownShipList, updateTreeData,clearLocation } = LocationSlice.actions
export const divisionCollection = (state) => state.location.division_collection
export const townshipCollection = (state) => state.location.township_collection
export const treeData = (state) => state.location.treeData
export default LocationSlice.reducer
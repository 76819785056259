import { compose } from '@reduxjs/toolkit'
import { Breadcrumb, Button, Card, Col, Form, Image, Input, message, Row, Upload } from 'antd'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Theme from '../../components/theme'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'
import { PlusOutlined } from "@ant-design/icons";
import { ApiHandler } from '../../network/network-manager'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'

class BannerItemsCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            previewOpen: false,
            previewImage: null,
            fileList: []
        }
        this.formRef = React.createRef();
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {

        if (!file.thumbUrl && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.thumbUrl || file.preview,
            previewOpen: true,
        });
    };

    handleChange = (uploadObject) => {
        if (this.checkValidFileSize(uploadObject.file)) {
            this.setState({ fileList: uploadObject.fileList });
            this.formRef.current?.setFieldsValue({ 'photoUrl': uploadObject.file })
        }
    };

    checkValidFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.destroy()
            message.error('Image must smaller than 5MB!');
        }
        return isLt2M;
    };

    createItems = async (values) => {
        this.setState({
            isLoading: true
        })
        let formData = new FormData();
        formData.append('name', values.name);
        formData.append('photo', values.photoUrl);
        formData.append('link', values.link);
        formData.append('bannerId', this.props.bannerId)
        await ApiHandler({ url: Api.banner_item, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            .then(() => {
                this.props.handleClose()
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const { fileList, previewImage, previewOpen, isLoading } = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    Upload
                </div>
            </div>
        )

        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                <Col span={1} />
                    <Col span={11}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: "Banner Item Create",
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        />
                    </Col>
                    <Col span={11} style={{textAlign: 'right'}}>
                        <Button type="primary" onClick={this.props.handleClose}>Back</Button>
                    </Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', paddingInline: 20, paddingBlock: 10 }}
                            bordered={false}
                            title={"Banner Form"}
                        >
                            <Form
                                layout="vertical"
                                ref={this.formRef}
                                onFinish={this.createItems}>

                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Form.Item name="name" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="link" label={"Link"}>
                                            <Input placeholder={"Enter Link"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="photoUrl" label={"Add Banner Photo"}
                                            rules={[{ required: true, message: 'Photo is required!' }]}
                                        >
                                            <Upload
                                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChange}
                                                beforeUpload={() => false}
                                            >
                                                {fileList.length >= 1 ? null : uploadButton}
                                            </Upload>
                                            {previewImage && (
                                                <Image
                                                    wrapperStyle={{
                                                        display: 'none',
                                                    }}
                                                    preview={{
                                                        visible: previewOpen,
                                                        onVisibleChange: (visible) => this.setState({ previewOpen: visible }),
                                                        afterOpenChange: (visible) => !visible && this.setState({ previewOpen: '' }),
                                                    }}
                                                    src={previewImage}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(BannerItemsCreate);
import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Button, Card, Col, Input, Radio, Row, Select, Skeleton, Spin, Tag, message } from "antd";
import dayjs from 'dayjs';
import React from 'react';
import { Link } from "react-router-dom";
import { NJVSpacer } from "../../components/core-component";
import Api from "../../network/api";
import { ApiFilter, ApiGet, ApiPut } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";


import {
    LoadingOutlined
} from '@ant-design/icons';


const HOME_DELIVERY = 'Home Delivery'
const BUS_GATE = 'Bus Gate'
const POST_OFFICE = 'Post Office'

const PAYMENT_OPTION_ONE = 'Collect from customer'
const PAYMENT_OPTION_TWO = 'Pay by shipper'

const HOME_DELIVERY_ID = 1;
const BUS_GATE_ID = 2
const POST_OFFICE_ID = 3

const orderTypeMap = {
    '1': 'Home Delivery',
    '2': 'Bus Gate',
    '3': 'Post Office'
}

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)

}

const orderTypeList = [
    {
        id: 1,
        value: 'Home Delivery'
    },
    {
        id: 2,
        value: 'Bus Gate'
    },
    {
        id: 3,
        value: 'Post Office'
    }
]

class OrderDetailUpdate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            order_object: props.location.state?.data || {},
            data: {},
            isDataFetching: false,
            viewPhoto: false,
            isFetchingPriceScript: false,
            orderSteps: [],
            currentStep: 0,
            updateData: {},

            all_division_collection: [],
            division_collection: [],
            township_collection: [],
            home_delivery_division_collection: [],
            home_delivery_township_map: {},
            bus_gate_township_map: {},
            is_updating: false,
            isBusgateServiceAvailable: false

        }
    }

    componentDidMount() {
        this.fetchDivision()
        this.setState({
            isDataFetching: true
        })
    }

    fetchDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionListHomeDelivery = []
                let townshipMapHomeDelivery = {}
                let townshipMapBusGate = {}

                response.map(division => {
                    if (division.active) {
                        let townshipListBusGateService = []
                        division.townshipDTOList.map(township => {
                            if (township.active && township.enableBusGatePostOfficeService) {
                                townshipListBusGateService.push(township)
                            }
                        })
                        townshipMapBusGate[division.id] = townshipListBusGateService
                    }
                    if (division.active && division.enableHomeDelivery) {
                        divisionListHomeDelivery.push(division)
                        let townshipListHomeDelivery = []
                        division.townshipDTOList.map(township => {
                            if (township.active && township.enableHomeDelivery) {
                                townshipListHomeDelivery.push(township)
                            }
                        })
                        townshipMapHomeDelivery[division.id] = townshipListHomeDelivery
                    }
                })

                this.setState({
                    all_division_collection: response,
                    home_delivery_division_collection: divisionListHomeDelivery,
                    home_delivery_township_map: townshipMapHomeDelivery,
                    bus_gate_township_map: townshipMapBusGate,
                }, () => this.fetchData())
            }
        } catch (error) {
        }
    }

    getPriceScript = async (senderTownshipId, receiverTownshipId, updateData) => {
        const { data } = this.state

        this.setState({
            isFetchingPriceScript: true
        })
        try {
            const params = {
                'senderTownshipId': senderTownshipId,
                'receiverTownshipId': receiverTownshipId,
                'specificUserId': data?.shipper?.id

            }
            const response = await ApiFilter(Api.get_price, params, this.props)
            if (response || response === 0) {

                let cod = 0
                if (updateData.paymentOption === PAYMENT_OPTION_ONE) {
                    cod = Number(updateData.parcelAmount) + Number(response)
                } else if (updateData.paymentOption === PAYMENT_OPTION_TWO) {
                    cod = Number(updateData.parcelAmount)
                }

                this.setState({
                    updateData: { ...updateData, cod: cod, deliveryCharges: response }
                })
            }
        } catch (error) {
        }
        this.setState({
            isFetchingPriceScript: false
        })
    }

    fetchData = async () => {
        const { all_division_collection, home_delivery_division_collection, home_delivery_township_map, bus_gate_township_map } = this.state
        try {
            const response = await ApiGet(Api.order, this.props.params.id, this.props)
            if (response) {
                let data = response
                let divisionList = []
                let townshipList = []
                let updatableData = {
                    recipientName: data.recipientName,
                    recipientPhoneNumber: data.recipientPhoneNumber,
                    address: data.toAddress,
                    parcelAmount: data.parcelAmount,
                    deliveryCharges: data.deliveryCharges,
                    cod: data.codAmount,
                    paymentOption: data.paymentOption,
                    busGateId: data.busGateId
                }

                if (data.orderType === 'Home Delivery' || data.orderType === 'Home_Delivery') {
                    if (data.toTownship.division) {
                        divisionList = home_delivery_division_collection
                        townshipList = home_delivery_township_map[data.toTownship.division.id]
                        updatableData = {
                            ...updatableData,
                            divisionId: data.toTownship.division.id,
                            townshipId: data.toTownship.id
                        }
                    }
                    updatableData = {
                        ...updatableData,
                        orderType: HOME_DELIVERY_ID
                    }
                } else if (data.orderType === 'Bus Gate' || data.orderType === 'Bus_Gate') {
                    if (data.toTownship.division) {
                        divisionList = all_division_collection
                        townshipList = bus_gate_township_map[data.toTownship.division.id]
                        updatableData = {
                            ...updatableData,
                            divisionId: data.toTownship.division.id,
                            townshipId: data.toTownship.id
                        }
                        this.fetchBusGate(data?.fromTownship?.id, data.toTownship.id)
                    }
                    updatableData = {
                        ...updatableData,
                        orderType: BUS_GATE_ID
                    }
                } else if (data.orderType === POST_OFFICE) {
                    if (data.toTownship.division) {
                        divisionList = all_division_collection
                        townshipList = bus_gate_township_map[data.toTownship.division.id]
                        updatableData = {
                            ...updatableData,
                            divisionId: data.toTownship.division.id,
                            townshipId: data.toTownship.id
                        }
                    }
                    updatableData = {
                        ...updatableData,
                        orderType: POST_OFFICE_ID
                    }
                }

                let fromAddress = data.fromAddress
                let toAddress = data.toAddress
                if (data && data.fromTownship) {
                    fromAddress = fromAddress + "," + data.fromTownship.name
                    if (data.fromTownship.division) {
                        fromAddress = fromAddress + "," + data.fromTownship.division.name
                    }
                }
                if (data && data.toTownship) {
                    toAddress = toAddress + "," + data.toTownship.name
                    if (data.toTownship.division) {
                        toAddress = toAddress + "," + data.toTownship.division.name
                    }
                }
                data = { ...data, senderAddress: fromAddress, recipientAddress: toAddress }

                if (data.createdDate != null) {
                    const createdDate = dayjs(data.createdDate, 'YYYY-MM-DD HH:mm A');
                    if (createdDate) {
                        data = { ...data, 'createdDate': createdDate.format('YYYY-MMM-DD hh:mm A') }
                    }
                }
                this.setState({
                    data: data,
                    isDataFetching: false,
                    updateData: updatableData,
                    division_collection: divisionList,
                    township_collection: townshipList
                })
            }
        } catch (error) {
        }
    }

    onupdateFieldValue = (key, value) => {
        let { updateData, data, home_delivery_township_map, bus_gate_township_map, busGateList } = this.state
        let lastVal = value
        if (key === 'parcelAmount') {
            const numericValue = value.replace(/[^0-9]/g, '')
            if (numericValue.length === 1 && numericValue == 0) {
                lastVal = ''
            } else {
                lastVal = numericValue
            }
            if (updateData.orderType === HOME_DELIVERY_ID) {
                if (updateData.paymentOption === PAYMENT_OPTION_ONE) {
                    updateData = {
                        ...updateData,
                        cod: Number(lastVal) + Number(updateData.deliveryCharges)

                    }
                } else if (updateData.paymentOption === PAYMENT_OPTION_TWO) {
                    updateData = {
                        ...updateData,
                        cod: Number(lastVal)
                    }
                }
            } else {
                if (updateData.paymentOption === PAYMENT_OPTION_TWO) {
                    updateData = {
                        ...updateData,
                        cod: 0
                    }
                }
            }

        } else if (key === 'divisionId') {
            let townshipList = []
            if (updateData.orderType === HOME_DELIVERY_ID) {
                townshipList = home_delivery_township_map[value]
            } else if (updateData.orderType === BUS_GATE_ID || updateData.orderType === POST_OFFICE_ID) {
                townshipList = bus_gate_township_map[value]
            }
            this.setState({
                township_collection: townshipList,
            })
            updateData = {
                ...updateData,
                townshipId: null,
                busGateId: null
            }
        } else if (key === 'townshipId') {
            updateData = {
                ...updateData,
                [key]: lastVal
            }
            this.setState({
                updateData: { ...updateData, busGateId: null }
            })
            if (updateData.orderType === HOME_DELIVERY_ID) {
                this.getPriceScript(data.fromTownship.id, value, updateData)
            } else if (updateData.orderType === BUS_GATE_ID) {
                this.fetchBusGate(data?.fromTownship?.id, value)
            }
            return
        } else if (key === 'paymentOption') {
            if (value === PAYMENT_OPTION_ONE) {
                updateData = {
                    ...updateData,
                    cod: Number(updateData.parcelAmount) + Number(updateData.deliveryCharges)
                }
            } else if (value === PAYMENT_OPTION_TWO) {
                updateData = {
                    ...updateData,
                    cod: Number(updateData.parcelAmount)
                }
            }
        } else if (key === 'orderType') {
            let townshipList = []
            if (lastVal === HOME_DELIVERY_ID) {
                if (updateData.divisionId) {
                    townshipList = home_delivery_township_map[updateData.divisionId]
                }
                this.setState({
                    busGateList: []
                })
                updateData = {
                    ...updateData,
                    paymentOption: PAYMENT_OPTION_ONE,
                    cod: Number(updateData.parcelAmount),
                    deliveryCharges: 0,
                    divisionId: null,
                    townshipId: null,
                    address: null
                }
            } else if (lastVal === BUS_GATE_ID || lastVal === POST_OFFICE_ID) {
                if (updateData.divisionId) {
                    townshipList = bus_gate_township_map[updateData.divisionId]
                }
                updateData = {
                    ...updateData,
                    paymentOption: PAYMENT_OPTION_TWO,
                    cod: 0,
                    deliveryCharges: 2000,
                    divisionId: null,
                    townshipId: null,
                    address: null
                }
            }
            this.setState({
                township_collection: townshipList
            })
        } else if (key === 'busGateId') {
            let address = ''
            busGateList.map(busGate => {
                if (busGate.id === value) {
                    address = busGate.busGateName
                    return
                }
            })

            updateData = {
                ...updateData,
                address: address
            }
        }
        updateData = {
            ...updateData,
            [key]: lastVal
        }


        this.setState({
            updateData
        })
    }
    fetchBusGate = async (senderTownshipId, receiverTownshipId) => {
        try {
            this.setState({
                isFetchingBusGate: true,
                busGateList: []
            })
            const param = {
                'receiverTownshipId': receiverTownshipId,
                'senderLocationId': senderTownshipId
            }
            const response = await ApiFilter(Api.bus_gate_by_receiver_and_sender, param, this.props)
            if (response) {
                this.setState({
                    busGateList: response
                })
            }
        } catch (error) {
        }
        this.setState({
            isFetchingBusGate: false
        })
    }

    updateOrderDetail = async () => {
        const { data, updateData, isFetchingPriceScript } = this.state

        try {

            if (!updateData.townshipId || !updateData.divisionId || !updateData.address) {
                message.destroy()
                message.error("Please fill all fields")
                return
            } else if (updateData.orderType === BUS_GATE_ID && updateData.busGateId === null) {
                message.destroy()
                message.error("Please select at least one bus gate")
                return
            } else if (isFetchingPriceScript) {
                message.destroy()
                message.error("Please wait price script is fetching")
                return
            }

            this.setState({
                is_updating: true
            })
            let params = {
                id: data.id,
                recipientName: updateData.recipientName,
                recipientPhoneNumber: updateData.recipientPhoneNumber,
                toAddress: updateData.address,
                toTownship: {
                    id: updateData.townshipId
                },
                parcelAmount: updateData.parcelAmount,
                codAmount: updateData.cod ? updateData.cod : 0,
                deliveryCharges: updateData.deliveryCharges,
                paymentOption: updateData.paymentOption,
                orderType: orderTypeMap[updateData.orderType],
                busGateId: updateData.busGateId ? updateData.busGateId : null
            }

            const response = await ApiPut(Api.order, null, params)
            if (response) {
                this.props.navigate(CustomPath.order_history)
            }
        } catch (error) {
        }
        this.setState({
            is_updating: false
        })
    }

    render() {
        const { data, isDataFetching, updateData, township_collection, division_collection, isFetchingPriceScript, is_updating, order_object, isFetchingBusGate, busGateList } = this.state
        return (
            <>
                <Row>
                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={6} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: <Link to={CustomPath.order_history}>Parcel History</Link>
                                        },
                                        {
                                            title: 'Parcel Detail Update'
                                        }

                                    ]}
                                />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Link to={order_object?.route} relative="path">
                                    <Button type="primary">Back</Button>
                                </Link>
                            </Col>
                        </Row>

                        <NJVSpacer height={20} />
                        {
                            isDataFetching ?
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Skeleton active />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <div>
                                                <Row>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <Row gutter={[16, 16]}>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Tracking Number" />
                                                                    <NJVValue value={data.trackingNumber} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Sender Name" />
                                                                    <NJVValue value={`${data.senderName ? data.senderName : ''}`} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Sender Phone Number" />
                                                                    <NJVValue value={data.senderPhoneNumber} />
                                                                </Col>
                                                                {
                                                                    data.fromAddress && data.fromTownship ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label="Sender Address" />
                                                                            <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.division.name}`} />
                                                                        </Col>
                                                                        :
                                                                        <></>
                                                                }
                                                            </Row>

                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <Row gutter={[16, 16]}>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Order Type" />
                                                                    <Radio.Group name='orderType' onChange={(event) => this.onupdateFieldValue('orderType', event.target.value)} value={updateData && updateData.orderType ? updateData.orderType : 1}>
                                                                        {
                                                                            orderTypeList.map((order) => {
                                                                                return <Radio key={order.id} value={order.id}>{order.value}</Radio>
                                                                                // return <Radio disabled={order.id === HOME_DELIVERY_ID ? false : (order.id === BUS_GATE_ID && isBusgateServiceAvailable) ? false : true} value={order.id}>{order.value}</Radio>
                                                                            })
                                                                        }
                                                                    </Radio.Group>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Receiver Name" />
                                                                    <Input onChange={(event) => this.onupdateFieldValue('recipientName', event.target.value)} value={updateData.recipientName} style={{ marginTop: 3 }} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Receiver Phone Number" />
                                                                    <Input onChange={(event) => this.onupdateFieldValue('recipientPhoneNumber', event.target.value)} value={updateData.recipientPhoneNumber} style={{ marginTop: 3 }} />
                                                                </Col>
                                                                <Col span={12}>
                                                                    <NJVLabel label="Division" />
                                                                    <Select
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        value={updateData.divisionId}
                                                                        placeholder={"Select Division"}
                                                                        size="large"
                                                                        onChange={(value) => this.onupdateFieldValue('divisionId', value)}
                                                                        options={
                                                                            division_collection && division_collection.map((township) => {
                                                                                return {
                                                                                    value: township.id,
                                                                                    label: township.name,
                                                                                    type: 'township'
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col span={12}>
                                                                    <NJVLabel label="Township" />
                                                                    <Select
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        value={updateData.townshipId}
                                                                        placeholder={"Select Township"}
                                                                        size="large"
                                                                        onChange={(value) => this.onupdateFieldValue('townshipId', value)}
                                                                        options={
                                                                            township_collection && township_collection.map((township) => {
                                                                                return {
                                                                                    value: township.id,
                                                                                    label: township.name,
                                                                                    type: 'township'
                                                                                }
                                                                            })
                                                                        }
                                                                    />
                                                                </Col>

                                                                {
                                                                    updateData.orderType === 2 ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label="Bus Gate Name" />
                                                                            <Select
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                                loading={isFetchingBusGate}
                                                                                value={(updateData && updateData.busGateId) ?? updateData.busGateId}
                                                                                placeholder={"Select bus gate"}
                                                                                size="large"
                                                                                onChange={(value) => this.onupdateFieldValue('busGateId', value)}
                                                                                options={
                                                                                    busGateList && busGateList.map((busGate) => {
                                                                                        return {
                                                                                            value: busGate.id,
                                                                                            label: busGate.busGateName,
                                                                                            type: 'busGate'
                                                                                        }
                                                                                    })
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        :
                                                                        <></>
                                                                }
                                                                <Col span={24}>
                                                                    <NJVLabel label="Address" />
                                                                    <Input
                                                                        disabled={updateData.orderType === BUS_GATE_ID}
                                                                        onChange={(event) => this.onupdateFieldValue('address', event.target.value)}
                                                                        value={updateData.address} style={{ marginTop: 3 }} />
                                                                </Col>
                                                            </Row>

                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} />
                                                <Row>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <Row gutter={[16, 16]}>

                                                                <Col span={24}>
                                                                    <NJVLabel label="Pickup Time" />
                                                                    <NJVValue value={`${data.pickupDate}`} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Payment option" />
                                                                    <Radio.Group name='paymentOption' onChange={(event) => this.onupdateFieldValue('paymentOption', event.target.value)} value={updateData.orderType == HOME_DELIVERY_ID ? (updateData && updateData.paymentOption) ?? updateData.paymentOption : PAYMENT_OPTION_TWO}>
                                                                        <Radio
                                                                            value={PAYMENT_OPTION_ONE}
                                                                            disabled={updateData.orderType === HOME_DELIVERY_ID ? false : true}
                                                                        >
                                                                            {PAYMENT_OPTION_ONE}
                                                                        </Radio>
                                                                        <Radio
                                                                            value={PAYMENT_OPTION_TWO}>{PAYMENT_OPTION_TWO}
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <NJVLabel label="COD Amount" />
                                                                    <Input disabled value={`${updateData.cod ? updateData.cod : 0} MMK`} style={{ marginBottom: 15, marginTop: 3 }} />
                                                                </Col>
                                                                <Col span={8}>
                                                                    <NJVLabel label="Parcel Amount" />
                                                                    <Input onChange={(event) => this.onupdateFieldValue('parcelAmount', event.target.value)} value={updateData.parcelAmount} style={{ marginBottom: 15, marginTop: 3 }} />
                                                                </Col>
                                                                <Col span={isFetchingPriceScript ? 6 : 8}>
                                                                    <NJVLabel label="Delivery Fees" />
                                                                    <Input disabled value={updateData.deliveryCharges} style={{ marginBottom: 15, marginTop: 3 }} />
                                                                </Col>
                                                                <Col span={isFetchingPriceScript ? 2 : 0}>
                                                                    <NJVLabel label="" />
                                                                    <Spin
                                                                        indicator={
                                                                            <LoadingOutlined
                                                                                style={{
                                                                                    marginTop: 10,
                                                                                    fontSize: 24,
                                                                                }}
                                                                                spin
                                                                            />
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label="Note" />
                                                                    <NJVValue value={`${data.note ? data.note : 'No Note Here!'}`} />
                                                                </Col>
                                                                {
                                                                    data && data.updateDeliveryCharges ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label="Updated Delivery Charges" />
                                                                            <NJVValue value={`${data.updateDeliveryCharges}`} />
                                                                        </Col> :
                                                                        <></>
                                                                }
                                                                {
                                                                    data && data.updateCodAmount ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label="Updated Cod Amount" />
                                                                            <NJVValue value={`${data.updateCodAmount}`} />
                                                                        </Col> :
                                                                        <></>
                                                                }
                                                            </Row>





                                                            {/* {
                                                                orderSteps && orderSteps.length != 0 ?
                                                                    <>
                                                                        <NJVLabel label="Status" />
                                                                        <NJVSpacer height={10} />
                                                                        <Steps
                                                                            className="step-full-width-title"
                                                                            size="small"
                                                                            current={currentStep}
                                                                            direction="vertical"
                                                                            items={orderSteps}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                            {
                                                                data.comment ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label="Fail Reason" />
                                                                        <NJVValue value={data.comment} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            } */}

                                                            {/* <NJVSpacer height={30} /> */}
                                                            {/* <div style={{ display: 'flex' }}> */}
                                                            {/* <div style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="Product Image" />
                                                                    {
                                                                        data.productImageUrl ?

                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }
                                                                </div> */}
                                                            {/* <div>
                                                                    <NJVLabel label="Package Image" />
                                                                    {
                                                                        data.packageImageUrl ?
                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.packageImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }


                                                                </div> */}
                                                            {/* </div> */}
                                                            {/* <br /> */}
                                                            {/* <NJVLabel label="Bus Gate Voucher" />
                                                            {
                                                                data.busGateVoucherImagePath ?
                                                                    <Image
                                                                        style={{
                                                                            marginTop: 5,
                                                                            borderRadius: 9,
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={() => this.setState({ viewPhoto: true })}
                                                                        src={data.busGateVoucherImagePath}
                                                                        // preview={false}
                                                                        width={200}
                                                                    />
                                                                    :
                                                                    <div
                                                                        style={{
                                                                            marginTop: 5,
                                                                            borderRadius: 9,
                                                                            cursor: 'pointer',
                                                                            padding: 10,
                                                                            backgroundColor: '#f1f1f1',
                                                                            border: '1px solid #f1f1f1',
                                                                            width: 200,
                                                                            height: 200,
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                    </div>
                                                            } */}
                                                            <br />


                                                        </Card>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: 20, textAlign: 'center' }}>
                                                        <Button type="primary" onClick={() => this.updateOrderDetail()} style={{ width: 100 }} loading={is_updating}>Update</Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                        }
                    </Col>

                </Row >
            </>
        )
    }

}

export default compose(withRouter)(OrderDetailUpdate)
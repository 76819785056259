import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, message, Row, Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { TextArea } = Input

const targetFaqTypes = [{ value: "SHIPPER", label: "Shipper" }, { value: "LOYALTY", label: "Loyalty" }, { value: "DRIVER", label: "Driver" }]

class FAQForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faqTypes: [],
            loading: false
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchFaqType()
    }

    fetchFaqType = async () => {
        try {
            var requestParams = {
                'enumType': 'FAQ'
            }
            const response = await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let userTemp = []
                response.map((res) => {
                    userTemp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    faqTypes: userTemp
                })
            }
        } catch (error) {
        }
    }

    saveFAQ = async (formData) => {
        this.setState({ loading: true })
        try {
            const response = await ApiHandler({ url: Api.faq, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: formData })
            if (response.status === 200) {
                this.props.navigate(CustomPath.faq)
            }
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    render() {
        const { faqTypes, loading } = this.state
        const { navigate } = this.props
        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: <Link to={CustomPath.faq}>FAQ</Link>,
                                        },
                                        {
                                            title: 'Form',
                                        }
                                    ]}
                                />
                            </Col>
                            <Col span={24}>
                                <Card
                                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                    bordered={false}
                                    title={"FAQ Form"}
                                    extra={<Button type="primary" onClick={() => navigate(CustomPath.faq)}>Back</Button>}
                                >
                                    <Form
                                        layout="vertical"
                                        ref={this.inputRef}
                                        initialValues={{ active: true }}
                                        onFinish={this.saveFAQ}>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Form.Item name="question" label={"Question (EN)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input placeholder={"Enter question"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="answer" label={"Answer  (EN)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <TextArea
                                                        style={{ height: 80 }}
                                                        placeholder={"Enter answer"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="questionMM" label={"Question (MM)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input placeholder={"Enter question"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="answerMM" label={"Answer  (MM)"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <TextArea
                                                        style={{ height: 80 }}
                                                        placeholder={"Enter answer"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="faqType" label={"FAQ Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select
                                                        size="large"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="Select faq type"
                                                        options={faqTypes}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="faqTargetType" label={"Target Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select
                                                        size="large"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="Select target type"
                                                        options={targetFaqTypes}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="active"
                                                    valuePropName="checked">
                                                    <Checkbox>Active</Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" loading={loading}>Create</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={1} />
                </Row>

            </>
        );
    }
}

export default compose(withRouter)(FAQForm)
import { UploadOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Form, Image, Input, Row, Select, Upload, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { SvgExcelOutlined } from "../../components/custom-svg";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { ApiFilter, ApiGet, ApiPutWithFormData, downloadExcel } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};



class CoreConfigUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            CoreConfigs: [],
            fileList: [],
            previewImage: '',
            previewOpen: false,
            isUpdating: false,
            coreType: [],
            uploadFile: null,
            coreValue: null,
            is_key_required: false
        };
        this.updateRef = React.createRef()

    }

    componentDidMount() {
        this.fetchData()
        this.fetchCoreType()
    }

    fetchData = async () => {
        let { is_key_required } = this.state
        try {
            const response = await ApiGet(Api.core_config, this.props.params.id, this.props)
            if (response) {
                if (response.coreType === 'OC_SAME_DAY') {
                    is_key_required = true
                }
                this.setState({
                    data: response,
                    is_key_required: is_key_required
                })
                this.updateRef.current.setFieldsValue(response);
            }
        } catch (error) {

        }
    }

    fetchCoreType = async (pageNumber, value) => {
        try {
            var params = {
                'enumType': 'CORE'
            }
            const response = await ApiFilter(Api.enum_filter, params, this.props)
            if (response) {
                let userTemp = []
                response.map((res) => {
                    userTemp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    coreType: userTemp
                })
            }
        } catch (error) {
        }
    }


    handleChange = ({ fileList: newFileList }) => this.setState({
        fileList: newFileList
    })

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true
        })
    };

    handleCancel = () => {
        this.setState({
            previewOpen: false
        })
    }

    updateCoreConfig = async (values) => {
        const { data } = this.state
        this.setState({
            isUpdating: true
        })
        try {
            let formData = new FormData()
            formData.append('id', data.id)
            formData.append('key', values.key ? values.key : '');
            formData.append('value', values.value ? values.value : '');
            formData.append('coreType', values.coreType);
            if (values.coreType === 'OC_EXCEL_TEMPLATE' || values.coreType === 'PARTNER_APPLICATION' || values.coreType === 'DRIVER_APPLICATION' || values.coreType === 'SHIPPER_APPLICATION' ) {
                if (values.excel && values.excel.file) {
                    formData.append('file', values.excel.file)
                } else {
                    message.error("Invalid file")
                    return
                }
            }
            const response = await ApiPutWithFormData(Api.core_config, formData, this.props)
            if (response.status === 200) {
                this.props.navigate(CustomPath.core_config)
            }
        } catch (error) {
            this.setState({
                isUpdating: false
            })
        }
    }

    downloadExampleTemplate = async () => {
        this.setState({
            downloadingExcel: true
        })
        try {
            const response = await downloadExcel(Api.download_order_sample_excel)
            if (response && response.status === 200) {
                this.setState({
                    downloadingExcel: false
                })
            }
        } catch (error) {
            this.setState({
                downloadingExcel: false
            })
        }
    }

    removeFile = () => {
        let { data } = this.state
        this.setState({
            data: { ...data, value: null }
        })
    }



    render() {
        const { navigate } = this.props
        const { coreType, data, isUpdating, uploadFile, is_key_required } = this.state


        const props = {
            onRemove: (file) => {
                this.setState({ uploadFile: null })
            },
            beforeUpload: (file) => {
                this.setState({ uploadFile: file })
                return false;
            },
            uploadFile,
        };
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.core_config}>Core Config</Link>,
                                },
                                {
                                    title: 'Update',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Core Config Update"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.core_config)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.updateRef}
                                initialValues={{ active: true }}
                                onFinish={this.updateCoreConfig}>
                                <Form.Item name="coreType" label={"Core Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={true}
                                        placeholder="Select CoreConfig Type"
                                        options={coreType}
                                    />
                                </Form.Item>

                                {
                                    data.coreType && (data.coreType === 'OC_EXCEL_TEMPLATE' || data.coreType === 'PARTNER_APPLICATION' || data.coreType === 'DRIVER_APPLICATION' || data.coreType === 'SHIPPER_APPLICATION') ?
                                        <>
                                            {
                                                data.value ?
                                                    <div style={{ display: 'flex' }}>
                                                        <Button type="primary" size="middle" onClick={() => this.downloadExampleTemplate()} style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', marginRight: 20 }}><SvgExcelOutlined width={20} height={20} color={'white'} />
                                                            <span style={{ marginLeft: 5 }}> Download Excel File</span>
                                                        </Button>
                                                        <Button type="text" onClick={() => this.removeFile()}>Remove</Button>
                                                    </div>

                                                    :
                                                    <Form.Item name="excel">
                                                        <Upload {...props}>
                                                            <Button icon={<UploadOutlined />}>Select File</Button>
                                                        </Upload>
                                                    </Form.Item>

                                            }



                                        </>
                                        :
                                        <>
                                            {
                                                is_key_required ?
                                                    <Form.Item name="key" label={"Key"} rules={[{ required: is_key_required, message: '*(requried)' }]}>
                                                        <Input placeholder={"Enter key"} />
                                                    </Form.Item>
                                                    :
                                                    <></>
                                            }


                                            <Form.Item name="value" label={"Value"} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Input placeholder={"Enter value"} />
                                            </Form.Item>

                                        </>
                                }



                                {
                                    data.photoUrl ?
                                        <>
                                            <div style={{ fontSize: 16, fontWeight: '500' }}>CoreConfig Photo</div>
                                            <Image
                                                style={{
                                                    marginTop: 5,
                                                    borderRadius: 9,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => this.setState({ viewPhoto: true })}
                                                src={data.photoUrl}
                                                placeholder={true}
                                                loading={"eager"}
                                                // preview={false}
                                                width={200}
                                            />
                                        </>
                                        :
                                        <></>
                                }



                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button type="primary" htmlType="submit" loading={isUpdating}>Update</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(CoreConfigUpdate)
import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Image, Input, Row, Select, Upload, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};



class CoverageUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            coverages: [],
            fileList: [],
            previewImage: '',
            previewOpen: false,
            isUpdating: false
        };
        this.updateRef = React.createRef()

    }

    componentDidMount() {
        this.fetchData()
        this.fetchCoverageType()
    }

    fetchData = async () => {
        try {
            const response = await ApiHandler({url: Api.coverage, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id})
            if (response) {
                this.setState({
                    data: response
                })
                this.updateRef.current.setFieldsValue(response);
            }
        } catch (error) {

        }
    }
    fetchCoverageType = async () => {
        try {
            const response = await ApiHandler({url: Api.coverage_type,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON})
            if (response) {
                let coverageTemp = []
                response.map((value) => {
                    coverageTemp.push({
                        value: value,
                        label: value,
                    });
                })
                this.setState({
                    coverages: coverageTemp
                });
            }
        } catch (error) {

        }
    }

    handleChange = ({ fileList: newFileList }) => this.setState({
        fileList: newFileList
    })

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true
        })

    };

    handleCancel = () => {
        this.setState({
            previewOpen: false
        })
    }

    updateCoverage = async (values) => {
        const { data } = this.state
        this.setState({
            isUpdating: true
        })
        try {
            let file = null
            if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj)
                file = values.photoUrl.file.originFileObj
            let formData = new FormData()
            formData.append('id', data.id)
            formData.append('name', values.name);
            formData.append('coverageType', values.coverageType);
            formData.append('active', values.active);
            formData.append('file', file)
            const response = await ApiHandler({url:Api.coverage,method: HTTP_METHOD.PUT,mediaType: MEDIA_TYPE.JSON,requestData: formData})
            if (response.status === 200) {
                this.props.navigate(CustomPath.coverage)
            }
        } catch (error) {
            this.setState({
                isUpdating: false
            })
        }
    }

    render() {
        const { navigate } = this.props
        const { coverages, fileList, previewImage, previewOpen, data, isUpdating } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.coverage}>Coverage & Price</Link>,
                                },
                                {
                                    title: 'Update',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Coverage & Price Update"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.coverage)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.updateRef}
                                initialValues={{ active: true }}
                                onFinish={this.updateCoverage}>

                                <Form.Item name="name" label={"Cover Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter cover name"} />
                                </Form.Item>
                                <Form.Item name="coverageType" label={"Coverage Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Coverage Type"
                                        options={coverages}
                                    />
                                </Form.Item>

                                <Form.Item name="photoUrl" label={"Upload Cover Photo"}>
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                    >
                                        {fileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>

                                {
                                    data.photoUrl ?
                                        <>
                                            <div style={{ fontSize: 16, fontWeight: '500' }}>Coverage Photo</div>
                                            <Image
                                                style={{
                                                    marginTop: 5,
                                                    borderRadius: 9,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => this.setState({ viewPhoto: true })}
                                                src={data.photoUrl}
                                                placeholder={true}
                                                loading={"eager"}
                                                // preview={false}
                                                width={200}
                                            />
                                        </>
                                        :
                                        <></>
                                }



                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button type="primary" htmlType="submit" loading={isUpdating}>Update</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(CoverageUpdate)
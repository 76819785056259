
export const SvgPhoneFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M23,11a1,1,0,0,1-1-1,8.008,8.008,0,0,0-8-8,1,1,0,0,1,0-2A10.011,10.011,0,0,1,24,10,1,1,0,0,1,23,11Zm-3-1a6,6,0,0,0-6-6,1,1,0,1,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0Zm2.183,12.164.91-1.049a3.1,3.1,0,0,0,0-4.377c-.031-.031-2.437-1.882-2.437-1.882a3.1,3.1,0,0,0-4.281.006l-1.906,1.606A12.784,12.784,0,0,1,7.537,9.524l1.6-1.9a3.1,3.1,0,0,0,.007-4.282S7.291.939,7.26.908A3.082,3.082,0,0,0,2.934.862l-1.15,1C-5.01,9.744,9.62,24.261,17.762,24A6.155,6.155,0,0,0,22.183,22.164Z" /></svg>
}

export const SvgVideoFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M5,20H13c2.757,0,5-2.243,5-5v-6c0-2.757-2.243-5-5-5H5C2.243,4,0,6.243,0,9v6C0,17.757,2.243,20,5,20ZM24,8.313v7.319c0,.757-.42,1.437-1.097,1.775-.283,.141-.587,.211-.889,.211-.421,0-.837-.135-1.189-.399-.037-.028-.825-.81-.825-.81V7.544s.786-.789,.825-.818c.604-.454,1.399-.527,2.078-.188s1.097,1.018,1.097,1.775Z" /></svg>
}

export const SvgPaperSendFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m.172,3.708C-.216,2.646.076,1.47.917.713,1.756-.041,2.951-.211,3.965.282l18.09,8.444c.97.454,1.664,1.283,1.945,2.273H4.048L.229,3.835c-.021-.041-.04-.084-.057-.127Zm3.89,9.292L.309,20.175c-.021.04-.039.08-.054.122-.387,1.063-.092,2.237.749,2.993.521.467,1.179.708,1.841.708.409,0,.819-.092,1.201-.279l18.011-8.438c.973-.456,1.666-1.288,1.945-2.28H4.062Z" /></svg>
}

export const SvgPaperClipFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M19,4v13c0,3.86-3.14,7-7,7s-7-3.14-7-7V5C5,2.24,7.24,0,10,0s5,2.24,5,5v12c0,1.65-1.35,3-3,3s-3-1.35-3-3V6c0-.55,.45-1,1-1s1,.45,1,1v11c0,.55,.45,1,1,1s1-.45,1-1V5c0-1.65-1.35-3-3-3s-3,1.35-3,3v12c0,2.76,2.24,5,5,5s5-2.24,5-5V4c0-.55,.45-1,1-1s1,.45,1,1Z" /></svg>
}

export const SvgDoubleCheckFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m1.283,7.697c-.385-.396-.375-1.029.021-1.414.396-.385,1.03-.376,1.414.021l4.089,4.211c.307.31.729.485,1.176.486.445,0,.864-.173,1.179-.488L18.29,1.296c.388-.394,1.021-.396,1.414-.007.393.389.396,1.022.007,1.414l-9.131,9.219c-.696.696-1.624,1.078-2.604,1.078-.982-.002-1.904-.387-2.596-1.085L1.283,7.697Zm22.423-.405c-.391-.391-1.025-.389-1.414.002l-13.087,13.12c-.378.378-.884.586-1.418.586-.536,0-1.039-.212-1.423-.599L1.699,15.784c-.394-.388-1.026-.386-1.415.008-.388.393-.385,1.025.007,1.414l4.659,4.61c.755.761,1.761,1.181,2.833,1.184,1.068,0,2.081-.416,2.837-1.173l13.088-13.121c.39-.391.389-1.024-.002-1.414Z" /></svg>

}

export const SvgMsgIconFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M8.7,18H3c-1.493,0-3-1.134-3-3.666V9.294A9.418,9.418,0,0,1,8.349.023a9,9,0,0,1,9.628,9.628A9.419,9.419,0,0,1,8.7,18ZM20,9.08h-.012c0,.237,0,.474-.012.712C19.59,15.2,14.647,19.778,9.084,19.981l0,.015A8,8,0,0,0,16,24h5a3,3,0,0,0,3-3V16A8,8,0,0,0,20,9.08Z" /></svg>

}


export const SvgMsgCloudIconFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M3,22.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5,.672-1.5,1.5-1.5,1.5,.672,1.5,1.5Zm3-5.5c-1.105,0-2,.895-2,2s.895,2,2,2,2-.895,2-2-.895-2-2-2Zm9.845-1.42c.699,.279,1.422,.42,2.155,.42,3.309,0,6-2.691,6-6,0-2.733-1.823-5.069-4.416-5.772-.938-2.518-3.356-4.228-6.084-4.228-1.879,0-3.652,.819-4.88,2.223-.524-.147-1.067-.223-1.62-.223C3.691,2,1,4.691,1,8c0,3.242,2.585,5.892,5.802,5.997,1.062,1.845,3.032,3.003,5.198,3.003,1.426,0,2.767-.499,3.845-1.42Z" /></svg>
}


export const SvgArrowLeftFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M19,11H9l3.29-3.29a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0l-4.29,4.3A2,2,0,0,0,6,12H6a2,2,0,0,0,.59,1.4l4.29,4.3a1,1,0,1,0,1.41-1.42L9,13H19a1,1,0,0,0,0-2Z" /></svg>
}

export const SvgOrderOutlined = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="package"><rect width={width} height={height} fill={color}></rect><path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" d="M224,177.32122V78.67878a8,8,0,0,0-4.07791-6.9726l-88-49.5a8,8,0,0,0-7.84418,0l-88,49.5A8,8,0,0,0,32,78.67878v98.64244a8,8,0,0,0,4.07791,6.9726l88,49.5a8,8,0,0,0,7.84418,0l88-49.5A8,8,0,0,0,224,177.32122Z"></path><polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" points="177.022 152.511 177.022 100.511 80 47"></polyline><polyline fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" points="222.897 74.627 128.949 128 33.108 74.617"></polyline><line x1="128.949" x2="128.01" y1="128" y2="234.821" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>
}

export const SvgInviceOutlined = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M17,16.5v1c0,.828-.672,1.5-1.5,1.5h-7c-.828,0-1.5-.672-1.5-1.5v-1c0-.828,.672-1.5,1.5-1.5h7c.828,0,1.5,.672,1.5,1.5Zm-8.5-3.5h2c.829,0,1.5-.671,1.5-1.5s-.671-1.5-1.5-1.5h-2c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5Zm0-5h2c.829,0,1.5-.671,1.5-1.5s-.671-1.5-1.5-1.5h-2c-.829,0-1.5,.671-1.5,1.5s.671,1.5,1.5,1.5Zm13.5,.157v10.343c0,3.033-2.467,5.5-5.5,5.5H7.5c-3.033,0-5.5-2.467-5.5-5.5V5.5C2,2.467,4.467,0,7.5,0h6.343c1.469,0,2.85,.572,3.889,1.611l2.657,2.657c1.039,1.039,1.611,2.419,1.611,3.889Zm-3,10.343V9h-4c-1.105,0-2-.895-2-2V3H7.5c-1.378,0-2.5,1.122-2.5,2.5v13c0,1.378,1.122,2.5,2.5,2.5h9c1.378,0,2.5-1.122,2.5-2.5Z" /></svg>
}


export const SvgMobileFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="phone" width={width} height={height} fill={color}><path d="M21.18,2.92H10.82a2.9,2.9,0,0,0-2.9,2.9V26.18a2.9,2.9,0,0,0,2.9,2.9H21.18a2.9,2.9,0,0,0,2.9-2.9V5.82A2.9,2.9,0,0,0,21.18,2.92Zm.74,23.26a.74.74,0,0,1-.74.74H10.82a.74.74,0,0,1-.74-.74V5.82a.74.74,0,0,1,.74-.74H21.18a.74.74,0,0,1,.74.74Z"></path><path d="M17,23.92H15a1.08,1.08,0,0,0,0,2.16h2a1.08,1.08,0,0,0,0-2.16Z"></path></svg>

}


export const SvgLockFilled = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="lock-cloud" width={width} height={height} fill={color}><path d="M18.42,6.72A7,7,0,0,0,5.06,8.61a4,4,0,0,0-.38,7.66,1.13,1.13,0,0,0,.32.05,1,1,0,0,0,.32-2A2,2,0,0,1,4,12.5a2,2,0,0,1,2-2,1,1,0,0,0,1-1,5,5,0,0,1,9.73-1.61,1,1,0,0,0,.78.67,3,3,0,0,1,1,5.53,1,1,0,1,0,1,1.74A5,5,0,0,0,22,11.5,5,5,0,0,0,18.42,6.72Zm-3.42,9V14.5a3,3,0,0,0-6,0v1.18a3,3,0,0,0,1,5.82h4a3,3,0,0,0,1-5.82ZM11,14.5a1,1,0,0,1,2,0v1H11Zm3,5H10a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z"></path></svg>
}

export const SvgFacebook = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 24 24" width={width} height={height} fill={color}>
        <g>
            <path d="M24,12.073c0,5.989-4.394,10.954-10.13,11.855v-8.363h2.789l0.531-3.46H13.87V9.86c0-0.947,0.464-1.869,1.95-1.869h1.509   V5.045c0,0-1.37-0.234-2.679-0.234c-2.734,0-4.52,1.657-4.52,4.656v2.637H7.091v3.46h3.039v8.363C4.395,23.025,0,18.061,0,12.073   c0-6.627,5.373-12,12-12S24,5.445,24,12.073z" />
        </g></svg>
}

export const SvgA = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm5.095,17.188c-.114.042-.23.062-.345.062-.407,0-.79-.251-.938-.656l-.861-2.345h-5.901l-.861,2.345c-.19.519-.766.783-1.283.594-.519-.19-.784-.765-.594-1.283l3.22-8.769c.42-1.01,1.383-1.637,2.469-1.637s2.048.626,2.453,1.596l3.235,8.809c.19.519-.075,1.093-.594,1.283Zm-4.503-9.362l1.624,4.424h-4.433l1.609-4.383c.142-.34.501-.367.607-.367s.465.026.592.326Z" /></svg>
}

export const SvgB = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12.426,10.981h-3.426v-2.231c0-.551.449-1,1-1h2.269c.457,0,.884.179,1.205.504.321.325.494.756.488,1.213-.012.835-.701,1.514-1.536,1.514Zm2.037,2h-5.463v2.269c0,.551.449,1,1,1h4.23c.467,0,.904-.183,1.232-.515.328-.333.506-.773.499-1.24,0-.841-.673-1.514-1.499-1.514Zm9.499-1c0,6.617-5.384,12-12,12S-.039,18.598-.039,11.981,5.345-.019,11.961-.019s12,5.383,12,12Zm-6,2.5c0-1.561-1.026-2.886-2.439-3.336.272-.492.431-1.054.439-1.65.014-.997-.364-1.936-1.064-2.645-.699-.709-1.633-1.1-2.628-1.1h-2.269c-1.654,0-3,1.346-3,3v6.5c0,1.654,1.346,3,3,3h4.23c1.006,0,1.949-.395,2.656-1.111s1.089-1.665,1.075-2.658Z" /></svg>
}




export const SvgC = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm4.009,16.652c-.835.971-2.309,1.598-3.756,1.598h-.455c-2.783,0-5.048-2.284-5.048-5.091v-2.318c0-2.807,2.265-5.091,5.048-5.091h.455c1.485,0,2.904.71,3.797,1.9.332.442.242,1.069-.2,1.4-.442.332-1.068.241-1.399-.2-.509-.679-1.351-1.1-2.197-1.1h-.455c-1.709,0-3.048,1.357-3.048,3.091v2.318c0,1.733,1.339,3.091,3.048,3.091h.455c.861,0,1.782-.371,2.238-.902.362-.419.993-.465,1.41-.106.419.36.467.991.107,1.41Z" /></svg>
}

export const SvgD = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m16,12c0,2.343-1.906,4.25-4.25,4.25h-1.75c-.552,0-1-.449-1-1v-6.5c0-.551.448-1,1-1h1.75c2.344,0,4.25,1.907,4.25,4.25Zm8,0c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-6,0c0-3.446-2.804-6.25-6.25-6.25h-1.75c-1.654,0-3,1.346-3,3v6.5c0,1.654,1.346,3,3,3h1.75c3.446,0,6.25-2.804,6.25-6.25Z" /></svg>

}

export const SvgN = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm5,15.889c0,1.018-.678,1.845-1.688,2.057-.154.033-.308.049-.459.049-.837,0-1.599-.48-1.946-1.268l-3.649-8.661c-.008-.019-.035-.082-.157-.054-.1.021-.1.062-.1.1v8.639c0,.553-.448,1-1,1s-1-.447-1-1v-8.639c0-1.018.678-1.845,1.688-2.057,1.007-.217,1.996.289,2.406,1.219l3.649,8.661c.009.019.039.084.157.054.1-.021.1-.062.1-.1V7.25c0-.553.448-1,1-1s1,.447,1,1v8.639Z" /></svg>

}

export const SvgP = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm1.5,14h-4.5v4c0,.552-.447,1-1,1s-1-.448-1-1V9c0-2.206,1.794-4,4-4h2.5c2.481,0,4.5,2.019,4.5,4.5s-2.019,4.5-4.5,4.5Zm2.5-4.5c0,1.378-1.121,2.5-2.5,2.5h-4.5v-3c0-1.103,.897-2,2-2h2.5c1.379,0,2.5,1.122,2.5,2.5Z" /></svg>


}

export const SvgR = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m15.5,10c0,1.103-.897,2-2,2h-4v-3c0-.552.448-1,1-1h3c1.103,0,2,.897,2,2Zm8.5,2c0,6.617-5.383,12-12,12S0,18.617,0,12,5.383,0,12,0s12,5.383,12,12Zm-8.898,1.66c1.409-.619,2.398-2.024,2.398-3.66,0-2.206-1.794-4-4-4h-3c-1.654,0-3,1.346-3,3v8c0,.553.447,1,1,1s1-.447,1-1v-3h3.516l1.851,3.471c.181.337.526.529.884.529.158,0,.319-.038.47-.117.487-.261.672-.866.412-1.354l-1.53-2.869Z" /></svg>

}

export const SvgS = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm1.812,18h-3.312c-.979,0-2.618,0-3.395-1.553-.247-.494-.047-1.095.447-1.342.495-.245,1.094-.047,1.342.447.189.38.586.447,1.605.447h3.312c.655,0,1.188-.533,1.188-1.188,0-.526-.354-.996-.86-1.142l-4.834-1.424c-1.354-.384-2.306-1.644-2.306-3.062,0-1.756,1.429-3.185,3.185-3.185h3.315c.979,0,2.618,0,3.395,1.553.247.494.047,1.095-.447,1.342-.495.246-1.094.047-1.342-.447-.189-.38-.586-.447-1.605-.447h-3.315c-.653,0-1.185.531-1.185,1.185,0,.527.354.997.861,1.141l4.837,1.425c1.353.388,2.302,1.647,2.302,3.062,0,1.758-1.431,3.188-3.188,3.188Z" /></svg>
}

export const SvgL = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm4,18h-6c-1.654,0-3-1.346-3-3V7c0-.552.447-1,1-1s1,.448,1,1v8c0,.552.448,1,1,1h6c.553,0,1,.447,1,1s-.447,1-1,1Z" /></svg>
}

export const SvgH = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm5,17c0,.552-.447,1-1,1s-1-.448-1-1v-4h-6v4c0,.552-.447,1-1,1s-1-.448-1-1V7c0-.552.447-1,1-1s1,.448,1,1v4h6v-4c0-.552.447-1,1-1s1,.448,1,1v10Z" /></svg>
}


export const SvgMobilePhoneFrame = (props) => {
    let { width, height, color } = props;
    if (width === null || width === undefined)
        width = 20
    if (height === null || height === undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M15.5,24h-7c-2.481,0-4.5-2.019-4.5-4.5V4.5C4,2.019,6.019,0,8.5,0h7c2.481,0,4.5,2.019,4.5,4.5v15c0,2.481-2.019,4.5-4.5,4.5ZM8.5,1c-1.93,0-3.5,1.57-3.5,3.5v15c0,1.93,1.57,3.5,3.5,3.5h7c1.93,0,3.5-1.57,3.5-3.5V4.5c0-1.93-1.57-3.5-3.5-3.5h-7Zm5.5,19.5c0-.276-.224-.5-.5-.5h-3c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5h3c.276,0,.5-.224,.5-.5Z" /></svg>
}

export const SvgOrderIncomplete = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m23.341,9.48l-3.501-6c-.893-1.53-2.547-2.48-4.318-2.48h-7.071c-1.771,0-3.426.951-4.319,2.48L.631,9.48c-.906,1.554-.906,3.485,0,5.039l3.501,6c.893,1.53,2.547,2.48,4.318,2.48h7.071c1.771,0,3.426-.951,4.319-2.48l3.5-6c.906-1.554.906-3.485,0-5.039Zm-7.634,4.812c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293,2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.293,2.293,2.293-2.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-2.293,2.293,2.293,2.293Z" /></svg>
}



export const SvgBrushOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M23.715,2.285a1.013,1.013,0,0,0-1.43,0L17.521,7.049l-.32-.313a5.008,5.008,0,0,0-6.429-.479A16.618,16.618,0,0,1,6.224,8.685L4.15,9.293a5.036,5.036,0,0,0-3.113,2.635A4.973,4.973,0,0,0,.9,15.947a12.95,12.95,0,0,0,12.112,8.064h.924a1.011,1.011,0,0,0,.578-.182A15.288,15.288,0,0,0,21.224,13.62a5.029,5.029,0,0,0-1.453-4.374l-.8-.784,4.747-4.747A1.013,1.013,0,0,0,23.715,2.285Zm-10.107,19.7h-.6A11.3,11.3,0,0,1,8.7,21.138l.011-.006a11.546,11.546,0,0,0,4.351-3.8l.518-.761a1.01,1.01,0,0,0-1.67-1.138l-.518.761A9.535,9.535,0,0,1,7.8,19.327l-1.251.63a10.757,10.757,0,0,1-2.583-2.57,11.625,11.625,0,0,0,4.377-2.664,1.011,1.011,0,0,0-1.414-1.446,9.617,9.617,0,0,1-3.98,2.32c-.061-.135-.127-.267-.182-.406a2.906,2.906,0,0,1,.085-2.381,3.023,3.023,0,0,1,1.864-1.578l2.073-.608a15.364,15.364,0,0,0,3.426-1.588l7.915,7.712A14.192,14.192,0,0,1,13.608,21.989Zm5.62-8.683a12.421,12.421,0,0,1-.309,1.387L11.948,7.9l0,0a3.011,3.011,0,0,1,1.755-.566,2.973,2.973,0,0,1,2.084.849l2.569,2.509A3.01,3.01,0,0,1,19.228,13.306Z" /></svg>
}

export const SvgRightArrowFill = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M14,22.03c-.39,0-.78-.08-1.16-.23-1.13-.47-1.84-1.52-1.85-2.75v-2.06H3.97c-2.19,0-3.97-1.8-3.97-4.01v-1.98c0-2.21,1.78-4.01,3.97-4.01h7.03v-2.06c0-1.23,.71-2.28,1.85-2.75,1.13-.47,2.38-.22,3.24,.65l6.72,6.33,.02,.02c1.55,1.55,1.55,4.07,0,5.62l-6.77,6.37c-.56,.56-1.3,.86-2.06,.86Z" /></svg>
}

export const SvgPdfOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M20.1,5.39l-3.49-3.49c-1.23-1.23-2.86-1.9-4.6-1.9H6.5C4.02,0,2,2.02,2,4.5v15c0,2.48,2.02,4.5,4.5,4.5h11c2.48,0,4.5-2.02,4.5-4.5V9.99c0-1.74-.68-3.37-1.9-4.6Zm-.71,.71c.55,.55,.97,1.2,1.24,1.9h-5.13c-.83,0-1.5-.67-1.5-1.5V1.37c.71,.27,1.35,.69,1.9,1.24l3.49,3.49Zm1.61,13.4c0,1.93-1.57,3.5-3.5,3.5H6.5c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.93,1.57-3.5,3.5-3.5h5.51c.33,0,.66,.03,.99,.09V6.5c0,1.38,1.12,2.5,2.5,2.5h5.41c.06,.32,.09,.65,.09,.99v9.51ZM6.97,13h-.97c-.55,0-1,.45-1,1v4.5c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-1.5h.97c1.11,0,2.01-.92,2.01-2.02s-.9-1.98-2.01-1.98Zm0,3h-.97v-2h.97c.56,0,1.01,.44,1.01,.98s-.46,1.02-1.01,1.02Zm5.03-3h0s-.51,0-1,0c-.55,0-1,.45-1,1v4c0,.55,.45,1,1,1,.48,0,.97,0,1,0h0c1.11,0,2-.89,2-1.98v-2.03c0-1.09-.89-1.98-2-1.98Zm1,4.02c0,.53-.43,.96-.96,.98h-1.04v-4h1.04c.53,.02,.96,.46,.96,.98v2.03Zm6-3.52c0,.28-.22,.5-.5,.5h-2.5v2s1.5,0,1.5,0c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5h-1.5v1.5c0,.28-.22,.5-.5,.5s-.5-.22-.5-.5v-4.5c0-.55,.45-1,1-1h2.5c.28,0,.5,.22,.5,.5Z" /></svg>
}

export const SvgExcelOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M15.38,12.83l-2.72,3.17,2.72,3.17c.18,.21,.16,.52-.05,.7-.09,.08-.21,.12-.33,.12-.14,0-.28-.06-.38-.17l-2.62-3.06-2.62,3.06c-.1,.12-.24,.17-.38,.17-.12,0-.23-.04-.33-.12-.21-.18-.23-.5-.05-.7l2.72-3.17-2.72-3.17c-.18-.21-.16-.52,.05-.7,.21-.18,.52-.16,.71,.05l2.62,3.06,2.62-3.06c.18-.21,.5-.23,.71-.05,.21,.18,.23,.5,.05,.7Zm6.62-2.83v9.51c0,2.48-2.02,4.5-4.5,4.5H6.5c-2.48,0-4.5-2.02-4.5-4.5V4.51C2,2.03,4.02,.01,6.5,.01h5.51c1.74,0,3.37,.68,4.6,1.9l3.48,3.49c1.23,1.23,1.9,2.86,1.9,4.6ZM15.9,2.63c-.55-.55-1.2-.97-1.9-1.24V6.51c0,.83,.67,1.5,1.5,1.5h5.13c-.27-.71-.69-1.35-1.24-1.9l-3.48-3.49Zm5.1,7.37c0-.33-.03-.66-.09-.99h-5.41c-1.38,0-2.5-1.12-2.5-2.5V1.1c-.32-.06-.65-.09-.99-.09H6.5c-1.93,0-3.5,1.57-3.5,3.5v15c0,1.93,1.57,3.5,3.5,3.5h11c1.93,0,3.5-1.57,3.5-3.5V10Z" /></svg>
}

export const SvgCheck = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m23.637,10.55l-4.252-7.962c-.522-.979-1.536-1.587-2.646-1.587H7.282c-1.107,0-2.12.606-2.644,1.582L.365,10.549c-.488.911-.488,1.999,0,2.911l4.271,7.958c.523.976,1.536,1.582,2.644,1.582h9.457c1.11,0,2.124-.608,2.646-1.587l4.252-7.962c.485-.908.485-1.993,0-2.901Zm-11.728,4.87c-.386.387-.896.58-1.407.58s-1.025-.195-1.416-.585l-2.782-2.696,1.392-1.437,2.793,2.707,5.809-5.701,1.404,1.425-5.793,5.707Z" /></svg>
}


export const SvgCrossOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color} ><path d="M16,8a1,1,0,0,0-1.414,0L12,10.586,9.414,8A1,1,0,0,0,8,9.414L10.586,12,8,14.586A1,1,0,0,0,9.414,16L12,13.414,14.586,16A1,1,0,0,0,16,14.586L13.414,12,16,9.414A1,1,0,0,0,16,8Z" /><path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" /></svg>
}
export const SvgCopyOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M21.155,3.272,18.871.913A3.02,3.02,0,0,0,16.715,0H12A5.009,5.009,0,0,0,7.1,4H7A5.006,5.006,0,0,0,2,9V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5v-.1A5.009,5.009,0,0,0,22,14V5.36A2.988,2.988,0,0,0,21.155,3.272ZM13,22H7a3,3,0,0,1-3-3V9A3,3,0,0,1,7,6v8a5.006,5.006,0,0,0,5,5h4A3,3,0,0,1,13,22Zm4-5H12a3,3,0,0,1-3-3V5a3,3,0,0,1,3-3h4V4a2,2,0,0,0,2,2h2v8A3,3,0,0,1,17,17Z" /></svg>
}
export const SvgSosFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="m24,23h0c0,.552-.448,1-1,1H1c-.552,0-1-.448-1-1h0c0-1.657,1.343-3,3-3h18c1.657,0,3,1.343,3,3Zm-1.789-16.797l1.5-1.517c.389-.393.385-1.025-.008-1.414-.394-.389-1.026-.385-1.414.008l-1.5,1.517c-.389.393-.385,1.025.008,1.414.195.192.449.289.703.289.258,0,.516-.099.711-.297Zm-3.823-3.243l.777-1.5c.254-.49.062-1.094-.428-1.348-.488-.254-1.094-.064-1.348.428l-.777,1.5c-.254.49-.062,1.094.428,1.348.146.076.304.112.459.112.361,0,.711-.196.889-.54ZM3.203,6.211c.393-.389.396-1.021.008-1.414l-1.5-1.517c-.387-.393-1.022-.396-1.414-.008-.393.389-.396,1.021-.008,1.414l1.5,1.517c.195.198.453.297.711.297.254,0,.509-.097.703-.289Zm3.757-2.823c.49-.254.682-.857.428-1.348l-.777-1.5c-.255-.492-.86-.682-1.348-.428-.49.254-.682.857-.428,1.348l.777,1.5c.178.344.527.54.889.54.155,0,.312-.036.459-.112Zm14.04,9.612v4c0,.553-.447,1-1,1H4c-.553,0-1-.447-1-1v-4c0-4.963,4.037-9,9-9s9,4.037,9,9Zm-8-3c0-.553-.447-1-1-1-2.206,0-4,1.794-4,4,0,.553.447,1,1,1s1-.447,1-1c0-1.103.897-2,2-2,.553,0,1-.447,1-1Z" /></svg>
}
export const SvgLocationFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M8.535,14.464c-.944-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.95,1.949-1.95,5.122,.026,7.096l1.99,1.849c.428,.398,.976,.596,1.524,.596s1.1-.2,1.528-.6l2.001-1.871c.945-.944,1.465-2.2,1.465-3.535s-.52-2.591-1.465-3.536Zm8.945-4.055c.428,.397,.976,.596,1.524,.596s1.101-.2,1.529-.6l2.001-1.871c1.95-1.949,1.95-5.122,0-7.071h0c-.945-.944-2.2-1.464-3.535-1.464s-2.591,.52-3.536,1.464c-1.95,1.949-1.95,5.122,.026,7.096l1.99,1.849Zm6.52,9.59c0,2.206-1.794,4-4,4H11c-.552,0-1-.448-1-1s.448-1,1-1h9c1.103,0,2-.897,2-2s-.897-2-2-2h-5c-2.206,0-4-1.794-4-4,0-1.692,1.072-3.208,2.667-3.772,.522-.185,1.092,.089,1.276,.61,.184,.521-.089,1.092-.61,1.276-.797,.282-1.333,1.04-1.333,1.886,0,1.103,.897,2,2,2h5c2.206,0,4,1.794,4,4Z" /></svg>
}

export const SvgPlusFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z" /></svg>
}


export const SvgMinusFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width={width} height={height} fill={color}><rect x="6" y="11" width="12" height="2" rx="1" /></svg>
}

export const SvgLocateOutlined = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M12,7c-2.757,0-5,2.243-5,5s2.243,5,5,5,5-2.243,5-5-2.243-5-5-5Zm0,8c-1.654,0-3-1.346-3-3s1.346-3,3-3,3,1.346,3,3-1.346,3-3,3Zm11-4h-1.05c-.471-4.717-4.233-8.48-8.95-8.95V1c0-.553-.448-1-1-1s-1,.447-1,1v1.05C6.283,2.52,2.52,6.283,2.05,11H1c-.552,0-1,.447-1,1s.448,1,1,1h1.05c.471,4.717,4.233,8.48,8.95,8.95v1.05c0,.553,.448,1,1,1s1-.447,1-1v-1.05c4.717-.471,8.48-4.233,8.95-8.95h1.05c.552,0,1-.447,1-1s-.448-1-1-1Zm-11,9c-4.411,0-8-3.589-8-8S7.589,4,12,4s8,3.589,8,8-3.589,8-8,8Z" /></svg>
}

















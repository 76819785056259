import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import { NumberAndDecimelPointValidator, PhoneNumberValidator } from "../../components/validator";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { TextArea } = Input

class NearbyForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            nearbyTypes: [],
            isSaving: false
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        this.fetchNearbyType()
    }


    fetchNearbyType = async () => {
        try {
            const response = await ApiHandler({ url: Api.nearby_type, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let nearbyTypeTemp = []
                response.map((value) => {
                    nearbyTypeTemp.push({
                        value: value,
                        label: value,
                    });
                })
                this.setState({
                    nearbyTypes: nearbyTypeTemp
                });
            }
        } catch (error) {

        }
    }

    saveNearby = async (values) => {
        this.setState({
            isSaving: true
        })
        try {
            const response = await ApiHandler({
                url: Api.nearby, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "name": values.name,
                    "latitude": values.latitude,
                    "longitude": values.longitude,
                    "active": values.active,
                    "nearbyType": values.nearbyType,
                    "phone": values.phone,
                    "address": values.address
                }
            })
            if (response.status == 200) {
                this.props.navigate(CustomPath.nearby)
            }
            this.setState({
                isSaving: false
            })
        } catch (error) {
        }
    }

    render() {
        const { navigate } = this.props
        const { nearbyTypes, isSaving } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.nearby}>Nearby</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Nearby"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.nearby)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                initialValues={{ active: true }}
                                onFinish={this.saveNearby}>

                                <Form.Item name="name" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="phone" label={"Phone Number"} rules={[{ required: true, validator: PhoneNumberValidator }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="latitude" label={"Latitude"} rules={[{ required: true, validator: NumberAndDecimelPointValidator }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="longitude" label={"Longitude"} rules={[{ required: true, validator: NumberAndDecimelPointValidator }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="address" label={"Adress"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea />
                                </Form.Item>
                                <Form.Item name="nearbyType" label={"Nearby Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select nearby type"
                                        options={nearbyTypes}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button style={{ marginRight: '20px' }}>Reset</Button>
                                    <Button loading={isSaving} type="primary" htmlType="submit">Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(NearbyForm)
import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Row, Select, Upload, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};


class CoverageForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            authorities: [],
            coverages: [],
            userTypes: [],
            fileList: [],
            previewImage: '',
            previewOpen: false,
            isUpdating: false
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        this.fetchCoverageType()
    }


    fetchCoverageType = async () => {
        try {
            const response = await ApiHandler({url: Api.coverage_type, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON})

            if (response) {
                let coverageTemp = []
                response.map((value) => {
                    coverageTemp.push({
                        value: value,
                        label: value,
                    });
                })
                this.setState({
                    coverages: coverageTemp
                });
            }
        } catch (error) {

        }
    }


    // handleChange = (info) => {
    //     if (info.file.status === 'uploading') {
    //         this.setState({
    //             loading: true
    //         })
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         getBase64(info.file.originFileObj, (url) => {
    //             // setLoading(false);
    //             // setImageUrl(url);
    //         });
    //     }
    // }


    handleChange = ({ fileList: newFileList }) => this.setState({
        fileList: newFileList
    })

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true
        })
    };

    handleCancel = () => {
        this.setState({
            previewOpen: false
        })
    }

    saveCoverage = async (values) => {
        this.setState({
            isUpdating: true
        })
        try {
            if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj) {
                let formData = new FormData()
                formData.append('name', values.name);
                formData.append('coverageType', values.coverageType);
                formData.append('active', values.active);
                formData.append('file', values.photoUrl.file.originFileObj)
                const response = await ApiHandler({url: Api.coverage,method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.FORM_DATA,requestData: formData})
                if (response.status === 200) {
                    this.props.navigate(CustomPath.coverage)
                }
            } else {
                message.error("Invalid image")
                this.setState({
                    isUpdating: false
                })
            }

        } catch (error) {
            this.setState({
                isUpdating: false
            })
        }
    }

    render() {
        const { navigate } = this.props
        const { coverages, fileList, isUpdating } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.coverage}>Coverage & Price</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Coverage & Price Form"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.coverage)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                initialValues={{ active: true }}
                                onFinish={this.saveCoverage}>

                                <Form.Item name="name" label={"Cover Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter cover name"} />
                                </Form.Item>
                                <Form.Item name="coverageType" label={"Coverage Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Coverage Type"
                                        options={coverages}
                                    />
                                </Form.Item>

                                <Form.Item name="photoUrl" label={"Upload Cover Photo"}>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                    >
                                        {fileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button style={{ marginRight: '20px' }}>Reset</Button>
                                    <Button type="primary" htmlType="submit" loading={isUpdating}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(CoverageForm)
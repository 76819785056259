import { Breadcrumb, Button, Card, Checkbox, Col, Divider, Form, Input, Row, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { v4 as uuidv4 } from 'uuid';
import Theme from "../../../components/theme";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPut } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class RoleUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roles: [],
            menus: [],
            menusData: [],
            isLoading: false,
            is_fetching_menu: false
        };
        this.updateRef = React.createRef()

    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const response = await ApiGet(Api.role, this.props.params.id, this.props)
            if (response) {
                this.setState({
                    data: response
                })
                this.fetchMenus(response.menus)
                this.updateRef.current.setFieldsValue(response);
            }
        } catch (error) {
        }
    }


    fetchMenus = async (currentMenu) => {
        this.setState({
            is_fetching_menu: true
        })
        try {
            let map = {}
            currentMenu && currentMenu.map(menu => {
                map[menu.name] = menu
            })
            const params = {

                enumType: 'MENU'
            }
            const response = await ApiFilter(Api.enum_filter, params, this.props)
            if (response) {
                let list = []
                response.map(menu => {
                    const uid = uuidv4()
                    if (map[menu.key]) {
                        let menuObj = map[menu.key]
                        list.push({ uid: uid, label: menu.value, value: menu.key, read: menuObj.read, write_edit: menuObj.write, delete: menuObj.delete })
                    } else {
                        list.push({ uid: uid, label: menu.value, value: menu.key, read: false, write_edit: false, delete: false })
                    }

                })
                this.setState({
                    menusData: list
                });
            }
        } catch (error) {

        }
        this.setState({
            is_fetching_menu: false
        })
    }
    updateRole = async (formData) => {
        const { data, menusData } = this.state
        this.setState({
            isLoading: true
        })
        try {
            let new_menu_list = []
            menusData && menusData.map(menu => {
                if (menu.read || menu.write_edit || menu.delete) {
                    new_menu_list.push({ name: menu.value, read: menu.read, write: menu.write_edit, delete: menu.delete })
                }
            })


            const response = await ApiPut(Api.role, data.id,
                {
                    "id": data.id,
                    "roleType": formData.roles,
                    "description": formData.description,
                    "menus": new_menu_list
                }, this.props
            )

            if (response.status == 200) {
                this.props.navigate(CustomPath.role)
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }

    onChangePermission = (uid, permission, event) => {
        const { menusData } = this.state
        let newMenuData = []
        menusData.map(menu => {
            if (menu.uid === uid) {
                if (permission === 'READ') {
                    menu.read = event.target.checked
                } else if (permission === 'WRITE_EDIT') {
                    menu.write_edit = event.target.checked
                } else if (permission === 'DELETE') {
                    menu.delete = event.target.checked
                }
            }
            newMenuData.push(menu)
        })
        this.setState({
            menusData: newMenuData
        })
    }


    render() {
        const { navigate } = this.props
        const { menusData, isLoading, is_fetching_menu } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.role}>Role & Permission</Link>,
                                },
                                {
                                    title: 'Update',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Role Update"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.role)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.updateRef}
                                onFinish={this.updateRole}>
                                <Form.Item name="roleType" label={"Role"}>
                                    <Input placeholder={"Role Type"} disabled={true} />
                                </Form.Item>
                                <Form.Item name="description" label={"Description"}>
                                    <Input placeholder={"Enter description"} />
                                </Form.Item>
                                <Row style={{
                                    marginBottom: 20,
                                    marginTop: 30,
                                    borderRadius: 8,
                                    backgroundColor: 'white',
                                    padding: 12
                                }}>

                                    <Col span={12}>
                                        <span>Menu</span>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <span>Read</span>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <span>Write / Edit</span>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'center' }}>
                                        <span>Delete</span>
                                    </Col>
                                </Row>
                                {
                                    is_fetching_menu ?
                                        <Skeleton active />
                                        :
                                        <>
                                            {
                                                menusData.map((menu, index) => {
                                                    return <>
                                                        <Row style={{ marginBottom: 10, paddingLeft: 12, paddingRight: 12 }}>

                                                            <Col span={12}>
                                                                {menu.label}
                                                            </Col>
                                                            <Col span={4} style={{ textAlign: 'center' }}>
                                                                <Checkbox
                                                                    checked={menu.read}
                                                                    onChange={(e) => this.onChangePermission(menu.uid, 'READ', e)}
                                                                />
                                                            </Col>
                                                            <Col span={4} style={{ textAlign: 'center' }}>
                                                                <Checkbox
                                                                    checked={menu.write_edit}
                                                                    onChange={(e) => this.onChangePermission(menu.uid, 'WRITE_EDIT', e)}
                                                                />
                                                            </Col>
                                                            <Col span={4} style={{ textAlign: 'center' }}>
                                                                <Checkbox
                                                                    checked={menu.delete}
                                                                    onChange={(e) => this.onChangePermission(menu.uid, 'DELETE', e)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Divider style={{ margin: 10 }} />
                                                    </>
                                                })
                                            }
                                        </>
                                }


                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ marginTop: 10 }} loading={isLoading}>Update</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row >
            </>
        );
    }
}

export default compose(withRouter)(RoleUpdate)
import { Breadcrumb, Button, Card, Col, Form, Input, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class ProductCategoryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            authorities: [],
            productCategorys: [],
            userTypes: [],
            fileList: [],
            previewImage: '',
            previewOpen: false
        };
        this.inputRef = React.createRef()

    }

    saveProductCategory = async (values) => {
        try {
            const response = await ApiHandler({url: Api.product_category, method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "name": values.name,
                    "description": values.description
                }
        })
            if (response.status == 200) {
                this.props.navigate(CustomPath.product_category)
            }
        } catch (error) {
        }
    }

    render() {
        const { navigate } = this.props
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.productCategory}>Product Category</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Product Category Form"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.product_category)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                initialValues={{ active: true }}
                                onFinish={this.saveProductCategory}>

                                <Form.Item name="name" label={"Product Category Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter product category name"} />
                                </Form.Item>

                                <Form.Item name="description" label={"Description"}>
                                    <Input placeholder={"Enter description"} />
                                </Form.Item>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button style={{ marginRight: '20px' }}>Reset</Button>
                                    <Button type="primary" htmlType="submit">Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(ProductCategoryForm)
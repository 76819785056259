import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, Divider, Form, Image, Input, Modal, Row, Select, Skeleton, Upload, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import DynamicEnumSelect from "../../../components/FetchEnum";
import Theme from "../../../components/theme";
import { AdminPasswordValidator, NumberLimit, NumberOnly, PhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiFilter, ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const { TextArea } = Input
class LoyaltyShopUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: {},
            previewImage: null,
            previewTitle: null,
            roleData: [],
            roleOptions: [],
            currentDate: '',
            profileFileList: [],
            coverFileList: [],
            singleFile: {},
            isLoading: false,
            previewOpen: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            showBranchCodeInputBox: false,
            loyaltyAccount: null,
            shopTypes: []
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };

    fetchData = async () => {
        this.setState({
            isFetching: true
        })
        await ApiHandler({ url: Api.loyalty_shop, method: HTTP_METHOD.GET, specificId: this.props.params.id })
            .then(response => {

                let loyaltyAccount = null
                let showBranchCodeInputBox = false
                if (response.branchCode) {
                    showBranchCodeInputBox = true
                    response['branchCodeCheckBox'] = true
                }
                if (response?.userStatus === 'ACTIVE') {
                    response['active'] = true
                } else {
                    response['active'] = false
                }
                if (response.accounts) {
                    response['loginId'] = response.accounts[0]?.loginId
                    response['password'] = response.accounts[0]?.password
                    response['confirmPassword'] = response.accounts[0]?.password
                    loyaltyAccount = response.accounts[0]
                }
                if (response.township) {
                    response['divisionId'] = response.township.division ? response.township.division.id : null
                    response['townshipId'] = response.township ? response.township.id : null
                }

                this.setState({
                    loyaltyAccount,
                    showBranchCodeInputBox,
                    data: response,
                    isFetching: false
                }, () => {
                    this.inputRef.current.setFieldsValue({ ...response, divisionId: null, townshipId: null });
                    const currentDivision = this.inputRef.current.getFieldValue('divisionId');
                    const currentTownship = this.inputRef.current.getFieldValue('townshipId');

                    if (!currentDivision && !currentTownship) {
                        this.fetchAllDivision(response.divisionId, response.townshipId)
                    }
                })
            })
            .catch(() => { })
    }


    fetchAllDivision = async (currentDivisionId, currentTownshipId) => {
        try {
            this.setState({
                isFetchingDivision: true
            })
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                townshipList = townshipMap[currentDivisionId];
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap,
                    township_collection: townshipList,
                    isFetchingDivision: false
                }, () => {
                    this.inputRef.current.setFieldsValue({ divisionId: currentDivisionId, townshipId: currentTownshipId })
                })
            }
        } catch (error) {
        }
    }

    updateLoyaltyShop = async (values) => {

        const { loyaltyAccount, data } = this.state
        this.setState({
            isLoading: true
        })
        if (values.password !== values.confirmPassword) {
            message.error("Confirm password does not match")
            return
        }
        let formData = new FormData()
        formData.append('id', data.id);
        formData.append('fullName', values.fullName);
        formData.append('shopType', values.shopType);
        formData.append('tag', values.tag);
        formData.append('shopAbout', values.shopAbout ? values.shopAbout : '');
        formData.append("address1", values.address1)
        formData.append("township.id", values.townshipId)
        formData.append("phoneNumber", values.contactPhoneNumber ? values.contactPhoneNumber : '')
        formData.append('contactPhoneNumber', values.contactPhoneNumber ? values.contactPhoneNumber : '')
        formData.append('contactFacebook', values?.contactFacebook ? values?.contactFacebook : '')
        formData.append('contactMessenger', values?.contactMessenger ? values?.contactMessenger : '')
        formData.append('contactViber', values?.contactViber ? values?.contactViber : '')
        formData.append('contactTelegram', values?.contactTelegram ? values?.contactTelegram : '')
        formData.append('contactWebsite', values?.contactWebsite ? values?.contactWebsite : '')
        formData.append('branchCode', values.branchCode ? values.branchCode : '')
        formData.append('latitude', values?.latitude ? values?.latitude : 0)
        formData.append('longitude', values?.longitude ? values?.longitude : 0)
        formData.append('approvalStatus', 'APPROVED');
        formData.append('userStatus', values.active ? "ACTIVE" : "INACTIVE");
        formData.append('accounts[0].id', loyaltyAccount?.id);
        formData.append('accounts[0].loginId', values.loginId);
        formData.append('accounts[0].password', values.password);
        formData.append('accounts[0].accountType', "UNIQUE_ID");
        formData.append('shipperDiscount', values.shipperDiscount);
        formData.append('goldDiscount', values.goldDiscount);
        formData.append('platinumDiscount', values.platinumDiscount);

        if (values?.profileUrl?.file) {
            formData.append('shopProfile', values.profileUrl?.file)
        }
        if (data?.profileUrl === null) {
            formData.append('deleteProfileUrl', true)
        } else {
            formData.append('profileUrl', data.profileUrl)
        }
        if (values?.coverUrl?.file) {
            formData.append('shopCover', values.coverUrl?.file)
        }
        if (data?.coverPhotoUrl === null) {
            formData.append('deleteCoverUrl ', true)
        } else {
            formData.append('coverPhotoUrl', data.coverPhotoUrl)
        }
        await ApiHandler({ url: Api.loyalty_shop, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            .then(() => { this.props.navigate(CustomPath.loyalty_shop) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleChange = (type, uploadObject) => {

        if (this.checkValidFileSize(uploadObject.file)) {
            if (type === 'cover') {
                this.setState({
                    coverFileList: uploadObject.fileList
                })
            } else {
                this.setState({
                    profileFileList: uploadObject.fileList
                })
            }
        }
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    checkValidFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.destroy()
            message.error('Image must smaller than 5MB!');
        }
        return isLt2M;
    };

    render() {
        const { navigate } = this.props
        const { data, showBranchCodeInputBox, isLoading, profileFileList, coverFileList, previewOpen, previewImage, previewTitle, division_collection, township_collection, isFetching, isFetchingDivision, shopTypes } = this.state

        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        {
                            isFetching ? <Skeleton /> : (
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Breadcrumb
                                            items={[
                                                {
                                                    title: 'Home',
                                                },
                                                {
                                                    title: <Link to={CustomPath.loyalty_shop}>Loyalty Shop</Link>,
                                                }
                                            ]}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Card
                                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                            bordered={false}
                                            title={"Loyalty Shop Update Form"}
                                            extra={<Button type="primary" onClick={() => navigate(CustomPath.loyalty_shop)}>Back</Button>}
                                        >
                                            <Form
                                                ref={this.inputRef}
                                                layout="vertical"
                                                initialValues={{ active: true }}
                                                onFinish={this.updateLoyaltyShop}
                                            >
                                                <Row gutter={[16, 16]}>
                                                    <Col span={8} >
                                                        <Form.Item name="loginId" label={"Shop Code"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <Input placeholder={"Enter shop code"} disabled={true} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name="password" label={"Password"}
                                                            rules={[{ required: true, message: '*(requried)' },
                                                            { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                                            <Input.Password size="small" placeholder="Enter password" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name="confirmPassword" label={"Confirm Password"}
                                                            rules={[{ required: true, message: '*(requried)' },
                                                            { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                                            <Input.Password size="small" placeholder="Enter confirm password" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8} >
                                                        <Form.Item name="fullName" label={"Shop Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <Input placeholder={"Enter shop name"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name="shopType" label={"Shop Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <DynamicEnumSelect enumtype="LOYALTY_SHOP_TYPE" size="large" style={{ width: '100%' }} placeholder="Select Shop Type" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8} >
                                                        <Form.Item name="tag" label={"Tag"} rules={[{ required: true, message: '*(requried)' }, { validator: NumberLimit }]}>
                                                            <Input placeholder={"Enter shop tag"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>

                                                        <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <Select
                                                                className="my-location-selector"
                                                                placeholder={"Select Division"}
                                                                onChange={this.handleDivisionSelect}
                                                                loading={isFetchingDivision}
                                                                size="large"
                                                                options={
                                                                    division_collection && division_collection.map((division) => {
                                                                        return {
                                                                            value: division.id,
                                                                            label: division.name,
                                                                            type: 'division'
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <Select
                                                                className="my-location-selector"
                                                                placeholder={"Select township"}
                                                                loading={isFetchingDivision}
                                                                size="large"
                                                                options={
                                                                    township_collection && township_collection.map((township) => {
                                                                        return {
                                                                            value: township.id,
                                                                            label: township.name,
                                                                            type: 'division'
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <TextArea
                                                                maxLength={200}
                                                                showCount={true}
                                                                style={{ height: 60 }} placeholder={"Enter address"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="shopAbout" label={"About"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <TextArea
                                                                maxLength={200}
                                                                showCount={true}
                                                                style={{ height: 60 }} placeholder={"Enter About"} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={6} >
                                                        <Form.Item name="latitude" label={"Latitude"} >
                                                            <Input placeholder={"Enter latitude"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6} >
                                                        <Form.Item name="longitude" label={"Longitude"} >
                                                            <Input placeholder={"Enter longitude"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} />
                                                    <Col span={8} >
                                                        <Form.Item name="shipperDiscount" label={"Shipper Percentage"} rules={
                                                            [
                                                                { required: true, message: '*(requried)' },
                                                                {
                                                                    validator: NumberOnly
                                                                }
                                                            ]}>
                                                            <Input placeholder={"Enter Shipper Percentage"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8} >
                                                        <Form.Item name="goldDiscount" label={"Gold Percentage"} rules={
                                                            [
                                                                { required: true, message: '*(requried)' },
                                                                {
                                                                    validator: NumberOnly
                                                                }
                                                            ]}>
                                                            <Input placeholder={"Enter Gold Percentage"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8} >
                                                        <Form.Item name="platinumDiscount" label={"Platinum Percentage"} rules={
                                                            [
                                                                { required: true, message: '*(requried)' },
                                                                {
                                                                    validator: NumberOnly
                                                                }
                                                            ]}>
                                                            <Input placeholder={"Enter Platinum Percentage"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="branchCodeCheckBox"
                                                            valuePropName="checked">
                                                            <Checkbox onChange={(event) => this.setState({ showBranchCodeInputBox: event.target.checked })}>Branch Code</Checkbox>
                                                        </Form.Item>
                                                    </Col>
                                                    {
                                                        showBranchCodeInputBox ?
                                                            <Col span={12} >
                                                                <Form.Item name="branchCode" label={"Branch Code"} >
                                                                    <Input placeholder={"Enter branch code"} />
                                                                </Form.Item>
                                                            </Col>
                                                            :
                                                            <></>
                                                    }

                                                    <Col span={24}>
                                                        <Divider orientation="left" variant="dashed">
                                                            Contact Info
                                                        </Divider>
                                                    </Col>

                                                    <Col span={12} >
                                                        <Form.Item name="contactPhoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]}>
                                                            <Input placeholder={"Enter phone number"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} >
                                                        <Form.Item name="contactFacebook" label={"Facebook Page Link"} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <Input placeholder={"Enter facebook page link"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} >
                                                        <Form.Item name="contactMessenger" label={"Messenger"} >
                                                            <Input placeholder={"Enter messenger"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} >
                                                        <Form.Item name="contactViber" label={"Viber"} >
                                                            <Input placeholder={"Enter viber"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} >
                                                        <Form.Item name="contactTelegram" label={"Telegram"} >
                                                            <Input placeholder={"Enter telegram"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} >
                                                        <Form.Item name="contactWebsite" label={"Website"} >
                                                            <Input placeholder={"Enter website"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="active"
                                                            valuePropName="checked">
                                                            <Checkbox>Active</Checkbox>
                                                        </Form.Item>
                                                    </Col>
                                                    {
                                                        data?.profileUrl ?
                                                            <Col span={4}>
                                                                <Form.Item name="profileUrl" label={"Upload Profile"}>
                                                                    <Image style={{ borderRadius: 10 }} src={data?.profileUrl} />
                                                                </Form.Item>
                                                                <Button onClick={() => this.setState(prevState => ({
                                                                    data: {
                                                                        ...prevState.data,
                                                                        profileUrl: null
                                                                    }
                                                                }))}>Remove</Button>
                                                            </Col>
                                                            : <Col span={3}>
                                                                <Form.Item name="profileUrl" label={"Upload Profile"}>
                                                                    <Upload
                                                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                                                        listType="picture-card"
                                                                        fileList={profileFileList}
                                                                        multiple={false}
                                                                        maxCount={1}
                                                                        action={null}
                                                                        onPreview={this.handlePreview}
                                                                        onChange={(obj) => this.handleChange('profile', obj)}
                                                                        beforeUpload={() => false}
                                                                    >
                                                                        {profileFileList.length >= 1 ? null :
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 8,
                                                                                    }}
                                                                                >
                                                                                    Upload
                                                                                </div>
                                                                            </div>}
                                                                    </Upload>
                                                                </Form.Item>
                                                            </Col>
                                                    }

                                                    {
                                                        data?.coverPhotoUrl ?
                                                            <Col span={4}>
                                                                <Form.Item name="coverUrl" label={"Upload Cover"}>
                                                                    <Image
                                                                        style={{ borderRadius: 10 }}
                                                                        src={data?.coverPhotoUrl} />
                                                                </Form.Item>
                                                                <Button onClick={() => this.setState(prevState => ({
                                                                    data: {
                                                                        ...prevState.data,
                                                                        coverPhotoUrl: null
                                                                    }
                                                                }))}>Remove</Button>
                                                            </Col>
                                                            :
                                                            <Col span={3}>
                                                                <Form.Item name="coverUrl" label={"Upload Cover"}>
                                                                    <Upload
                                                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                                                        listType="picture-card"
                                                                        fileList={coverFileList}
                                                                        multiple={false}
                                                                        maxCount={1}
                                                                        action={null}
                                                                        onPreview={this.handlePreview}
                                                                        onChange={(obj) => this.handleChange('cover', obj)}
                                                                        beforeUpload={() => false}
                                                                    >
                                                                        {coverFileList.length >= 1 ? null :
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 8,
                                                                                    }}
                                                                                >
                                                                                    Upload
                                                                                </div>
                                                                            </div>}
                                                                    </Upload>
                                                                </Form.Item>
                                                            </Col>

                                                    }

                                                    <Col span={24} style={{ marginTop: 20 }}>
                                                        <Form.Item>
                                                            <Button type="primary" htmlType="submit" loading={isLoading}>Update</Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                    </Col >
                    <Col span={1} />
                </Row >
            </>
        );
    }
}

export default compose(withRouter)(LoyaltyShopUpdate)
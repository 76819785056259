import { HolderOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Col, Table, Tag, Row, Select, Skeleton } from 'antd';
import React, { useContext, useMemo, useEffect } from 'react';
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import { TitleLevel3 } from '../../components/general-component';
import { NJVButton } from '../../components/core-component';


const RowContext = React.createContext({});
const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{
                cursor: 'move',
            }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};
const columns = [
    {
        key: 'sort',
        align: 'center',
        width: 80,
        render: () => <DragHandle />,
    },
    {
        title: 'Question',
        dataIndex: 'question',
    },
    {
        title: 'Status',
        key: 'active',
        render: (_, { active }) => (
            <>
                {
                    (
                        active ? <Tag color="#87d068">Active</Tag> : <Tag color="#cd201f">Inactive</Tag>
                    )
                }
            </>
        ),

    },

];
const RowContent = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };
    const contextValue = useMemo(
        () => ({
            setActivatorNodeRef,
            listeners,
        }),
        [setActivatorNodeRef, listeners],
    );
    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};
const FaqDragAndDropSorting = () => {
    const [faqData, setFaqData] = React.useState([])
    const [faqType, setFaqType] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [updating, setUpdating] = React.useState(false)
    const [selectedFaq, setSelectedFaq] = React.useState(false)

    useEffect(() => {
        fetchFaqType()
    }, [])

    const fetchFaqType = async () => {
        const params = {
            'enumType': 'FAQ'
        }
        await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.GET, requestParams: params })
            .then(response => {
                let faqTemp = []
                response.map((res) => {
                    faqTemp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                setFaqType(faqTemp)
                fetchData(faqTemp?.[0].value)
            })
            .catch(() => { })
    }

    const fetchData = async (faqType) => {
        setSelectedFaq(faqType)
        setLoading(true)
        let params = {
            size: 100,
            page: 0,
            faqType: faqType
        }
        await ApiHandler({ url: Api.faq_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.GET, requestParams: params })
            .then(response => {
                let faqTemp = []
                response?.content?.map(faq => {
                    faqTemp.push({
                        ...faq,
                        key: faq.id
                    })
                })
                setFaqData(faqTemp)
            })
            .catch(error => { })
            .finally(() => setLoading(false))
    }

    const onFaqChange = async (value) => {
        fetchData(value)
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setFaqData((prevState) => {
                const activeIndex = prevState.findIndex((record) => record.key === active?.id);
                const overIndex = prevState.findIndex((record) => record.key === over?.id);
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
    };

    const updateSorting = async () => {
        setUpdating(true)
        let sortedList = []
        faqData.map((faq, index) => sortedList.push({ id: faq.id, index: index + 1 }))
        await ApiHandler({ url: Api.faq_update_sorting, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.GET, requestData: sortedList })
            .then(response => {
                console.log(response)
            })
            .catch(error => { })
            .finally(() => setUpdating(false))
    }
    return (
        <Row>
            <Col span={1} />
            <Col span={22}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <TitleLevel3 label={"Faq Sorting"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button type='primary' loading={updating} onClick={updateSorting}>Update Sorting</Button>
                    </Col>
                    <Col span={8}>
                        <Select
                            className="custom-selector-gray"
                            placeholder={"Select faq type"}
                            style={{ width: '100%' }}
                            value={selectedFaq}
                            size='large'
                            onChange={onFaqChange}
                            options={faqType}
                        />


                    </Col>
                    <Col span={24}>
                        {
                            loading ?
                                <Skeleton active />
                                :
                                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                                    <SortableContext items={faqData.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                                        <Table
                                            rowKey="key"
                                            components={{
                                                body: {
                                                    row: RowContent,
                                                },
                                            }}
                                            columns={columns}
                                            dataSource={faqData}
                                            pagination={false}
                                            style={{ width: '100%' }}
                                        />
                                    </SortableContext>
                                </DndContext>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>


    );
};
export default FaqDragAndDropSorting;
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Skeleton, Space, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { MainPageTitle } from "../../../components/general-component";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiDelete, ApiFilter } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class UserGroupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            searchValue: [],
            fetchUserTypes: [],
            deleteItem: null,
            nearbyTypes: [],
            filter_name: '',
            filter_type: 'ALL',
            isDataFetching: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber, value) => {
        const { page, filter_name, filter_type, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const response = await ApiFilter(Api.user_group_filter, null, this.props)
            if (response) {
                this.setState(prevState => ({
                    data: response
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }


    handleSearchName = (event) => {
        if (event.target.name === 'name') {
            this.setState({ filter_name: event.target.value })
        }
    }

    handleSelect = (value) => {
        this.setState({ filter_type: value })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    deleteNearby = (id) => {
        this.setState({
            deleteItem: id,
            openModal: true
        })
    }
    deleteNearbyConfirm = async () => {
        const { deleteItem } = this.state
        try {
            await ApiDelete(Api.nearby, deleteItem, this.props)
        } catch (error) {

        }
        this.setState({
            openModal: false,
            deleteItem: ''
        });
        this.fetchData()
    }


    render() {
        const { data, totalPagination, page, pageSize, openModal, nearbyTypes, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (nearby) => (
                    <Space>
                        <Link to={`${CustomPath.user_group_update}/${nearby.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteNearbyConfirm()}
                    onCancel={() => this.hideModal()}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <MainPageTitle title="User Group" route={CustomPath.user_group_create} />
                        <Row>
                            <Col span={24}>
                                {/* <Row gutter={16} style={{ marginBottom: 30 }}>
                                    <Col span={6}>
                                        <Input
                                            onChange={this.handleSearchName}
                                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                            placeholder="Search by name"
                                            name="name"
                                        />
                                    </Col>
                                    <Col span={3} className="search">
                                        <Button onClick={() => this.fetchData()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col span={24}>
                                        {
                                            isDataFetching ?
                                                <Skeleton active />
                                                :
                                                <Table
                                                    // className="custom-table"
                                                    style={{ borderRadius: 10 }}
                                                    pagination={false}
                                                    columns={columns}
                                                    dataSource={data}
                                                    rowClassName={getRowClassName}
                                                />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </>
        );
    }
}

export default compose(withRouter)(UserGroupPage)
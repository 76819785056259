import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Table } from 'antd';
import { useSelector } from 'react-redux';
import { menuMap } from '../redux/menu-slice';

// export const NJVHeader = (props) => {
//     const { title } = props;
//     return (
//         <span className="core-header">{title}</span>
//     )
// }

export const NJVSpacer = ({ height, width }) => {
    return (
        <div style={{ height: height, width: width, display: 'inline-block' }}></div>
    )
}

export const NJVAddButton = ({ handleClick }) => {
    return <Button
        type="primary"
        icon={<PlusCircleFilled />}
        shape="round"
        onClick={handleClick}
    >
        Create
    </Button>
}

export const NJVSearchButton = ({ onClick }) => {
    return <Button
        type="primary"
        icon={<SearchOutlined />}
        shape="round"
        onClick={onClick}
    >
        Search
    </Button>
}

export const NJVButton = ({ ...buttonProps }) => {
    const menuAccessMap = useSelector(menuMap)
    const access = menuAccessMap[buttonProps.menuName]
    if (access && access.write_edit) {
        return (
            <ConfigProvider theme={{
                token: {
                    // colorPrimary: '#bf002e',
                    borderRadius: '7px',
                    borderRadiusLG: '9px'
                }
            }}>
                <Button
                    {...buttonProps}
                >
                    {buttonProps.text}
                </Button>
            </ConfigProvider>
        )
    }
}

export const NJVUpdateButton = (props) => {
    return <Button
        {...props}
    >{props.name}</Button>
}


export const NJVTable = ({ ...tableProps }) => {
    return (
        <ConfigProvider theme={{
            token: {
                colorBgContainer: '#f8f8f8',
            },
            components: {
                Table: {
                    headerBg : 'red'
                }
            }
        }}>
            <Table {...tableProps} />
        </ConfigProvider>
    )
}



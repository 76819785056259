import { compose } from '@reduxjs/toolkit';
import { Button, Card, Checkbox, Col, Form, Image, Input, Row, message } from 'antd';
import React from "react";
import { SvgLockFilled, SvgMobileFilled } from "../../components/custom-svg";
import '../../css/login.css';
import withRouter from '../../network/with-router';
import CustomPath from '../../routes/custom-path';
import { Authenticate } from "../auth/auth-provider";
const logo = require('../../asset/Logo.png');
class LoginPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            phoneNumber: '',
            visiblePassword: false,
            isLoggin: false
        }
        this.formRef = React.createRef()
    }
    login = async (values) => {
        this.setState({ isLoggin: true })
        message.destroy()
        if ((values.phoneNumber === undefined || values.phoneNumber === '') && (values.password === undefined || values.password === '')) {
            message.error("Please Enter Phone number & Password")
        } else if (values.phoneNumber === undefined || values.phoneNumber === '') {
            message.error("Please Enter Phone number")
        } else if (values.password === undefined || values.password === '') {
            message.error("Please Enter Password")
        } else if (values.phoneNumber.length > 12 || values.phoneNumber.length < 1) {
            message.error("Invalid Phone Number")
        } else {
            const response = await Authenticate(values.phoneNumber, values.password, this.props)
            if (response) { 
                this.props.navigate(CustomPath.dashboard)
            }
        }
        this.setState({ isLoggin: false })

    }

    handleInputChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.formRef.current.setFieldsValue({ phoneNumber: numericValue })
    };

    showPassword = value => {
        this.setState({ visiblePassword: value.target.checked })
    }

    render() {
        const { visiblePassword, isLoggin } = this.state
        return (
            <>
                <Row style={{ height: '100vh', display: 'flex', alignContent: 'center' }}>
                    <Col span={9} />
                    <Col span={6}>
                        <Card
                            style={{ boxShadow: 'none' }}
                            bordered={false}
                        >
                            <Row>
                                <Col span={24} style={{ marginBottom: 50 }}>
                                    <Image
                                        width={200}
                                        src={logo}
                                        preview={false}
                                    />
                                </Col>
                            </Row>
                            <Form
                                name="normal_login"
                                className="login-form"
                                onFinish={this.login}
                                ref={this.formRef}
                            >
                                <Form.Item
                                    style={{ marginBottom: 30 }}
                                    name="phoneNumber"
                                >
                                    <Input
                                        className="login-box"
                                        style={{ backgroundColor: '#f5f5f5', border: 'none', borderRadius: 12 }}
                                        prefix={<SvgMobileFilled width={20} height={20} color={"#898989"} />}
                                        placeholder="Phone Number"
                                        autoComplete={false}
                                        onChange={this.handleInputChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    name="password"
                                >
                                    <Input
                                        className="login-box"
                                        style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }}
                                        prefix={<SvgLockFilled width={20} height={20} color={"#898989"} />}
                                        type={visiblePassword ? "text" : "password"}
                                        placeholder="Password"
                                        autoComplete={false}
                                    />
                                </Form.Item>
                                <Checkbox
                                    style={{ marginBottom: 30 }}
                                    onChange={this.showPassword}>
                                    Show Password
                                </Checkbox>
                                <Row>
                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Form.Item>
                                            <Button style={{ width: 150 }} type="primary" size="large" htmlType="submit" className="login-form-button" loading={isLoggin}>
                                                Log in
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(LoginPage)
import { compose } from "@reduxjs/toolkit";
import { Button, Col, Image, Modal, Result, Row, Skeleton } from "antd";
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiFilter, ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

const empty = require('../../asset/empty-cart.png');

class RedisCachePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            cacheName: null,
            isDataFetching: false,
            isClearingCache: false
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    clearCache = async () => {
        const { cacheName } = this.state
        this.setState({
            isClearingCache: true
        })
        try {
            const response = await ApiFilter(Api.redis_cache, { 'cacheName': cacheName })
            if (response) {
                this.fetchData()
            }
        } catch (error) {
        }
        this.setState({
            isClearingCache: false,
            openModal: false,
            cacheName: null
        })
    }

    fetchData = async () => {
        this.setState({
            isDataFetching: true
        })
        try {
            const response = await ApiHandler({url: Api.redis_cache_filter,method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON})
            if (response) {
                this.setState({
                    data: response
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    render() {
        const { data, isDataFetching, isClearingCache, openModal,cacheName } = this.state
        return (
            <>
                <Modal
                    title="Clear Cache"
                    open={openModal}
                    onOk={() => this.clearCache()}
                    onCancel={() => this.setState({ openModal: false, cacheName: null })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to clear redis cache?</p>
                </Modal>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={"Redis Cache"} />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <>
                                            {
                                                data ?
                                                    <>
                                                        {
                                                            data.map(cache => {
                                                                return <div style={{ backgroundColor: '#f1f1f1', padding: '12px 12px', borderRadius: 8, marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <div>{cache}</div>
                                                                    <div><Button type="primary" style={{ backgroundColor: 'red' }} onClick={() => this.setState({ cacheName: cache, openModal: true })} loading={cacheName===cache ? isClearingCache : false} >Clear</Button></div>
                                                                </div>
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <Result
                                                        title="No Data"
                                                        icon={
                                                            <Image
                                                                src={empty}
                                                                width={200}
                                                                preview={false} />}
                                                    />
                                            }


                                        </>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(RedisCachePage)
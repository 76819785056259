import { Button, Col, Input, Row, Skeleton, Table, message } from "antd";
import React from "react";
import { compose } from "redux";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";


class NotificationChannelAddShippers extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            channelId: this.props.channelId,
            shippers: [],
            selectedRowKeys: [],
            pageSize: 100,
            totalPagination: 0,
            page: 1,
            is_fetching_data: true,
            townshipName: null
        };
        this.updateRef = React.createRef()

    }

    componentDidMount() {
        this.fetchUnsubscribeShippers()
    }

    notificaitonChannelOnAddShippers = () => {
        const { onAddShippers } = this.props
        if (onAddShippers) {
            onAddShippers()
        }
    }

    fetchUnsubscribeShippers = async (pageNumber) => {
        const { page, pageSize, channelId, townshipName } = this.state
        this.setState({
            is_fetching_data: true
        })
        try {
            const requestParams = {
                channelId: channelId,
                size: pageSize,
                townshipName: townshipName,
                page: pageNumber ? pageNumber - 1 : page - 1
            }
            const response = await ApiHandler({url: Api.shipper_notification_channel_unsubscribers, method: HTTP_METHOD.GET,mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response) {
                let shippersTemp = []
                response?.content?.map((res) => {
                    shippersTemp.push({
                        key: res.id,
                        fullName: res.fullName,
                        phoneNumber: res.phoneNumber,
                        businessName: res?.businessResponseDTO?.businessName

                    });
                })
                this.setState(prevState => ({
                    shippers: shippersTemp,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {

        }
        this.setState({
            is_fetching_data: false
        })
    }


    addShippersToChannel = async () => {
        const { selectedRowKeys, channelId } = this.state
        if (selectedRowKeys && selectedRowKeys.length == 0) {
            message.error("Please select at least one shipper")
        }
        try {
            const requestParams = {
                channelId: channelId,
                userIds: selectedRowKeys
            }

            const response = await ApiHandler({url: Api.notification_channel_add_subscriber,method: HTTP_METHOD.POST,mediaType: MEDIA_TYPE.JSON, requestParams})
            if (response) {
                this.setState({
                    selectedRowKeys: [],
                    page: 1,
                    totalPagination: 0,
                    shippers: []
                })
                this.notificaitonChannelOnAddShippers()
            }
        } catch (error) {
            message.error("Fail to add shippers to channel")
        }
    }


    handlePaginationChange = (pageNumber) => {
        const { selectedRowKeys } = this.state
        if (selectedRowKeys && selectedRowKeys.length != 0) {
            message.error("Please add selected shipper to channel first")
        } else {
            this.fetchUnsubscribeShippers(pageNumber);
        }

    };

    onSelectChange = (newSelectedRowKeys) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        })
    };


    render() {
        const { page, totalPagination, pageSize, shippers, is_fetching_data, selectedRowKeys } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Name',
                dataIndex: 'fullName',
            }, {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
            },
            {
                title: 'Business Name',
                dataIndex: 'businessName'
            }
        ]
        return (
            <>
                <Row style={{ marginBottom: "20px" }} gutter={[16, 8]}>
                    <Col span={6}>
                        <Input style={{ background: '#f1f1f1' }} onChange={(event) => this.setState({ townshipName: event.target.value })} placeholder="Find By Township Name" />
                    </Col>
                    <Col span={18}>
                        <Button type="primary" size="large" onClick={() => this.fetchUnsubscribeShippers()}>Search</Button>
                    </Col>
                    <Col span={24}>
                        {
                            is_fetching_data ?
                                <Skeleton active />
                                :
                                <Table
                                    rowSelection={{
                                        selectedRowKeys,
                                        onChange: this.onSelectChange,
                                    }}
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        defaultPageSize: pageSize,
                                        showSizeChanger: false,
                                        onChange: this.handlePaginationChange
                                    }}

                                    columns={columns}
                                    dataSource={shippers}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                    <Col span={24}>
                        <Button type="primary" onClick={() => this.addShippersToChannel()}>
                            Add To Channel
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(NotificationChannelAddShippers)
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPut } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import TextArea from "antd/es/input/TextArea";

class DriverUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roleData: [],
            driverAccount: {},
            isLoading: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            assing_township_collection: []
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
        this.fetchAllDivision()
    }

    fetchData = async (township_map) => {

        try {
            const response = await ApiGet(Api.driver, this.props.params.id, this.props)
            if (response) {
                this.setState(prevState => ({
                    data: response,
                }));
                let driver;
                if (response.dateOfBirth) {
                    const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
                    driver = { ...response, dateOfBirth: dob }
                } else {
                    driver = { ...response }
                }
                if (response.userStatus === 'ACTIVE') {
                    driver = { ...driver, active: true }
                } else {
                    driver = { ...driver, active: false }
                }
                if (driver.role) {
                    driver = { ...driver, role: driver.role.id }
                }
                if (driver.accounts) {
                    driver.accounts.map((account) => {
                        if (account.accountType === "PHONE") {
                            driver = { ...driver, primaryPhoneNumber: account.primaryPhoneNumber, password: account.password }
                            this.setState({
                                driverAccount: account
                            })
                        }
                    })
                }
                if (driver.township) {
                    let township_collection = township_map[driver.township.division.id]
                    driver = {
                        ...driver,
                        divisionId: driver.township.division ? driver.township.division.id : null,
                        townshipId: driver.township ? driver.township.id : null,
                    }
                    this.setState({
                        township_collection: township_collection
                    })
                }

                if (driver.assignTownship) {
                    let township_collection = township_map[driver.assignTownship.division.id]
                    driver = {
                        ...driver,
                        assignDivisionId: driver.assignTownship.division ? driver.assignTownship.division.id : null,
                        assignTownshipId: driver.assignTownship ? driver.assignTownship.id : null,
                    }
                    this.setState({
                        assing_township_collection: township_collection
                    })
                }

                if (driver.roles) {
                    let roles = []
                    driver.roles.map((role) => {
                        roles.push(role.id)
                    })
                    driver = { ...driver, roles }
                }
                this.inputRef.current.setFieldsValue(driver);
            }
        } catch (error) {

        }
    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }

    handleAssignDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            assing_township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ assignTownshipId: null });
    }

    fetchAllDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }


    updateDriver = async (values) => {
        const { driverAccount, data } = this.state
        this.setState({
            isLoading: true
        })
        try {
            const response = await ApiPut(Api.driver, data.id,
                {
                    "id": data.id,
                    "specialCode": values.specialCode,
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "dateOfBirth": values.dateOfBirth.format("YYYY-MM-DD"),
                    "phoneNumber": values.primaryPhoneNumber,
                    "gender": values.gender,
                    "userStatus": values.active ? "ACTIVE" : "INACTIVE",
                    "accounts": [
                        {
                            "id": driverAccount.id,
                            "loginId": values.primaryPhoneNumber,
                            "primaryPhoneNumber": values.primaryPhoneNumber,
                            "password": values.password,
                            "accountType": "PHONE"
                        }
                    ],
                    'township': {
                        'id': values.townshipId
                    },
                    'assignTownship': {
                        id: values.assignTownshipId
                    }
                }, this.props
            )
            if (response.status === 200) {
                this.props.navigate(CustomPath.driver)
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }


    render() {
        const { navigate } = this.props
        const { isLoading, division_collection, township_collection, assing_township_collection } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.driver}>Driver</Link>,
                                },
                                {
                                    title: 'Update',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>

                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Driver Form Update"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.driver)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                onFinish={this.updateDriver}>
                                <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <Form.Item name="specialCode" label={"Unique Id"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter unique id"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter first name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="lastName" label={"Last Name"}>
                                            <Input placeholder={"Enter last name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="primaryPhoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"979xxxxxxxx"} onChange={this.handlePhoneNumberChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="password" label={"Password"} rules={[{ required: true, message: '*(requried)' }, { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                            <Input.Password size="small" placeholder="Enter password" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="gender" label={"Gender"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                size="large"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Select gender"
                                                options={[
                                                    {
                                                        label: 'Male',
                                                        value: 'MALE',
                                                    },
                                                    {
                                                        label: 'Female',
                                                        value: 'FEMALE',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                            <DatePicker
                                                disabledDate={this.disabledDate}
                                                allowClear={false}
                                                size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>

                                        <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select Division"}
                                                onChange={this.handleDivisionSelect}
                                                size="large"
                                                options={
                                                    division_collection && division_collection.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select township"}
                                                onChange={this.divisionSelectorChange}
                                                size="large"
                                                options={
                                                    township_collection && township_collection.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <TextArea
                                                style={{ height: 60 }} placeholder={"Enter address"} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>

                                        <Form.Item name="assignDivisionId" label={"Assign  Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select assign division"}
                                                onChange={this.handleAssignDivisionSelect}
                                                size="large"
                                                options={
                                                    division_collection && division_collection.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="assignTownshipId" label={"Assign Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                className="my-location-selector"
                                                placeholder={"Select assign township"}
                                                onChange={this.divisionSelectorChange}
                                                size="large"
                                                options={
                                                    assing_township_collection && assing_township_collection.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked">
                                            <Checkbox>Active</Checkbox>
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    {/* <Button style={{ marginRight: '20px' }}>Reset</Button> */}
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Update</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(DriverUpdate)